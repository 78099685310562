import { atom, selector } from 'recoil';
import { IProject } from '../models/IProject';
import { ICurrentDecodePage } from '../models/ICurrentDecodePage';
import { IDecodeStatistics } from '../models/IDecodeStatistics';
import { ls_getCurrentPageDecode } from '../helpers/localStorage';
import { IBurialType, ICasketType, IMonumentType, ISources } from '../models/IGrave';
import { IPlaceSection, IPlaceStatus, IPlaceType } from '../models/IPlace';
import { ITableGravePlace } from '../models/ITableGravePlace';
import { placesKeysWithWidth } from "../pages/project/places/usePlaces";
import { gravesKeysWithWidth } from "../pages/project/graves/useGraves";

export const projectsState = atom({
  key: 'projects-state',
  default: {
    projects: [] as IProject[],
  }
})

export const currentProjectAtom = atom({
  key: 'current-project-state',
  default: undefined as IProject | undefined
})

export const gravesStateAtom = atom<ITableGravePlace>({
  key: 'graves-state',
  default: {
    offset: 0,
    all: 0,
    count: 100,
    searchParams: {},
    sortedParams: { monumentNumber: 'ASC' },
    currentKeys: gravesKeysWithWidth
  }
})

export const graveStateAtom = atom({
  key: 'grave-state',
  default: {
    casketType: [] as ICasketType[],
    burialType: [] as IBurialType[],
    monumentType: [] as IMonumentType[],
    sources: [] as ISources[]
  }
})

export const placeStateAtom = atom({
  key: 'place-state',
  default: {
    placeStatus: [] as IPlaceStatus[],
    placeType: [] as IPlaceType[],
    section: [] as IPlaceSection[]
  }
})

export const placesStateAtom = atom<ITableGravePlace>({
  key: 'places-state',
  default: {
    offset: 0,
    all: 0,
    count: 100,
    searchParams: {},
    sortedParams: { placeNumber: 'ASC' },
    type: 'common',
    currentKeys: placesKeysWithWidth
  }
})

export const decodeState = atom({
  key: 'decode-state',
  default: {
    currentPage: ls_getCurrentPageDecode(),
    currentGraveIndex: 0,
    sortType: 'default'
  } as ICurrentDecodePage
})

export const decodeStatisticState = atom({
  key: 'decode-statistic',
  default: {
    decodeMe: 0,
    avgTime: 0,
  } as IDecodeStatistics
})

export const projectsSelector = selector({
  key: 'projects-selector',
  get: ({ get }) => {
    return get(projectsState)
  }
})

export const currentProjectSelector = selector({
  key: 'current-project-selector',
  get: ({ get }) => {
    return get(currentProjectAtom)
  }
})