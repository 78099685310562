import React, { FC, useState } from 'react';
import { RecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCustomMutation } from '../../api/useCustomMutation';
import { ls_getProjectInfo, ls_setProjectInfo } from '../../helpers/localStorage';
import { ISyncSchemas } from '../../models/ISyncSchemas';
import SelectProject from "../SelectProject/SelectProject";
import styles from './syncSchemas.module.scss';
import Loader from "../UI/Loader/Loader";
import { WRITE_USER_HISTORY_URL } from "../../api/urls";

interface Props {
  url: string
  title: string
  label: string
  sseUrl: string
  state: RecoilState<ISyncSchemas>
  syncType: 'urnBook' | 'book'
  showResults?: boolean
  isLoading?: boolean
}

interface Form {
  File: string
  schema: string
  mergeOnCopy: string
  onlyUpdate: string
}

const SyncSchema: FC<Props> = ({
                                 url,
                                 title,
                                 label,
                                 sseUrl,
                                 state,
                                 syncType,
                                 showResults = false,
                                 isLoading = false,
                               }) => {
  const mutation = useCustomMutation()
  // const [syncSchemas, setSyncSchema] = useRecoilState(state)
  // const [wrongPlacesRgis, setWrongPlacesRgis] = useRecoilState(wrongPlacesRgisAtom)
  // const [notOkCandidates, setNotOkCandidates] = useRecoilState(notOkCandidatesOfSyncAtom)

  const { register, handleSubmit } = useForm<Form>()
  const inputLabelProps = { shrink: true }
  // const jobRef = useRef(ls_getCurrentJobId(syncType))
  // const [open, setOpen] = useState<{ statistic: boolean, notOkCandidates: boolean, wrongPlaces: boolean }>({
  //   notOkCandidates: false,
  //   statistic: false,
  //   wrongPlaces: false,
  // })

  const [project, setProject] = useState<any>(ls_getProjectInfo())
  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  // const onStatisticOpen = () => setOpen({ statistic: true, notOkCandidates: false })
  // const onNotOkCandidatesOpen = () => setOpen({ statistic: false, notOkCandidates: true, wrongPlaces: false })
  // const onWrongPlacesOpen = () => setOpen({ statistic: false, notOkCandidates: false, wrongPlaces: true })
  // const onClose = () => setOpen({ statistic: false, notOkCandidates: false, wrongPlaces: false })

  const onSubmit = async (form: Form, onlyCheck = false) => {
    try {
      // setWrongPlacesRgis([])
      // setNotOkCandidates([])
      const formData = new FormData()

      formData.set('schema', project.schema)
      formData.set('onlyCheck', onlyCheck ? 'true' : 'false')
      formData.set('file', form.File[0])
      formData.set('mergeOnCopy', form.mergeOnCopy + '')
      formData.set('onlyUpdate', form.onlyUpdate + '')

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: `SyncSchema_${syncType}`}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url,
        data: formData
      })

      if (data.wrongPlacesRgis) {
        toast.error(data.message)
        // setWrongPlacesRgis(data.wrongPlacesRgis)
        return;
      }

      // if (data.notOkCandidates?.length) {
      //   setNotOkCandidates(data.notOkCandidates)
      // }

      // jobRef.current = data.jobId
      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  // const getEvent = (sse: EventSource, e: MessageEvent<any>) => {
  //   const parsed = JSON.parse(e.data)
  //
  //   if (parsed.progress) {
  //     setSyncSchema(prev => ({ ...prev, percent: Number(parsed.progress) * 100 }))
  //   }
  //
  //   if (parsed.completed) {
  //     toast.success(parsed.completed.message)
  //     setSyncSchema(prev => ({ ...prev, percent: 100, statistic: parsed.completed.statistic }))
  //     ls_setCurrentJobId(null, syncType)
  //     sse.close()
  //   }
  // }

  // useEffect(() => {
  //   if (jobRef.current) {
  //     ls_setCurrentJobId(jobRef.current, syncType)
  //
  //     const sse = new EventSource(`${sseUrl}?jobId=${jobRef.current}`)
  //     sse.onmessage = e => getEvent(sse, e)
  //     sse.onerror = (e) => {
  //       toast.error('Ошибка')
  //       syncSchemas.sse.close()
  //     }
  //   }
  //
  // }, [jobRef.current])

  if (isLoading) {
    return <Loader/>
  }

  return (
    <Card elevation={4} className={styles.card}>

      <CardHeader title={title}/>

      <CardContent>
        <Stack spacing={2}>

          <h2>{project ? project.name : 'Выберите проект'}</h2>
          <SelectProject onProjectClick={onProjectClick} needFirstSelect={false}/>

          <FormControlLabel
            control={<Checkbox defaultChecked {...register('mergeOnCopy')} />}
            label="Совместить на копии"
            style={{ marginTop: 0 }}
          />

          <FormControlLabel
            control={<Checkbox {...register('onlyUpdate')} />}
            label="Не вставлять не найденные могилы и ограды"
            style={{ marginTop: 0 }}
          />

          <TextField
            {...register('File', { required: true })}
            size={'small'}
            type={'file'}
            label={label}
            InputLabelProps={inputLabelProps}
          />
        </Stack>

        {/*{syncSchemas.percent !== 0 &&*/}
        {/*    <LinearProgressWithLabel value={syncSchemas.percent} color={'secondary'}/>*/}
        {/*}*/}

        {/*{syncSchemas.statistic.graves &&*/}
        {/*    <Button variant={'contained'} onClick={onStatisticOpen}>*/}
        {/*        Посмотреть статистику*/}
        {/*    </Button>*/}
        {/*}*/}
      </CardContent>

      <CardActions>
        <LoadingButton
          loading={mutation.isLoading}
          variant={'contained'}
          onClick={handleSubmit(async (data) => await onSubmit(data, false))}
          // disabled={!!syncSchemas.percent}
        >
          Совместить
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default SyncSchema;