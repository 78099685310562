import React, { FC, useEffect, useState } from 'react';
import { IWrongPlacesRgis } from '../../../models/IWrongPlacesRgis';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Table,
  TableBody,
  TableHead,
  Typography
} from '@mui/material';
import Icon from '../../UI/Icons/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { GRAVE_PAGE, MAP_PAGE, PLACE_PAGE } from '../../../router/routeConsts';
import { StyledTableCell, StyledTableRow } from '../../UI/StyledTable/StyledTable';
import { showInfoField } from '../../../helpers/showInfoField';
import { useRecoilState } from 'recoil';
import { showWrongPlacesRgisAtom } from '../../../store/wrongPlacesRgisStore';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { GET_PLACE_URL, UPDATE_PLACE_URL } from '../../../api/urls';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import { toast } from 'react-toastify';

interface Props {
  onExpand: () => void
  expanded: boolean
  body: IWrongPlacesRgis
}

const WrongPlacesAccordion: FC<Props> = ({ body, onExpand, expanded }) => {
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const [places, setPlaces] = useState<number[]>([])
  const [_, setShowWrongPlacesOnMap] = useRecoilState(showWrongPlacesRgisAtom);

  const onShowOnMap = async () => {
    const loadPlaces = [];

    for (const place of places) {
      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: GET_PLACE_URL + place + `?schema=${ls_getProjectInfo().schema}`
      })

      loadPlaces.push(data.place)
    }

    setShowWrongPlacesOnMap({ places: loadPlaces, showOnMap: true });
    navigate(MAP_PAGE)
  }

  const getPlacesIds = () => {
    body.gravesWithPlaces.map(({ idPlace }) => {
      if (!places.includes(idPlace)) {
        const arr = places
        arr.push(idPlace)
        setPlaces(arr)
      }
    })
  }

  const test = async () => {
    if (places.length) {
      try {
        const { schema } = ls_getProjectInfo()
        for (const place of places) {
          const { data: editData } = await mutation.mutateAsync({
            method: 'patch',
            data: { id: place, dontMerge: true, schema },
            url: UPDATE_PLACE_URL + place,
          })

          toast.success(editData.message)
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    getPlacesIds()
  }, [])

  return (
    <Accordion expanded={expanded} onChange={onExpand}>
      <AccordionSummary expandIcon={<Icon icon={'expandMore'}/>}>
        <Stack direction={'row'} spacing={2}>
          <Typography variant={'h5'}>
            Ргис id ограды: {body.idPlaceRgis}
          </Typography>

          <Button variant={'text'} onClick={onShowOnMap}>
            Посмотреть на карте
          </Button>

          <Typography variant={'h6'}>
            Претендующие ограды:
            {
              places.map((idPlace) => <Link to={PLACE_PAGE + idPlace + `?common`} key={idPlace}>{idPlace} </Link>)
            }
          </Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Table>
          <TableHead>
            <StyledTableRow>

              <StyledTableCell>
                Могила
              </StyledTableCell>

              <StyledTableCell>
                Ргис id могилы
              </StyledTableCell>

              <StyledTableCell>
                Ограда
              </StyledTableCell>

              <StyledTableCell>
                ФИО и даты (из Ргис данных)
              </StyledTableCell>

            </StyledTableRow>
          </TableHead>

          <TableBody>
            {
              body.gravesWithPlaces.map(({ id, idPlace, attributes }) => (
                <StyledTableRow key={attributes.idMonumentRgis}>
                  <StyledTableCell>
                    <Link to={GRAVE_PAGE + id}>
                      {id}
                    </Link>
                  </StyledTableCell>

                  <StyledTableCell>
                    {attributes.idMonumentRgis}
                  </StyledTableCell>

                  <StyledTableCell>
                    <Link to={PLACE_PAGE + idPlace + `?common`}>
                      {idPlace}
                    </Link>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Stack direction={'row'} spacing={1}>
                      <div>{showInfoField(attributes.searchableFields.lastname, { dateWithoutTime: true })}</div>
                      <div>{showInfoField(attributes.searchableFields.firstname, { dateWithoutTime: true })}</div>
                      <div>{showInfoField(attributes.searchableFields.patronymic, { dateWithoutTime: true })}</div>

                      <div>|</div>

                      <div>
                        Дата рождения: {showInfoField(attributes.searchableFields.dob, { dateWithoutTime: true })}
                      </div>
                      <div>
                        Дата смерти: {showInfoField(attributes.searchableFields.dod, { dateWithoutTime: true })}
                      </div>
                    </Stack>
                  </StyledTableCell>

                </StyledTableRow>
              ))}
          </TableBody>
        </Table>

      </AccordionDetails>
    </Accordion>
  );
};

export default WrongPlacesAccordion;