import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import { getBurialTypes, getCasketTypes, getMonumentTypes, getSources } from '../../../../api/grave/graveApi'
import { getGraveById } from '../../../../api/project/projectApi'
import { graveStateAtom } from '../../../../store/projectStore'
import { graveDto } from '../useGraves'
import { UPDATE_GRAVE_URL, WRITE_USER_HISTORY_URL } from '../../../../api/urls'
import { toast } from 'react-toastify'
import { useCustomMutation } from '../../../../api/useCustomMutation'
import { ls_getProjectInfo } from '../../../../helpers/localStorage'
import { validateDatesInObject } from '../../../../helpers/validateDatesInObject';
import { NO_INFO } from '../../../../utils/utils';
import { useNavigate } from "react-router-dom";
import * as turf from '@turf/turf';
import { useTheme } from "@mui/material/styles";
import { showInfoField } from "../../../../helpers/showInfoField";
import { useForm } from "react-hook-form";
import { IGrave } from "../../../../models/IGrave";

const useMapGrave = (id: number) => {
  const theme = useTheme()
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  const cacheTime = 1000 * 60 * 20
  const [graveState, setGraveState] = useRecoilState(graveStateAtom)
  const mutation = useCustomMutation()
  const projectInfo = ls_getProjectInfo()
  const navigate = useNavigate()
  const [sources, setSources] = useState<string[] | undefined>()

  const cancel = () => navigate(-1)

  const form = useForm<IGrave>({
    defaultValues: {
      monumentNumber: 0,
      idPlace: 0,
      idMonumentRgis: '',
      sectionNumber: '',
      bookNumber: '',
      firstname: '',
      lastname: '',
      patronymic: '',
      burialType: '',
      casketType: '',
      monumentType: '',
      isSubgrave: false,
      monumentSize: '',
      monumentWidth: 0,
      monumentHeight: 0,
      monumentDepth: 0,
      snils: '',
      groundsman: '',
      zagsDocumentNum: '',
      zagsName: '',
      gender: null
    }
  })

  const handleChange = (event: any) => {
    const { target: { value } } = event

    setSources(
      typeof value === 'string' ? value.split(',') : value
    );

    form.control._formValues.sources = typeof value === 'string' ? value.split(',') : value
  };

  const {
    data: graveResponse,
    refetch: graveRefetch,
    isLoading: graveIsLoading,
  } = useQuery([`grave/${id}`, id], () => getGraveById(id))

  const {
    data: casketTypesResponse,
  } = useQuery(['casketType'], getCasketTypes, { cacheTime, staleTime: cacheTime })

  const {
    data: burialTypesResponse,
  } = useQuery(['burialType'], getBurialTypes, { cacheTime, staleTime: cacheTime })

  const {
    data: monumentTypesResponse,
  } = useQuery(['graveType'], getMonumentTypes, { cacheTime, staleTime: cacheTime })
  const {
    data: sourcesResponse,
  } = useQuery(['sources'], getSources, { cacheTime, staleTime: cacheTime })

  const searchDirty = (data: any) => {
    let dirty = {} as any

    for (const { fieldName, type } of graveDto) {

      if (type === 'bool') {
        if (showInfoField(data[fieldName]) !== showInfoField(graveResponse?.data.grave[fieldName])) {
          dirty[fieldName] = true
        }
        continue
      }

      if (fieldName === 'geometry') {
        continue
      }

      if (data[fieldName] !== showInfoField(graveResponse?.data.grave[fieldName], { dateWithoutTime: true })) {
        dirty[fieldName] = true
      }
    }

    return dirty;
  }

  const onSubmit = async (data: any) => {

    const dateKeys = ['dob', 'dod', 'dof', 'zagsDocumentDate', 'monumentInstallationDate', 'certificateDate', 'deathRecordDate', 'docr', 'doph']
    const numberKeys = ['age', 'monumentHeight', 'monumentDepth', 'monumentWidth', 'idMonumentRgis']

    const dirty = searchDirty(data)

    for (const { fieldName } of graveDto) {
      if (!dirty[fieldName] && fieldName !== 'id') {
        delete data[fieldName];
        continue;
      }

      if (data[fieldName] === NO_INFO) {
        data[fieldName] = null
      }
    }

    if (data['sources']) {
      const sources = []
      for (const source of data['sources']) {
        const find = sourcesResponse?.data.sources.filter((sour: any) => sour.name === source)
        if (find) {
          sources.push(find[0].id)
        }
      }
      data['sources'] = sources
    }

    for (const key of numberKeys) {
      if (data[key] && !isNaN(Number(data[key]))) {
        data[key] = Number(data[key])
      } else {
        if (data[key] === '') {
          data[key] = null
        }
      }
    }

    if (data['idPlace']) {
      data['idPlace'] = Number(data['idPlace'])
    }

    if (data['geometry']) {
      const coordinates = [Number(data['geometry'].split(',')[0]), Number(data['geometry'].split(',')[1])]
      data['geometry'] = turf.point(coordinates).geometry
    }

    if (data['monumentNumber']) {
      data['monumentNumber'] = Number(data['monumentNumber'])
    }

    if (data.burialType) {
      graveState.burialType.forEach((burial) => {
        if (data.burialType.toLowerCase() === burial.name.toLowerCase()) {
          data.idBurialType = burial.id
        }
      })
    }

    if (data.monumentType) {
      graveState.monumentType.forEach((monument) => {
        if (data.monumentType.toLowerCase() === monument.name.toLowerCase()) {
          data.idMonumentType = monument.id
        }
      })
    }

    if (data.casketType) {
      graveState.casketType.forEach((casket) => {
        if (data.casketType.toLowerCase() === casket.name.toLowerCase()) {
          data.idCasketType = casket.id
        }
      })
    }

    data.schema = projectInfo.schema

    validateDatesInObject(data, dateKeys)

    let changesDescription = {
      action: 'edit',
      objectId: data.id,
      data: []
    } as any

    for (const [key, _] of Object.entries(dirty)) {
      changesDescription.data.push({
        fieldName: key,
        oldData: graveResponse?.data.grave[key],
        newData: data[key]
      })
    }

    if (!Object.entries(changesDescription.data).length) {
      toast.error('Вы ничего не изменяли')
      return
    }

    try {
      const { data: editData } = await mutation.mutateAsync({
        method: 'patch',
        data: data,
        url: UPDATE_GRAVE_URL + data.id
      })

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'grave'
        },
        url: WRITE_USER_HISTORY_URL
      })


      await graveRefetch()

      toast.success(editData.message)

    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  function getStyles(name: string, projectName: readonly string[] | undefined) {
    if (projectName) {
      return {
        fontWeight:
          projectName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      }
    }
  }

  useEffect(() => {
    if (monumentTypesResponse && burialTypesResponse && casketTypesResponse && sourcesResponse) {
      setGraveState({
        monumentType: monumentTypesResponse.data.types,
        burialType: burialTypesResponse.data.types,
        casketType: casketTypesResponse.data.types,
        sources: sourcesResponse.data.sources
      })
    }
  }, [monumentTypesResponse, burialTypesResponse, casketTypesResponse, sourcesResponse])

  return {
    isLoading: graveIsLoading,
    grave: graveResponse?.data.grave,
    graveState,
    onSubmit,
    graveRefetch,
    cancel,
    getStyles,
    MenuProps,
    handleChange,
    form,
    sources
  }
};

export default useMapGrave