import React from 'react';
import SetRowNumberMarkers from "./markers/SetRowNumberMarkers";
import SetRowNumberButton from "./window/SetRowNumberButton";

const SetRowNumber = () => {
  return (
    <>
      <SetRowNumberButton/>
      <SetRowNumberMarkers/>
    </>
  );
};

export default SetRowNumber;