import React, { FC } from 'react';
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import styles from "./mapLayoutModal.module.scss";
import Icon from "../../../../components/UI/Icons/Icon";
import { Link } from "react-router-dom";
import { GRAVE_PAGE } from "../../../../router/routeConsts";
import { NO_INFO } from "../../../../utils/utils";
import Loader from "../../../../components/UI/Loader/Loader";

interface Props {
  onClose: () => void,
  onCopy: (textToCopy: string) => Promise<void>,
}

const GravesInfoModal: FC<Props> = ({ onClose, onCopy }) => {
  const [mapState] = useRecoilState(mapStateAtom)

  if (!mapState.preSelectedObject || mapState.preSelectedObject?.type !== 'grave') {
    return <></>
  }

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.close} onClick={onClose}>
          <Icon icon={"close"}/>
        </div>

        <p>Могила</p>

        {mapState.selectedObject ?
          <>
            <div style={{ display: 'flex' }} key={mapState.selectedObject.uuid}>
              <Link to={GRAVE_PAGE + mapState.selectedObject.id}>
                <span>№</span>
                <span>{mapState.selectedObject.monumentNumber}</span>
                <span>{mapState.selectedObject.lastname || NO_INFO}</span>
                <span>{mapState.selectedObject.firstname || NO_INFO}</span>
                <span>{mapState.selectedObject.patronymic || NO_INFO}</span>
              </Link>

              <div
                className={styles.copy_btn_wrapper}
                onClick={
                  () => onCopy(`${mapState.selectedObject.lastname || NO_INFO} ${mapState.selectedObject.firstname || NO_INFO} ${mapState.selectedObject.patronymic || NO_INFO}`)
                }
              >
                <Icon icon={"copy"}/>
              </div>
            </div>
          </>
          :
          <Loader/>
        }
      </div>
    </>
  );
};

export default GravesInfoModal;