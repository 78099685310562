import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import styles from './timer.module.scss';

interface Props {
  changeDependencies: any[]
  timerRef: MutableRefObject<number>
}

const Timer: FC<Props> = ({ changeDependencies, timerRef }) => {
  const [count, setCount] = useState(0)

  const countUp = () => {
    setCount(prev => {
      timerRef.current = prev + 1
      return prev + 1
    })
  }

  useEffect(() => {
    let timer: NodeJS.Timer | undefined = undefined

    if (count === 0) {
      timer = setInterval(countUp, 1000)
    }

    return () => {
      // @ts-ignore
      clearInterval(timer)
      setCount(0)
    }
  }, [changeDependencies])

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>Время:</span>
      <span className={styles.value}>{count}</span>
      <span className={styles.label}>сек.</span>
    </div>
  );
};

export default Timer;