import React, { useState } from 'react';
import styles from "../FindNotExistingPhotos/findNotExistingPhotos.module.scss";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../api/useCustomMutation";
import { MOVE_PHOTOS_FROM_KZ_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import { toast } from "react-toastify";
import { CantMovePhotosFromKzAtom } from "../../store/CantMovePhotosFromKzStore";
import { useRecoilState } from "recoil";
import { IProject } from "../../models/IProject";
import Icon from "../UI/Icons/Icon";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import project from "../../pages/project/Project";
import { algorithmsTitles } from "../../utils/utils";

const MovePhotosFromKz = () => {
  const mutation = useCustomMutation()
  const [projects, setProjects] = useState<IProject[]>([])
  const [open, setOpen] = useState(false)
  const [cantMovePhotosFromKz, setCantMovePhotosFromKz] = useRecoilState(CantMovePhotosFromKzAtom)

  const onOpenClose = () => setOpen(!open)

  const onProjectClick = (project: any) => {
    setProjects((prev) => {
      const find = prev.find((p) => p.schema === project.schema);

      if (find) {
        return prev
      } else {
        return [...prev, project]
      }
    })
  }

  const onDelete = (schema: string) => {
    const newProjectsArray = projects.filter((A) => A.schema !== schema)

    setProjects(newProjectsArray);
  }

  const onSubmit = async () => {
    try {
      const schemas = [] as string[];
      const projectsID = [] as number[];

      projects.map((p) => {
        schemas.push(p.schema)
        projectsID.push((p.id))
      })

      for (let i = 0; i<schemas.length; i++) {

        let changesDescription = {
          action: 'algorithm',
          objectId: projectsID[i],
          data: [{algorithmName: 'MovePhotosFromKz'}]
        } as any

        await mutation.mutateAsync({
          method: "post",
          data: {
            schema: schemas[i],
            changesDescription,
            actionObject: 'project'
          },
          url: WRITE_USER_HISTORY_URL
        })
      }

      const { data } = await mutation.mutateAsync({
        method: 'post',
        data: { schemas: projects.map((project) => project.schema) },
        url: MOVE_PHOTOS_FROM_KZ_URL,
      })

      setCantMovePhotosFromKz(data.cantMove)
      toast.success(data.message)

    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }
  const onInfoClick = () => {
    toast(
      <>
        Копирование фотографий из бакета КЗ в наш бакет, удаление фоток-заглушек и переименовываем фотографии по нашим правилам.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.container}>

      <CustomCardHeader title={algorithmsTitles.MovePhotosFromKz} onInfoClick={onInfoClick}/>

      <CardContent className={styles.overflow}>
        <Table>
          <TableBody>
            {
              projects.map((project) => (
                <TableRow key={project.schema}>
                  <TableCell>{project.name}</TableCell>

                  <TableCell>
                    <Icon icon={"close"} onClick={() => onDelete(project.schema)}/>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </CardContent>

      <CardActions>
        <Stack spacing={1} alignItems={"center"}>
          <Stack direction={'row'} spacing={3}>
            <SelectProject onProjectClick={onProjectClick}/>

            <LoadingButton
              variant={'outlined'}
              loading={mutation.isLoading}
              onClick={onSubmit}
            >
              Начать
            </LoadingButton>
          </Stack>

          {cantMovePhotosFromKz &&
              <>
                  <Button onClick={onOpenClose}>
                      Посмотреть неперенесённые фотографии
                  </Button>

                  <Dialog open={open} onClose={onOpenClose} fullScreen>

                  </Dialog>
              </>
          }
        </Stack>
      </CardActions>
    </Card>
  );
};

export default MovePhotosFromKz;