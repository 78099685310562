// packages
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Button, FormControlLabel, Stack, Switch } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';
// components
import CreateMunicipalityForm from './CreateMunicipalityForm';
import Loader from '../../../components/UI/Loader/Loader';
// logic
import { getMunicipalities } from '../../../api/municipality/getMunicipalities';
import { useMunicipalities } from './useMunicipalities';
// styles
import styles from './municipalities.module.scss';

const Municipalities = () => {
  const { data: municipalities, refetch } = useQuery(['municipalities'], getMunicipalities)
  const { columns, rows, handleToggle, isExistDeleted } = useMunicipalities()
  const [showCreate, setShowCreate] = useState(false)

  const onOpen = () => setShowCreate(true)
  const onClose = () => setShowCreate(false)

  if (!municipalities || !rows) {
    return <Loader/>
  } else {
    return (
      <div className={styles.wrapper}>
        <Stack spacing={1}>
          <div className={styles.title}>
            <h1>Управление округами</h1>

            <Button onClick={onOpen} variant={'outlined'}>
              Создать округ
            </Button>
          </div>

          <CreateMunicipalityForm isOpen={showCreate} onClose={onClose} refetch={refetch}/>

          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={handleToggle}
                disabled={isExistDeleted}
              />}
            label="Удалёные округа"
          />

          <div className={styles.table_wrapper}>
            <DataGrid
              columns={columns}
              rows={rows}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </Stack>
      </div>
    )
  }
}

export default Municipalities