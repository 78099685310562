import React from 'react';
import DecodeStatistic from '../../_admin/statistics/decodeStatistic/DecodeStatistic';
import { useRecoilValue } from 'recoil';
import { userSelector } from '../../../store/userStore';
import { useQuery } from 'react-query';
import { getProjectsWithPermissions } from '../../../api/project/projectApi';
import Loader from '../../../components/UI/Loader/Loader';

const DecodeStatisticProfilePage = () => {

  const user = useRecoilValue(userSelector)
  const { data: projects, isLoading } = useQuery('projects_with_permissions', getProjectsWithPermissions)

  if (!projects || isLoading) {
    return <Loader/>
  }

  return (
    <div>
      <DecodeStatistic user={user} projects={projects}/>
    </div>
  );
};

export default DecodeStatisticProfilePage;