import React, { FC, useEffect } from 'react';
import { TableVirtuoso } from 'react-virtuoso'
import TableHeaders from '../table/TableHeaders';
import TableCells from '../table/TableCells';
import { useTable } from '../table/useTable';
import { useRecoilState } from 'recoil';
import { placesStateAtom } from '../../../store/projectStore';
import { useNavigate } from 'react-router-dom';
import { EDIT_PLACE_PAGE, PLACE_PAGE } from '../../../router/routeConsts';
import { useCustomMutation } from '../../../api/useCustomMutation';
import {
  DELETE_PLACE_URL,
  DELETE_PLANNED_PLACE_BY_ID_URL,
  GET_PLACES_SEARCH_RESULTS_URL,
  WRITE_USER_HISTORY_URL
} from '../../../api/urls';
import { toast } from 'react-toastify';
import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { getPlaces } from '../../../api/project/projectApi';
import Loader from '../../../components/UI/Loader/Loader';
import { useForm } from 'react-hook-form';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import cn from 'classnames';
import styles from './places.module.scss';
import Icon from '../../../components/UI/Icons/Icon';
import ExportSearchResults from "../../../components/ExportSearchResults/ExportSearchResults";
import { placesTablesHeaders } from "./usePlaces";
import { ITableSettings } from "../../../models/ITableSettings";

const Places: FC = () => {
  const [placesState, setPlacesState] = useRecoilState(placesStateAtom)
  const { count, searchParams, all, offset, type, sortedParams, currentKeys } = placesState

  const {
    data: response,
    isLoading,
    refetch,
    error
  } = useQuery(
    [offset, searchParams, type, sortedParams],
    () => getPlaces(offset, count, searchParams, sortedParams, type),
    { retry: false }
  )

  const form = useForm()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const projectInfo = ls_getProjectInfo()
  const { configMUITable } = useTable()

  const onChangePlaces = (e: any) => {
    setPlacesState(prev => ({ ...prev, type: e.target.value }))
  }

  const toFirstPage = () => {
    setPlacesState(prev => ({
      ...prev,
      offset: 0
    }))
  }

  const toLastPage = () => {
    if (all - count < 1) {
      toast.error('Вы уже на последней странице')
      return
    }

    setPlacesState(prev => ({
      ...prev,
      offset: all - count
    }))
  }

  const onNext = () => {
    if (all - count > offset) {
      setPlacesState(prev => ({
        ...prev,
        offset: prev.offset + count
      }))
    }
  }

  const onPrev = () => {
    if (offset >= count) {
      setPlacesState(prev => ({
        ...prev,
        offset: prev.offset - count
      }))
    }
  }

  const onSearch = (query: any) => {
    setPlacesState(prev => ({
      ...prev,
      searchParams: query,
      offset: 0
    }))
  }

  const onSort = (key: any) => {
    let param = 'ASC'

    if (placesState.sortedParams[key]) {
      if (placesState.sortedParams[key] === 'ASC') {
        param = 'DESC'
      }
    }

    const query = { [key]: param }

    setPlacesState(prev => ({
      ...prev,
      sortedParams: query
    }))
  }

  const onReset = () => {
    setPlacesState(prev => ({
      ...prev,
      searchParams: {},
      sortedParams: { placeNumber: 'ASC' }
    }))
    form.reset()
  }

  const toDetails = (id: number) => {
    navigate(PLACE_PAGE + id + `?${type}`)
  }

  const onDelete = ({ id, objectNumber }: { id: number, objectNumber: number }) => {
    const url = type === 'common' ? DELETE_PLACE_URL : DELETE_PLANNED_PLACE_BY_ID_URL
    mutation.mutateAsync({
      method: 'delete',
      url: url + id + `?schema=${projectInfo.schema}`
    })
      .then(({ data }) => {

        const changesDescription = {
          objectId: id,
          action: 'delete',
          objectNumber
        }

        mutation.mutateAsync({
          method: "post",
          data: {
            schema: projectInfo.schema,
            changesDescription,
            actionObject: type === 'common' ? 'place' : 'planned_place'
          },
          url: WRITE_USER_HISTORY_URL
        })

        toast.success(data.message)
        refetch()
      })
      .catch(({ response }) => {
        toast.error(response?.data.message)
        toast.error(response?.data.error)
      })
  }

  const tableSettings: ITableSettings = {
    allKeys: placesTablesHeaders,
    currentKeys: currentKeys,
    onChangeKeys: keys => {
      setPlacesState((prev) => ({ ...prev, currentKeys: keys }))
    }
  }

  useEffect(() => {
    if (error) {
      toast.error(
        <p>
          {/*@ts-ignore*/}
          {error.response?.data.message}
          {/*@ts-ignore*/}
          {error.response?.data.error}
        </p>
      )
    }
  }, [error])

  useEffect(() => {
    setPlacesState(prev => ({
      ...prev,
      all: response?.data.all
    }))
  }, [response?.data.all, setPlacesState])

  if (!projectInfo?.schema) {
    return <h2>Загрузите проект</h2>
  }

  if (!error && (isLoading || !response?.data.places)) {
    return <Loader/>
  }

  return (
    <div className={styles.places__container}>
      <TableVirtuoso
        className={styles.custom_scroll}
        style={{ height: 'calc(100vh - 17rem)' }}
        data={error ? [] : response?.data.places}
        totalCount={error ? 0 : response?.data.places.length}
        components={configMUITable}
        fixedHeaderContent={() =>
          <TableHeaders
            form={form}
            onSearch={onSearch}
            onReset={onReset}
            onSorted={onSort}
            sortedParams={placesState.sortedParams}
            disabledHeaders={['sectionNumber', 'countPhotos']}
            tableSettings={tableSettings}
          />}
        itemContent={(index, place) =>
          <>
            {
              !response?.data.places?.length
                ? <div>Ничего не найдено</div>
                : <TableCells
                  cells={{ ...place, countPhotos: place.photos?.length || 0 }}
                  keysWithWidth={currentKeys}
                  onDetails={toDetails}
                  onDelete={onDelete}
                  type={type}
                  editUrl={EDIT_PLACE_PAGE}
                />
            }
          </>}
      />

      <Stack direction={'row'} justifyContent={"space-between"}>

        <Box marginTop={'1rem'}>
          <FormLabel id="demo-radio-buttons-group-label">Ограды</FormLabel>

          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={type}
            name="radio-buttons-group"
            onChange={onChangePlaces}
          >
            <FormControlLabel value="common" control={<Radio/>} label="Существующие"/>
            <FormControlLabel value="planned" control={<Radio/>} label="Запланированые"/>
          </RadioGroup>
        </Box>

        <Stack direction={'row'} spacing={4} justifyContent={'flex-end'} alignItems={'center'} padding={2}>
          <ExportSearchResults url={GET_PLACES_SEARCH_RESULTS_URL} entity={"places"} searchParams={searchParams} count={response?.data.all} sortedName={sortedParams}/>

          <Typography>с {offset} по {count + offset > all ? all : count + offset} из {all}</Typography>

          <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.first_page_icon)} onClick={toFirstPage}/>

          <Button onClick={onPrev} variant={'contained'}>Предыдущая</Button>
          <Button onClick={onNext} variant={'contained'}>Следующая</Button>

          <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.last_page_icon)} onClick={toLastPage}/>
        </Stack>
      </Stack>
    </div>
  );
};

export default React.memo(Places);