import React, {FC, useReducer, useState} from 'react';
import {Box, Stack} from '@mui/material';
import styles from './currentPhoto.module.scss';
import {ProjectInfo} from '../../../../../helpers/localStorage';
import Icon from "../../../../../components/UI/Icons/Icon";
import {useCustomMutation} from "../../../../../api/useCustomMutation";
import {ROTATE_PHOTO_ON_CROC_URL} from "../../../../../api/urls";
import {toast} from "react-toastify";

interface Props {
  currentGrave: any
  projectInfo: ProjectInfo
  forcer?: string,
  forceUpdateImages?: () => void
}

const CurrentPhoto: FC<Props> = ({currentGrave, projectInfo, forceUpdateImages, forcer = 0}) => {
  const [currentPhoto, setCurrentPhoto] = useState(0)
  const [figureStyle, setFigureStyle] = useState({
    position: '',
    width: 400
  })
  const mutation = useCustomMutation()

  const imageUrl = projectInfo.hostingUrl + projectInfo.imageFolder + '/'

  const onSelectPhoto = (index: number) => {
    setCurrentPhoto(index)
  }

  const handleMouseMove = (e: any) => {
    const {left, top, width, height} = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    setFigureStyle({
      position: `${x}% ${y}%`,
      width
    })
  }

  const rotatePhoto = async (photo: string, direction: 'left' | 'right') => {
    try {
      if (!forceUpdateImages) {
        return
      }

      await mutation.mutateAsync({
        method: 'patch',
        url: ROTATE_PHOTO_ON_CROC_URL +
          `?photo=${photo.split('/')[1]}&direction=${direction}&relativePath=graves&imageFolder=${projectInfo.imageFolder}`
      })

      forceUpdateImages()
      toast.success('Картинка перевёрнута успешно')
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  return (
    <Box className={styles.wrapper}>
      <Stack className={styles.main__photo__wrapper} spacing={1} padding={1}>
        {currentGrave.photos?.length
          ? <Box className={styles.big__photo}>
            {forcer &&
              <Icon
                icon={"rotate"}
                className={styles.rotate_icon}
                onClick={() => rotatePhoto(currentGrave.photos[currentPhoto], "left")}
              />
            }
            <figure
              className={styles.figure}
              onMouseMove={handleMouseMove}
              style={{
                backgroundPosition: figureStyle.position,
                backgroundImage: `url(${imageUrl + currentGrave.photos[currentPhoto].replace('.JPG', '.jpg') + '?' + forcer})`,
                width: figureStyle.width
              }}
            >
              <img
                alt={currentGrave.photos[currentPhoto].replace('graves/', '').replace('.JPG', '.jpg')}
                src={imageUrl + currentGrave.photos[currentPhoto].replace('.JPG', '.jpg') + '?' + forcer}
              />
            </figure>
            {forcer &&
              <Icon
                icon={"rotate"}
                className={styles.mirror_icon}
                onClick={() => rotatePhoto(currentGrave.photos[currentPhoto], "right")}
              />
            }
          </Box>
          : <p>нет фото</p>
        }
        <div className={styles.preview__photos}>
          {currentGrave.photos?.map((photo: string, index: number) =>
            <React.Fragment key={index}>
              <img
                alt={'grave'}
                className={index === currentPhoto ? styles.active_photo : ''}
                src={imageUrl + photo.replace('.JPG', '.jpg') + '?' + forcer}
                onClick={() => onSelectPhoto(index)}
              />
            </React.Fragment>
          )}
        </div>
      </Stack>
    </Box>
  );
};

export default CurrentPhoto;