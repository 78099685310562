import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './overview.module.scss';
import Tabs from '../../../components/UI/Tabs/Tabs';
import {
  ADMIN_CEMETERIES,
  ADMIN_FEATURES,
  ADMIN_MUNICIPALITIES,
  ADMIN_PROJECTS,
  ADMIN_STATISTICS,
  ADMIN_USERS, ADMIN_USERS_TEMPLATES
} from '../../../router/routeConsts';
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";

const OverView = () => {
  const links = [
    { label: 'Возможности', to: ADMIN_FEATURES, scopes: [SCOPES.admin, SCOPES.worker] },
    { label: 'Пользователи', to: ADMIN_USERS, scopes: [SCOPES.admin, SCOPES.superUser] },
    { label: 'Проекты', to: ADMIN_PROJECTS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Кладбища', to: ADMIN_CEMETERIES, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Округи', to: ADMIN_MUNICIPALITIES, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Шаблоны', to: ADMIN_USERS_TEMPLATES, scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser,] },
    { label: 'Статистика', to: ADMIN_STATISTICS, scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.worker] }
  ]

  return (
    <div className={styles.wrapper}>
      <Tabs links={links}/>
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
};

export default OverView;