import React, { FC, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentProjectSelector, currentProjectAtom } from '../../../store/projectStore';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { GET_PROJECT_URL } from '../../../api/urls';
import { AxiosResponse } from 'axios';
import { IProjectDataResponse } from '../../../models/IProjectDataResponse';
import { toast } from 'react-toastify';
import { ls_getProjectInfo, ls_setProjectInfo } from '../../../helpers/localStorage';
import styles from './info.module.scss';
import Loader from '../../../components/UI/Loader/Loader';
import { useInfo } from "./useInfo";
import InfoTable from "./InfoTables/InfoTable";

interface IInfoProps {
  isLoading: boolean
}

const Info: FC<IInfoProps> = ({ isLoading }) => {
  const project = useRecoilValue(currentProjectSelector)
  const mutation = useCustomMutation()
  const [, setCurrentProject] = useRecoilState(currentProjectAtom)
  const projectInfo = ls_getProjectInfo()

  const { mainData, placesTypeData, earthData, peopleData } = useInfo()

  const tableData = [
    { data: mainData, label: 'Кладбище', defaultOpen: false },
    { data: earthData, label: 'Земля', defaultOpen: true },
    { data: peopleData, label: 'Люди', defaultOpen: true },
    { data: placesTypeData, label: 'Захоронения по типу', defaultOpen: false },
  ]

  useEffect(() => {
    if (project && !Object.keys(project).length && projectInfo) {
      mutation.mutateAsync({
        method: 'get',
        url: GET_PROJECT_URL + projectInfo.schema
      })
        .then(({ data }: AxiosResponse<IProjectDataResponse>) => {
          setCurrentProject(data.project)
          ls_setProjectInfo(data.project)
        })
        .catch(({ response }) => {
          toast.error(response?.data?.message)
        })
    }
  }, [])

  if (isLoading) {
    return <Loader/>
  }

  if (!projectInfo) {
    return <div/>
  }

  return (
    <div className={styles.container}>
      {tableData.map(({ data, label, defaultOpen }) => (
        <InfoTable key={label} data={data} label={label} defaultOpen={defaultOpen}/>
      ))}
    </div>
  );
};

export default Info;