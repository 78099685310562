import React from 'react';
import { Button, Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import styles from "./syncProject.module.scss";
import { useForm } from "react-hook-form";
import { useCustomMutation } from "../../../../../api/useCustomMutation";
import { SYNC_PROJECT_BY_FILES_URL } from "../../../../../api/urls";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";

const SyncProject = () => {
  const { register, handleSubmit } = useForm()
  const mutation = useCustomMutation()

  const size = 'small'
  const type = 'file'
  const inputLabelProps = { shrink: true }

  const submit = async (data: any) => {
    for(const file of data.syncingFiles) {
      try {
        const formData = new FormData()

        formData.append('syncingFiles', file)

        const res = await mutation.mutateAsync({
          method: 'post',
          url: SYNC_PROJECT_BY_FILES_URL,
          data: formData
        })

        toast.success(res.data.message)

      } catch (e: any) {
        toast.error(
          <div>
            <p>{e.response?.data.message}</p>
            <p>{e.response?.data.error}</p>
          </div>
        )
      }
    }
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={'Синхронизация полевых данных'}/>

      <CardContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>Выберите файлы</Typography>

          <TextField
            {...register('syncingFiles', { required: true })}
            size={size}
            type={type}
            label={'Полевые данные'}
            inputProps={{
              multiple: true
            }}
            InputLabelProps={inputLabelProps}
          />

          <div className={styles.btn_wrapper}>
            <LoadingButton
              loading={mutation.isLoading}
              variant={'contained'}
              className={styles.btn}
              onClick={handleSubmit(submit)}
            >
              Начать синхронизацию
            </LoadingButton>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SyncProject;