import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent, Grid,
  Toolbar,
  Typography
} from '@mui/material';
import { useQuery } from 'react-query';
import { getUserDecodeIntervals } from '../../../../../api/statistic/statisticApi';
import Loader from '../../../../../components/UI/Loader/Loader';
import { useCustomMutation } from '../../../../../api/useCustomMutation';
import {
  CREATE_USER_DECODE_INTERVALS_URL,
  DELETE_USER_DECODE_INTERVALS_URL,
  UPDATE_USER_DECODE_INTERVALS_URL
} from '../../../../../api/urls';
import { toast } from 'react-toastify';
import { Confirm } from 'react-st-modal';
import { confirmTextHelper } from '../../../../../helpers/confirmTextHelper';
import { getUsers } from '../../../../../api/user/getUsers';
import DoughnutChart from '../../../../../components/UI/DoughnutChart/DoughnutChart';
import { useRecoilValue } from 'recoil';
import { projectsSelector } from '../../../../../store/projectStore';
import styles from './userIntervals.module.scss'
import IntervalForm from './IntervalForm';
import { IIntervalForm } from '../../../../../models/_admin/IIntervalForm';
import { IIntervalWithUser } from '../../../../../models/_admin/IIntervalWithUser';
import Icon from '../../../../../components/UI/Icons/Icon';

const UserIntervals = () => {
  const mutation = useCustomMutation()
  const { projects } = useRecoilValue(projectsSelector)
  const { data: responseIntervals, refetch: refetchIntervals } = useQuery(['intervals'], getUserDecodeIntervals)
  const { data: responseUsers } = useQuery(['users'], ()=>getUsers())

  const [targetInterval, setTargetInterval] = useState<IIntervalWithUser>({} as IIntervalWithUser)
  const [isOpen, setIsOpen] = useState<'create' | 'edit' | 'close' | 'info'>('close')

  const onClose = () => setIsOpen('close')
  const onShowCreate = () => setIsOpen('create')
  const onShowInfoGraphic = () => setIsOpen('info')

  const onShowEdit = (interval: IIntervalWithUser) => {
    setTargetInterval(interval)
    setIsOpen('edit')
  }

  const onRemove = async (id: number) => {
    try {
      const { title, confirm, body, cancel } = confirmTextHelper('интервала', 'интервал')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) {
        return
      }

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_USER_DECODE_INTERVALS_URL + id
      })
      toast.success(data.message)
      await refetchIntervals()
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onEdit = async (formData: IIntervalForm) => {
    try {
      const { data } = await mutation.mutateAsync({
        method: 'patch',
        data: formData,
        url: UPDATE_USER_DECODE_INTERVALS_URL + targetInterval.id
      })

      toast.success(data.message)
      await refetchIntervals()
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onSubmit = async (formData: IIntervalForm) => {
    try {
      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: CREATE_USER_DECODE_INTERVALS_URL,
        data: formData
      })

      toast.success(data.message)
      onClose()
      await refetchIntervals()
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  if (!projects || !responseUsers) {
    return <Loader/>
  }

  return (
    <>
      {isOpen === 'create' &&
          <IntervalForm
              isOpen={!!isOpen}
              onClose={onClose}
              projects={projects}
              users={responseUsers.data}
              onSubmit={onSubmit}
              intervals={responseIntervals!.intervals}
              title={'Создание интервала'}
          />
      }

      {isOpen === 'edit' &&
          <IntervalForm
              isOpen={!!isOpen}
              onClose={onClose}
              projects={projects}
              users={responseUsers.data}
              onSubmit={onEdit}
              intervals={responseIntervals!.intervals}
              title={'Редактирование интервала'}
              defaultValues={targetInterval}
          />
      }

      {isOpen === 'info' &&
          <Dialog open={!!isOpen} onClose={onClose} fullScreen>
              <AppBar>
                  <Toolbar>
                      <Button
                          color={'info'}
                          variant={'contained'}
                          onClick={onClose}
                      >
                          Закрыть
                      </Button>
                      <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
                          Инфографика
                      </Typography>
                      <Typography variant={'h6'} fontWeight={'bold'}>
                          Серым цветом обозначены свободные интервалы
                      </Typography>
                  </Toolbar>
              </AppBar>
              <DialogContent>
                  <div className={styles.doughnut__wrapper}>
                      <Grid container spacing={2}>
                        {Object.entries(responseIntervals!.intervals).map(([key, value]) =>
                          <Grid key={key} item xs={6}>
                            <DoughnutChart
                              count={value.count}
                              busyIntervals={value.busyIntervals}
                              freeIntervals={value.freeIntervals}
                              processedIntervals={value.processedIntervals}
                              max={value.max}
                              withAccordions={true}
                              centerLabels={[
                                projects.find(project => project.schema === key)?.name || '',
                                key,
                                `${value.count} оград`
                              ]}
                            />
                          </Grid>
                        )}
                      </Grid>
                  </div>
              </DialogContent>
          </Dialog>
      }

      <Card elevation={4} className={styles.card}>
        <CardHeader title={'Интервалы для расшифровщика'}/>

        <CardContent>
          <Typography variant={'h6'}>Заданные интервалы</Typography>
          <div className={styles.table_wrapper}>
            {responseIntervals?.intervalsWithUser.length === 0
              ? <Typography>Нет заданных интервалов</Typography>
              : <table cellSpacing={5} width={'100%'}>
                <thead>
                <tr>
                  <th>Схема</th>
                  <th>Старт</th>
                  <th>Конец</th>
                  <th>E-mail</th>
                  <th>Фамилия</th>
                  <th>Имя</th>
                  <th colSpan={2}>Действия</th>
                </tr>
                </thead>

                <tbody>
                {responseIntervals?.intervalsWithUser
                  .sort((A, B) => A.schema > B.schema ? 1 : -1)
                  .map((interval) =>
                    <tr key={interval.schema + interval.startId}>
                      <td>{interval.schema}</td>
                      <td>{interval.startId}</td>
                      <td>{interval.endId}</td>
                      <td>{interval.user?.email}</td>
                      <td>{interval.user?.lastname}</td>
                      <td>{interval.user?.firstname}</td>
                      <td className={styles.action_icon}>
                        <Icon
                          icon={'edit'}
                          color={'#ff5b00'}
                          onClick={() => onShowEdit(interval)}
                        />
                      </td>
                      <td className={styles.action_icon}>
                        <Icon
                          icon={'delete'}
                          color={'#ff0000'}
                          onClick={() => onRemove(interval.id)}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            }
          </div>
        </CardContent>

        <CardActions>
          <Button variant={'contained'} onClick={onShowCreate}>Задать интервал</Button>
          <Button
            disabled={responseIntervals?.intervalsWithUser.length === 0}
            variant={'contained'}
            onClick={onShowInfoGraphic}
          >
            Инфографика
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default UserIntervals;