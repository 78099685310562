import React, { FC, useState } from 'react';
import { useCustomMutation } from "../../api/useCustomMutation";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import { ls_getProjectInfo } from "../../helpers/localStorage";
import styles from './exportSearchResults.module.scss';
import { toast } from "react-toastify";
import Loader from "../UI/Loader/Loader";

interface Props {
  url: string
  entity: 'places' | 'graves'
  searchParams: any
  count: number
  sortedName: any
}

const ExportSearchResults: FC<Props> = ({ url, entity, searchParams, count, sortedName }) => {
  const mutation = useCustomMutation()
  const [open, setOpen] = useState(false)
  const [format, setFormat] = useState<undefined | string>(undefined)
  const [loadUrl, setLoadUrl] = useState<undefined | string>(undefined)

  const exportMenuItems = ['GEOJSON', 'XLSX', 'CSV', 'TXT', 'SQL']

  const handleChange = (e: any) => {
    setFormat(e.target.value)
  }

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onSubmit = async () => {
    try {
      if (!format) {
        toast.error('Выберите формат')
        return
      }

      const schema = ls_getProjectInfo().schema
      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: url + `?schema=${schema}&search=${JSON.stringify(searchParams)}&format=${format.toLowerCase()}&entity=${entity}&count=${count}&sortedName=${JSON.stringify(sortedName)}`
      })

      setLoadUrl(data)

      toast.success('Отчёт подготовлен')

      setTimeout(() => {
        setLoadUrl(undefined)
      }, 120000)
    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <div>
      <Button variant={'outlined'} onClick={onOpen}>
        Скачать результаты поиска
      </Button>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle title={'Выгрузка результатов поиска'}/>

        <DialogContent>
          <FormControl fullWidth className={styles.form}>
            <InputLabel id="demo-simple-select-label">Формат</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={format ?? ''}
              label="Формат"
              onChange={handleChange}
            >
              {exportMenuItems.map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {mutation.isLoading &&
              <Loader/>
          }

          {loadUrl && !mutation.isLoading &&
              <Stack spacing={1} padding={1}>
                  <a download href={loadUrl} target={'_blank'} rel="noreferrer">{loadUrl}</a>
              </Stack>
          }
        </DialogContent>

        <DialogActions>
          <Stack direction={'row'} spacing={1}>
            <Button variant={"outlined"} onClick={onSubmit}>
              Подготовить отчёт
            </Button>

            <Button variant={"outlined"} onClick={onClose}>
              Отмена
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportSearchResults;