import React from 'react';
import cn from "classnames";
import styles from "../../customButtons.module.scss";
import Icon from "../../../../UI/Icons/Icon";
import {useSetRowNumber} from "./useSetRowNumber";
import {Button, TextField} from "@mui/material";

const SetRowNumberButton = () => {
  const {mapState, onOpenClose, register, handleSubmit, onSubmit} = useSetRowNumber()

  if (!mapState.rowsRuler.isOn) {
    return (
      <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={onOpenClose}>
        <Icon icon={"addGroup"}/>
      </div>
    )
  }

  return (
    <div className={cn('leaflet-control', 'leaflet-bar', styles.set_row_is_on_btn)}>
      <div className={styles.off_set_row_btn} onClick={onOpenClose}>
        <Icon icon={"close"}/>
      </div>

      <div className={styles.set_row_body}>
        <TextField {...register('rowNumber')} label={'Номер ряда'}/>
        <Button variant={'outlined'} onClick={handleSubmit(onSubmit)}>Задать</Button>
      </div>
    </div>
  );
};

export default SetRowNumberButton;