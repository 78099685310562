import React, { FC, useEffect } from 'react';
import { TableVirtuoso } from 'react-virtuoso'
import TableHeaders from '../table/TableHeaders';
import TableCells from '../table/TableCells';
import { useTable } from '../table/useTable';
import { useRecoilState } from 'recoil';
import { gravesStateAtom } from '../../../store/projectStore';
import { useNavigate } from 'react-router-dom'
import { EDIT_GRAVE_PAGE, GRAVE_PAGE } from '../../../router/routeConsts';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { DELETE_GRAVE_URL, GET_PLACES_SEARCH_RESULTS_URL, WRITE_USER_HISTORY_URL } from '../../../api/urls';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { getGraves } from '../../../api/project/projectApi';
import Loader from '../../../components/UI/Loader/Loader';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import styles from './graves.module.scss';
import cn from 'classnames';
import Icon from '../../../components/UI/Icons/Icon';
import ExportSearchResults from "../../../components/ExportSearchResults/ExportSearchResults";
import { ITableSettings } from "../../../models/ITableSettings";
import { gravesTablesHeaders } from "./useGraves";

const Graves: FC = () => {
  const [gravesState, setGravesState] = useRecoilState(gravesStateAtom)
  const { all, count, searchParams, offset, sortedParams, currentKeys } = gravesState

  const {
    data: response,
    isLoading,
    refetch,
    error,
  } = useQuery([offset, searchParams, sortedParams], () => getGraves(offset, count, searchParams, sortedParams), { retry: false })

  const form = useForm()
  const projectInfo = ls_getProjectInfo()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const { configMUITable } = useTable()

  const toFirstPage = () => {
    setGravesState(prev => ({
      ...prev,
      offset: 0
    }))
  }

  const toLastPage = () => {
    if (all - count < 1) {
      toast.error('Вы уже на последней странице')
      return
    }

    setGravesState(prev => ({
      ...prev,
      offset: all - count
    }))
  }

  const onNext = () => {
    if (all - count > offset) {
      setGravesState(prev => ({
        ...prev,
        offset: prev.offset + count
      }))
    }
  }

  const onPrev = () => {
    if (offset >= count) {
      setGravesState(prev => ({
        ...prev,
        offset: prev.offset - count
      }))
    }
  }

  const onSearch = (query: any) => {
    setGravesState(prev => ({
      ...prev,
      searchParams: query,
      offset: 0
    }))
  }

  const onSort = (key: any) => {
    let param = 'ASC'

    if (gravesState.sortedParams[key] && gravesState.sortedParams[key] === 'ASC') {
      param = 'DESC'
    }

    setGravesState(prev => ({
      ...prev,
      sortedParams: { [key]: param }
    }))
  }

  const onReset = () => {
    setGravesState(prev => ({
      ...prev,
      searchParams: {},
      sortedParams: { monumentNumber: 'ASC' }
    }))

    form.reset()
  }

  const toDetails = (id: number) => {
    navigate(GRAVE_PAGE + id)
  }

  const onDelete = ({ id, objectNumber }: { id: number, objectNumber: number }) => {
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_GRAVE_URL + id + `?schema=${projectInfo.schema}`,
    })
      .then(({ data }) => {
        const changesDescription = {
          objectId: id,
          action: 'delete',
          objectNumber: objectNumber
        }

        mutation.mutateAsync({
          method: "post",
          data: {
            schema: projectInfo.schema,
            changesDescription,
            actionObject: 'grave'
          },
          url: WRITE_USER_HISTORY_URL
        })

        toast.success(data.message)
        refetch()
      })
      .catch(({ response }) => {
        toast.error(response?.data.message)
        toast.error(response?.data.error)
      })
  }

  const tableSettings: ITableSettings = {
    allKeys: gravesTablesHeaders,
    currentKeys: currentKeys,
    onChangeKeys: keys => {
      setGravesState(prev => ({ ...prev, currentKeys: keys }));
    }
  }

  useEffect(() => {
    if (error) {
      toast.error(
        <p>
          {/*@ts-ignore*/}
          {error.response?.data.message}
          {/*@ts-ignore*/}
          {error.response?.data.error}
        </p>
      )
    }
  }, [error])

  useEffect(() => {
    setGravesState(prev => ({
      ...prev,
      all: response?.data.all
    }))
  }, [response?.data.all, setGravesState])

  if (!projectInfo?.schema) {
    return <h2>Загрузите проект</h2>
  }

  if (!error && (isLoading || !response?.data.graves)) {
    return <Loader/>
  }

  return (
    <div className={styles.graves__container}>
      <TableVirtuoso
        className={styles.custom_scroll}
        style={{ height: 'calc(100vh - 17rem)' }}
        data={error ? [] : response?.data.graves}
        totalCount={error ? 0 : response?.data.graves.length}
        components={configMUITable}
        fixedHeaderContent={() =>
          <TableHeaders
            tableSettings={tableSettings}
            form={form}
            onSearch={onSearch}
            onReset={onReset}
            onSorted={onSort}
            sortedParams={gravesState.sortedParams}
            disabledHeaders={['placeNumber', 'countPhotos']}
          />
      }
        itemContent={(index, grave) =>
          <>
            {!response?.data.graves?.length
              ? <div>Ничего не найдено</div>
              : <TableCells
                cells={{ ...grave, countPhotos: grave.photos?.length || 0 }}
                keysWithWidth={currentKeys}
                onDetails={toDetails}
                onDelete={onDelete}
                editUrl={EDIT_GRAVE_PAGE}
              />}
          </>
        }
      />

      <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} alignItems={'center'} padding={2}>
        <ExportSearchResults url={GET_PLACES_SEARCH_RESULTS_URL} entity={"graves"} searchParams={searchParams} count={response?.data.all} sortedName={sortedParams}/>

        <Typography>с {offset + 1} по {count + offset > all ? all : count + offset} из {all}</Typography>
        <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.first_page_icon)} onClick={toFirstPage}/>
        <Button onClick={onPrev} variant={'contained'}>Предыдущая</Button>
        <Button onClick={onNext} variant={'contained'}>Следующая</Button>
        <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.last_page_icon)} onClick={toLastPage}/>
      </Stack>
    </div>
  );
};

export default React.memo(Graves);