import React from 'react';
import { useRecoilValue } from 'recoil';
import { projectsSelector } from '../../../../store/projectStore';
import DecodeStatistic from './DecodeStatistic';

const DecodeStatisticContainer = () => {
  const { projects } = useRecoilValue(projectsSelector)

  return (
    <div>
      <DecodeStatistic projects={projects}/>
    </div>
  );
};

export default DecodeStatisticContainer;