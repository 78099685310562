import React, { useState } from 'react';
import { Card, CardActions, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCustomMutation } from '../../api/useCustomMutation';
import SelectProject from "../SelectProject/SelectProject";
import styles from './importPlacesAndGravesFromProject.module.scss';
import { ProjectInfo } from "../../helpers/localStorage";
import { toast } from "react-toastify";
import { MOVE_PLACE_AND_GRAVES_FROM_PROJECT_URL } from "../../api/urls";

const ImportPlaceAndGravesFromProject = () => {
  const mutation = useCustomMutation()

  const [projects, setProjects] = useState<{ sourceProject: ProjectInfo | undefined, recipientProject: ProjectInfo | undefined }>({
    sourceProject: undefined,
    recipientProject: undefined
  })

  const onSourceProjectClick = (sourceProject: ProjectInfo) => {
    setProjects((prev) => ({ ...prev, sourceProject }))
  }

  const onRecipientProjectClick = (recipientProject: ProjectInfo) => {
    setProjects((prev) => ({ ...prev, recipientProject }))
  }

  const onSubmit = async () => {
    try {
      if (!projects.recipientProject) {
        toast.error('Выберите проект куда хотите загрузить')
        return
      }

      await mutation.mutateAsync({
        method: 'post',
        url: MOVE_PLACE_AND_GRAVES_FROM_PROJECT_URL,
        data: {
          sourceSchema: projects.sourceProject?.schema,
          recipientSchema: projects.recipientProject?.schema,
          importType: 'all'
        }
      })

      toast.success('Ограды и могилы успешно перенесены')
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (

    <Card className={styles.card} elevation={6}>
      <CardHeader title={'Перенос оград и могил из проекта'}/>

      <div className={styles.wrapper}>
        <div className={styles.wrapper_select_project}>
          <div className={styles.source_project}>
            <p>Откуда</p>

            <div className={styles.project}>
              <SelectProject onProjectClick={onSourceProjectClick}/>
            </div>

            {projects &&
                <h2>{projects.sourceProject ? projects.sourceProject.name : 'Выберите проект'}</h2>
            }

          </div>

          <div className={styles.recipient_project}>
            <p>Куда</p>

            <div className={styles.project}>
              <SelectProject needFirstSelect={false} onProjectClick={onRecipientProjectClick}/>
            </div>

            {projects &&
                <h2>{projects.recipientProject ? projects.recipientProject.name : 'Выберите проект'}</h2>
            }

          </div>
        </div>

        <CardActions>
          <LoadingButton
            className={styles.loading_button}
            loading={mutation.isLoading}
            variant={'outlined'}
            onClick={onSubmit}
          >
            Загрузить
          </LoadingButton>
        </CardActions>
      </div>
    </Card>
  );
};

export default ImportPlaceAndGravesFromProject;