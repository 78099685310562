import * as turf from "@turf/turf";

export const changeWidthOrLength = (param: 'width' | 'length' | 'all', distance: number | {length: number, width: number}, coordinates: number[][]) => {
  const { width, length } = findWidthAndLengthIndexes(coordinates)
  let newCoordinates = [] as any[]

  switch (param) {
    case "width":
      if (typeof distance === 'object') {
        throw new Error('Нельзя изменить на массив дистанций')
      }

      newCoordinates = changeDimensionsOfPolygon(distance, coordinates, width)
      break

    case "length":
      if (typeof distance === 'object') {
        throw new Error('Нельзя изменить на массив дистанций')
      }

      newCoordinates = changeDimensionsOfPolygon(distance, coordinates, length)
      break

    case "all":
      if (typeof distance !== 'object') {
        throw new Error('Длинну и ширину нельзя изменить на одно число, нужен объект') // вида {length: number, width: number}
      }

      const movedCoordinates =  changeDimensionsOfPolygon(distance.width, coordinates, width)
      newCoordinates = changeDimensionsOfPolygon(distance.length,  movedCoordinates, length)
      break

    default:
      break;
  }

  return [[newCoordinates[0], newCoordinates[1], newCoordinates[2], newCoordinates[3], newCoordinates[0]]]
}

export const changeDimensionsOfPolygon = (distance: number, coordinates: number[][],  {firstPoint, secondPoint}:  {firstPoint: number, secondPoint: number}) => {
  const angleHeight = turf.rhumbBearing(coordinates[firstPoint], coordinates[secondPoint])

  coordinates[secondPoint] = turf.transformTranslate(
    turf.point(coordinates[firstPoint]),
    distance,
    angleHeight,
    { units: "meters" }
  )["geometry"]["coordinates"]

  const firstPointForSecondStep = (secondPoint + 1) % 4
  const secondPointForSecondStep = (firstPoint + 3) % 4

  coordinates[firstPointForSecondStep] = turf.transformTranslate(
    turf.point(coordinates[secondPointForSecondStep]),
    distance,
    angleHeight,
    { units: "meters" }
  )["geometry"]["coordinates"]

  return coordinates
}

export const findWidthAndLengthIndexes = (coordinates: number[][]) => {
  const firstLength = turf.length(
    turf.lineString([
      coordinates[0],
      coordinates[1]
    ]),
    { units: 'meters' }
  )
  const secondLength = turf.length(
    turf.lineString([
      coordinates[1],
      coordinates[2]
    ]),
    { units: 'meters' }
  )

  return firstLength > secondLength ?
    { length: { firstPoint: 0, secondPoint: 1 }, width: { firstPoint: 1, secondPoint: 2 } }
    :
    { length: { firstPoint: 1, secondPoint: 2 }, width: { firstPoint: 0, secondPoint: 1 } }
}