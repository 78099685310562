import React, { useState } from 'react';
import { useCustomMutation } from "../../api/useCustomMutation";
import {
  DELETE_GRAVE_WITH_TEMPORARY_RGIS_ID_URL,
  DELETE_PLACES_WITH_TEMPORARY_RGIS_ID_URL,
  WRITE_USER_HISTORY_URL
} from "../../api/urls";
import { toast } from "react-toastify";
import { Card, CardActions, CardContent, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import styles from '../CalculatePlacesTypes/calculatePlacesTypes.module.scss';
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const DeleteTemporaryObjects = () => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()
  const [objects, setObjects] = useState<'graves' | 'places'>('graves')

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const handleChange = (event: any) => {
    setObjects(event.target.value);
  }

  const onSubmit = async () => {
    try {
      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'DeleteTemporaryObjects', objects }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      let currentSchema = project.schema

      const url = objects === 'graves' ? DELETE_GRAVE_WITH_TEMPORARY_RGIS_ID_URL : DELETE_PLACES_WITH_TEMPORARY_RGIS_ID_URL

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: url + `?schema=${currentSchema}`
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Получаем ограды и могилы со временными ргис id. Удаляем могилы, если нет фото. Удаляем ограды, если нет фото и
        координат.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.DeleteTemporaryObjects} onInfoClick={onInfoClick}/>

      <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
        <div>
          <h3>{project?.name}</h3>
        </div>

        <form>
          <InputLabel id="temporary-objects">Объекты</InputLabel>
          <Select
            labelId="temporary-objects"
            id="temporary-objects-select"
            value={objects}
            label="Объекты"
            onChange={handleChange}
          >
            <MenuItem value={'graves'}>Могилы</MenuItem>
            <MenuItem value={'places'}>Ограды</MenuItem>
          </Select>
        </form>
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default DeleteTemporaryObjects;