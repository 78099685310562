import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import styles from './home.module.scss';
import { useNavigate } from "react-router-dom";
import { PROJECTS_PAGE, SIGN_IN_PAGE } from "../../router/routeConsts";
import { ls_getTokens } from "../../helpers/localStorage";

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const tokens = ls_getTokens()

    if (tokens) {
      navigate(PROJECTS_PAGE)
    } else {
      navigate(SIGN_IN_PAGE)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <Typography variant={'h3'}>Карта захоронений</Typography>
    </div>
  );
};

export default Home;