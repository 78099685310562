import React, {FC} from 'react';
import {getIconsPath} from './getIconsPath';
import styles from './icons.module.scss'
import cn from 'classnames'
import {IconType} from '../../../models/IconType';

interface Props {
  icon: IconType
  color?: string
  onClick?: (event: any) => void
  className?: string
  disabled?: boolean
  colorClassProp?: string
}

const Icon: FC<Props> = ({
                           icon, color = '#0048ff',
                           onClick,
                           className,
                           disabled = false,
                           colorClassProp
}) => {
  const {url, colorClass} = getIconsPath(icon)

  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={cn(styles.icon, colorClassProp ? colorClassProp : colorClass, className)}
    >
      <img src={url}/>
    </div>
  )
};

export default Icon;