import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../../store/mapStore";
import {createRef, useMemo, useState} from "react";
import {toast} from "react-toastify";

export const useSetRowNumberMarkers = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const [elRefs, setElRefs] = useState([])

  const onNewPoint = (count: number) => {
    setElRefs((elRefs) =>
      Array(count - 1)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }

  const onDragEnd = (newPoint: any, index: number) => {
    let newGeometry = mapState.rowsRuler.pointsArray.map((point: any, i: number) => {
      return i === index ? {lat: newPoint.lat, lng: newPoint.lng} : point
    })

    if (index === 0) {
      newGeometry[newGeometry.length - 1] = {lat: newPoint.lat, lng: newPoint.lng}
    }

    setMapState((prev) => ({...prev, rowsRuler: {...mapState.rowsRuler, pointsArray: newGeometry}}))
  }

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        const index = Number(e.target.options.title)
        // @ts-ignore
        const marker = elRefs[index].current

        if (marker != null) {
          // @ts-ignore
          onDragEnd(marker.getLatLng(), index)
        }
      },
    }),
    [mapState.rowsRuler.pointsArray],
  )

  const onCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy)

      toast.success('Координаты скопированы')
    } catch (e: any) {
      console.log(e);
      toast.error(e.message)
    }
  }

  return {
    eventHandlers,
    mapState,
    elRefs,
    onNewPoint,
    setMapState,
    onCopy
  }
}