import { atom, selector } from "recoil";
import { ISyncSchemas } from "../models/ISyncSchemas";

export const syncSchemaRgisState = atom<ISyncSchemas>({
  key: 'sync-rgis',
  default: {
    sse: {} as EventSource,
    percent: 0,
    statistic: {}
  }
})

export const syncSchemaRgisSelector = selector({
  key: 'sync-rgis-selector',
  get: ({get}) => {
    return get(syncSchemaRgisState)
  }
})

export const syncSchemaBookState = atom<ISyncSchemas>({
  key: 'sync-book',
  default: {
    sse: {} as EventSource,
    percent: 0,
    statistic: {}
  }
})

export const syncSchemaBookSelector = selector({
  key: 'sync-book-selector',
  get: ({get}) => {
    return get(syncSchemaBookState)
  }
})