import React, {FC, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Button, Card, CardActions, CardContent, CardHeader, Dialog, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useCustomMutation} from '../../api/useCustomMutation';
import SelectProject from "../SelectProject/SelectProject";
import styles from './importVectorsRgis.module.scss';
import ImportVectorsFileMap from "./ImportVectorsFileMap/ImportVectorsFileMap";
import {ls_setProjectInfo} from "../../helpers/localStorage";

interface Props {
  title: string
  url: string
}

const ImportVectorsRgis: FC<Props> = ({url, title}) => {
  const mutation = useCustomMutation()
  const {register, handleSubmit} = useForm()

  const [importErrors, setImportErrors] = useState<string | undefined>()
  const [openMap, setOpenMap] = useState(false)
  const [project, setProject] = useState<any>()

  const onOpenOrCloseMap = () => {
    setOpenMap(!openMap);
  }
  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  const inputLabelProps = {shrink: true}
  const size = 'small'
  const type = 'file'

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries<FileList | string>(data)) {
        if (typeof value === 'string') {
          formData.set(key, value)
          continue
        }

        if (value.length) {
          formData.set(key, value[0])
        }
      }

      formData.set('schema', project.schema)
      formData.set('importType', 'all')

      const res = await mutation.mutateAsync({
        method: 'post',
        url,
        data: formData
      })

      setImportErrors(undefined)
      toast.success(res.data.message)

    } catch (e: any) {
      console.log(e);

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
        </div>
      )

      toast.error(
        <div style={{display: "flex"}}>
          {e.response?.data.error}
        </div>,
        {autoClose: 100000}
      )

      toast.error(
        <div>
          <p>{JSON.stringify(e)}</p>
        </div>
      )
    }
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={title}/>

      <CardContent>
        <Stack spacing={2}>
          <SelectProject onProjectClick={onProjectClick}/>
          {project &&
            <h2>{project ? project.name : 'Выберите проект'}</h2>
          }

          <TextField
            {...register('bordersFile', {required: true})}
            size={size}
            type={type}
            label={'Границы'}
            InputLabelProps={inputLabelProps}
          />
          <TextField
            {...register('sectionsFile', {required: false})}
            size={size}
            type={type}
            label={'Сектора'}
            InputLabelProps={inputLabelProps}
          />
          <TextField
            {...register('placesFile', {required: false})}
            size={size}
            type={type}
            label={'Ограды'}
            InputLabelProps={inputLabelProps}
          />
          <TextField
            {...register('gravesFile')}
            size={size}
            type={type}
            label={'Могилы'}
            InputLabelProps={inputLabelProps}
          />
          {url.includes('rgis') &&
            <>
              <TextField
                {...register('tasksFile', {required: false})}
                size={size}
                type={type}
                label={'Задачи полевиков'}
                InputLabelProps={inputLabelProps}
              />
              <TextField
                {...register('notesFile', {required: false})}
                size={size}
                type={type}
                label={'Записки полевиков'}
                InputLabelProps={inputLabelProps}
              />
            </>
          }
        </Stack>

        {importErrors && importErrors.split(':')[1] &&
          <div style={{color: 'red'}}>
            <p>{importErrors?.split(':')[0]}:</p>
            {importErrors?.split(':')[1]?.split(',').map((id) => (<li>{id}</li>))}
          </div>
        }
      </CardContent>

      <CardActions>
        <Stack direction={"row"} justifyContent={"space-between"} width={'100%'}>
          <LoadingButton
            loading={mutation.isLoading}
            variant={'outlined'}
            onClick={handleSubmit(onSubmit)}
          >
            Загрузить
          </LoadingButton>

          <Button onClick={onOpenOrCloseMap}>
            Карта файлов
          </Button>
        </Stack>
      </CardActions>

      <Dialog open={openMap} fullScreen onClose={onOpenOrCloseMap}>
        <ImportVectorsFileMap onClose={onOpenOrCloseMap}/>
      </Dialog>
    </Card>
  );
};

export default ImportVectorsRgis;