import React, { FC } from 'react';
import { Switch } from '@mui/material'

interface Props {
  onChange: any
  value: boolean | null
}

const CustomSwitch: FC<Props> = (props) => {
  return (
    <Switch
      {...props}
      type={'checkbox'}
      checked={!!props.value}
    />
  );
};

export default CustomSwitch