import { axiosInstance } from '../axios';
import { GET_DECODE_HISTORY_BY_USER_URL, GET_USER_DECODE_INTERVAL_URL, GET_USER_DECODE_INTERVALS_URL } from '../urls';
import { IIntervalWithUser } from '../../models/_admin/IIntervalWithUser';
import { IProjectBusyAndFreeInterval } from '../../models/_admin/IProjectBusyAndFreeInterval';
import { ls_getProjectInfo } from '../../helpers/localStorage';
import { toast } from "react-toastify";

export const getUserDecodeIntervals = async () => {
  try {
    const response = await axiosInstance.get(GET_USER_DECODE_INTERVALS_URL)

    return {
      intervalsWithUser: response.data.intervalsWithUser as IIntervalWithUser[],
      intervals: response.data.intervals as IProjectBusyAndFreeInterval,
    }
  } catch (e: any) {
    toast.error(e.message)
    return {
      intervalsWithUser: [],
      intervals: {}
    }
  }
}

export const getUserDecodeInterval = async () => {
  try {
    const schema = ls_getProjectInfo().schema
    const response = await axiosInstance.get(GET_USER_DECODE_INTERVAL_URL + `?schema=${schema}`)

    return response.data
  } catch (e: any) {
    toast.error(e.message)
    return []
  }
}

export const getDecodeHistoryByUser = async (schema: string, userId: number) => {
  try {
    const response = await axiosInstance.get(GET_DECODE_HISTORY_BY_USER_URL + `?schema=${schema}&userId=${userId}`)

    return response.data
  } catch (e: any) {
    toast.error(e.message)
    return []
  }
}