import React, { FC } from 'react';
import { AppBar, Button, DialogContent, Stack, Table, TableBody, TableHead, Toolbar, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { notExistingPhotosAtom } from "../../store/notExistingPhotosStore";
import { StyledTableCell, StyledTableRow } from "../UI/StyledTable/StyledTable";
import { Link, useNavigate } from "react-router-dom";
import { GRAVE_PAGE, MAP_PAGE, PLACE_PAGE } from "../../router/routeConsts";
import styles from './findNotExistingPhotos.module.scss';
import { useCustomMutation } from "../../api/useCustomMutation";
import { DELETE_NOT_EXISTING_PHOTOS_URL } from "../../api/urls";
import { ls_getProjectInfo } from "../../helpers/localStorage";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

interface Props {
  onClose: () => void
}

const ShowNotExistingPhotos: FC<Props> = ({ onClose }) => {
  const navigate = useNavigate()
  const mutation = useCustomMutation()
  const [notExisingPhotos, setNotExistingPhotos] = useRecoilState(notExistingPhotosAtom)

  const onMapClick = () => {
    setNotExistingPhotos((prev) => ({ ...prev, showOnMap: true }));
    navigate(MAP_PAGE)
  }


  const deleteExistingPhotos = async (type: string) => {
    try {
      const projectSchema = ls_getProjectInfo().schema

      const { data } = await mutation.mutateAsync({
        method: 'post',
        data: {
          schema: projectSchema,
          type: type
        },
        url: DELETE_NOT_EXISTING_PHOTOS_URL
      })

      toast.success(data.message);
    } catch (e: any) {
      toast.error(e.response?.data.message)
    }
  }

  return (
    <>
      <AppBar>
        <Toolbar>
          <Stack direction={'row'} justifyContent={"space-between"} width={'100%'}>
            <Stack direction={'row'} spacing={1}>
              <Button
                color={'info'}
                variant={'contained'}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Button
                color={'info'}
                variant={'contained'}
                onClick={onMapClick}
              >
                Посмотреть на карте
              </Button>
            </Stack>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
              Невыгруженные фотографии
            </Typography>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h6">
              Всего найдено: {notExisingPhotos.graves.length + notExisingPhotos.places.length}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <div className={styles.dialog_container}>
          <Stack direction={"row"} spacing={2} width={'100%'} justifyContent={'space-around'}>
            <div>
              <div className={styles.table_header}>
                <Typography variant={'h4'}>
                  Могилы
                </Typography>

                <LoadingButton
                  loading={mutation.isLoading}
                  color={'error'}
                  variant={'outlined'}
                  onClick={() => deleteExistingPhotos('grave')}
                >
                  Удалить все записи
                </LoadingButton>
              </div>

              <Table className={styles.table}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      id
                    </StyledTableCell>

                    <StyledTableCell>
                      Фотография
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {notExisingPhotos.graves.map(({ photo, grave }) => (
                    <StyledTableRow key={photo}>
                      <StyledTableCell>
                        <Link to={GRAVE_PAGE + grave?.id}>
                          {grave?.id}
                        </Link>
                      </StyledTableCell>

                      <StyledTableCell>
                        {photo}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <div>
              <div className={styles.table_header}>
                <Typography variant={'h4'}>
                  Ограды
                </Typography>

                <LoadingButton
                  loading={mutation.isLoading}
                  color={'error'}
                  variant={'outlined'}
                  onClick={() => deleteExistingPhotos('place')}
                >
                  Удалить все записи
                </LoadingButton>
              </div>

              <Table className={styles.table}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      id
                    </StyledTableCell>

                    <StyledTableCell>
                      Фотография
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {notExisingPhotos.places.map(({ photo, place }) => (
                    <StyledTableRow key={photo}>
                      <StyledTableCell>
                        <Link to={PLACE_PAGE + place?.id + `?common`}>
                          {place?.id}
                        </Link>
                      </StyledTableCell>

                      <StyledTableCell>
                        {photo}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Stack>
        </div>
      </DialogContent>
    </>
  );
};

export default ShowNotExistingPhotos;