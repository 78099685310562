import {ls_getProjectInfo} from "../../../../../helpers/localStorage";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../../store/mapStore";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useCustomMutation} from "../../../../../api/useCustomMutation";
import {SET_ROW_NUMBER_BY_GEOMETRY_URL} from "../../../../../api/urls";
import * as turf from '@turf/turf';
import React from "react";
import {convertCoordsToArray} from "../../../../../helpers/convertCoordsToArray";

export const useSetRowNumber = () => {
  const projectInfo = ls_getProjectInfo();
  const [mapState, setMapState] = useRecoilState(mapStateAtom);
  const {register, handleSubmit} = useForm()
  const mutation = useCustomMutation()

  const onOpenClose = () => {
    if (mapState.isOnRulers) {
      setMapState((prev) => ({...prev, ruler: {isOn: false, pointsArray: []}}))
    }
    if (!mapState.rowsRuler.isOn) {
      setMapState((prev) => ({
        ...prev,
        isOnRulers: true,
        selectedObject: null,
        rowsRuler: {pointsArray: [], isOn: true}
      }))
    } else {
      setMapState((prev) => ({...prev, isOnRulers: false, rowsRuler: {isOn: false, pointsArray: []}}))
    }
  }

  const onSubmit = async (form: any) => {
    try {
      if (!form.rowNumber) {
        toast.error('Введите номер ряда')
        return
      }

      if (!mapState.rowsRuler.pointsArray?.length) {
        toast.error('Выделите ряд на карте')
        return
      }

      const data = {
        rowNumber: form.rowNumber.toString(),
        schema: projectInfo.schema,
        geometry: {type: 'Polygon', coordinates: [mapState.rowsRuler.pointsArray.map((c) => convertCoordsToArray(c))]}
      }

      const result = await mutation.mutateAsync({
        method: 'patch',
        url: SET_ROW_NUMBER_BY_GEOMETRY_URL,
        data
      })

      toast.success(result.data.message)
    } catch (e: any) {
      console.error(e)
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return {
    mapState,
    register,
    handleSubmit,
    onOpenClose,
    onSubmit
  }
}