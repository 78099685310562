import React, { FC, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { IExportUserReport } from "../IExportUserReport";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../../api/useCustomMutation";
import { useQuery } from "react-query";
import { getUserReports } from "../../../api/user-reports/getUserReports";
import Loader from "../../UI/Loader/Loader";
import { toast } from "react-toastify";
import { ls_getProjectInfo } from "../../../helpers/localStorage";
import { EXPORT_USER_REPORT_BY_SAVED_TEMPLATE_URL, EXPORT_USER_REPORT_URL, SAVE_USER_REPORT_URL } from "../../../api/urls";
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";
import { useForm } from "react-hook-form";

interface Form {
  File: any,
  template: {
    label: string,
    value: string,
    url: string
  }
}

interface Props extends IExportUserReport {
  currentUrl: string | undefined
  setCurrentUrl: (url: string) => void
  onOpenClose: () => void
  onOpenLink: () => void
}

const UserReports: FC<Props> = ({ id, entity, onOpenClose, setCurrentUrl, currentUrl, onOpenLink }) => {
  const { data, isLoading, refetch } = useQuery([`${entity}-user-templates`], () => getUserReports(entity))
  const mutation = useCustomMutation()
  const [watchTemplate, setWatchTemplate] = useState<Form>({} as Form)
  const [confirmSave, setConfirmSave] = useState(false)
  const projectInfo = ls_getProjectInfo()

  const { register, handleSubmit } = useForm()

  const onOpenCloseConfirm = () => setConfirmSave(!confirmSave)

  const onShowTemplate = () => {
    if (!watchTemplate?.template?.url) {
      toast.error('Нет ссылки')
      return
    }
    window.open(watchTemplate.template.url, '_blank', 'noopener,noreferrer')
  }

  const onLoad = async () => {
    try {
      let data
      if (watchTemplate.template.value === 'own template') {
        if (!watchTemplate.File[0]) {
          toast.error('Шаблон отчёта не выбран');
          return
        }

        const formData = new FormData()

        formData.append('file', watchTemplate.File[0])
        formData.append('schema', projectInfo.schema)
        formData.append('entity', entity)
        formData.append('id', id.toString())

        const { data: userReportData } = await mutation.mutateAsync({
          method: 'post',
          data: formData,
          url: EXPORT_USER_REPORT_URL,
        })

        data = userReportData
      } else {

        const { data: userReportData } = await mutation.mutateAsync({
          method: 'get',
          url: EXPORT_USER_REPORT_BY_SAVED_TEMPLATE_URL +
            `?template=${watchTemplate.template.value}` +
            `&id=${id}` +
            `&entity=${entity}` +
            `&schema=${projectInfo.schema}`,
        })

        data = userReportData
      }

      setCurrentUrl(data.urls[0])
      toast.success(data?.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onSave = async (form: any) => {
    try {
      if (!watchTemplate.File[0]) {
        toast.error('Шаблон отчёта не выбран');
        return
      }

      const formData = new FormData()

      formData.append('file', watchTemplate.File[0])
      formData.append('schema', projectInfo.schema)
      formData.append('entity', entity)
      formData.append('id', id.toString())
      formData.append('reportName', form.reportName)

      const { data } = await mutation.mutateAsync({
        method: 'post',
        data: formData,
        url: SAVE_USER_REPORT_URL,
      })

      toast.success(data?.message)
      refetch()
      onOpenCloseConfirm()
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  if (isLoading) {
    return <Loader/>
  }

  return (
    <>
      <Autocomplete
        getOptionLabel={(option: any) => option.label}
        onChange={(event, value) => setWatchTemplate({ File: undefined, template: value })}
        options={[
          ...data?.data.map((t: any) => ({ label: t.name, value: t.fileName, url: t.url })),
          {
            label: 'Свой шаблон',
            value: 'own template',
            url: 'none'
          }
        ]}
        renderInput={(params) =>
          <TextField
            label={'Шаблон'}
            {...params}
          />
        }
      />

      {watchTemplate?.template?.value === 'own template' &&
          <TextField
              sx={{ margin: '1rem 0' }}
              // @ts-ignore
              onChange={(event) => setWatchTemplate(prevState => ({ ...prevState, File: event.target.files }))}
              size={'small'}
              type={'file'}
              label={'Свой шаблон'}
              InputLabelProps={{ shrink: true }}
          />
      }

      <DialogActions>
        {currentUrl &&
            <Button onClick={onOpenLink}>
                Скачать
            </Button>
        }

        {watchTemplate?.template?.value && watchTemplate.template.value !== 'own template' &&
            <Button onClick={onShowTemplate}>
                Посмотреть шаблон
            </Button>
        }

        {watchTemplate?.template?.value === 'own template' &&
            <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.editorUser, SCOPES.superUser]}>
                <Button onClick={onOpenCloseConfirm}>
                    Сохранить шаблон
                </Button>
            </PermissionsGate>
        }

        <LoadingButton
          loading={mutation.isLoading}
          onClick={onLoad}
        >
          Сформировать отчёт
        </LoadingButton>

        <Button onClick={onOpenClose}>
          Отмена
        </Button>
      </DialogActions>

      <Dialog open={confirmSave} onClose={onOpenCloseConfirm}>
        <DialogTitle>
          Введите название нового шаблона
        </DialogTitle>

        <DialogContent style={{ padding: '1rem' }}>
          <TextField
            {...register('reportName', { required: true })}
            size={'small'}
            type={'text'}
            label={'Название шаблона'}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={mutation.isLoading}
            onClick={handleSubmit(onSave)}
          >
            Сохранить отчёт
          </LoadingButton>

          <Button onClick={onOpenCloseConfirm}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default UserReports;