import React, { useEffect } from 'react';
import { toast } from "react-toastify";
import { FIX_DATES_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import styles from "../../pages/_admin/features/import/schemas/importShemas.module.scss";
import { LoadingButton } from "@mui/lab";
import useEditUserPermission
  from "../../pages/_admin/users/userActions/actions/editUserPermission/useEditUserPermission";
import { IProject } from "../../models/IProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const FixDates = () => {
  const {
    projectsData,
    setProjects,
    currentProjects,
    projects,
    theme,
    handleChange,
    MenuProps,
    getStyles,
    mutation
  } = useEditUserPermission(1)

  const onSubmit = async () => {
    try {
      const schemas = [] as string[];
      const projectsID = [] as number[];

      currentProjects.map((name)=>{
        projects.map((p)=>{
          if(p.name === name) {
            schemas.push(p.schema)
            projectsID.push(p.id)
          }
        })
      })

      for (let i = 0; i<schemas.length; i++) {

        let changesDescription = {
          action: 'algorithm',
          objectId: projectsID[i],
          data: [{algorithmName: 'FixDates', }]
        } as any

        await mutation.mutateAsync({
          method: "post",
          data: {
            schema: schemas[i],
            changesDescription,
            actionObject: 'project'
          },
          url: WRITE_USER_HISTORY_URL
        })
      }

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: FIX_DATES_URL,
        data: { schemas }
      })
      toast.success(data.message)

    } catch (e: any) {
      toast.error(
        <div>
        <p>{e.response?.data?.error}</p>
        <p>{e.response?.data?.message}</p>
      </div>
      )
    }
  }

  useEffect(()=>{
    if(projectsData) {
      setProjects(projectsData.data)
    }
  },[projectsData])

  const onInfoClick = () => {
    toast(
      <>
        Корректировка дат могил и оград.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.FixDates} onInfoClick={onInfoClick}/>

      <CardContent>
        <FormControl>
          <InputLabel>Назначить новые проекты</InputLabel>

          <Select
            multiple
            value={currentProjects}
            onChange={handleChange}
            style={{width:'25rem'}}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Назначить новые проекты"
              />
            }
            renderValue={(selected: any) => (
              <Box className={styles.overflow} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any, index: number) => (
                  <Chip key={index} label={value}/>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {projects.map((project: IProject) => (
              <MenuItem
                key={project.name}
                value={project.name}
                style={getStyles(project.name, currentProjects, theme)}
              >
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>

      <CardActions>
        <LoadingButton
          loading={mutation.isLoading}
          onClick={onSubmit}
          variant={'contained'}
          disabled={!currentProjects.length}
        >
          Начать проверку
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default FixDates;