import { INotOkCandidatesOfSync } from "../../../models/IRgisAttributes";
import { TableCell, TableRow } from "@mui/material";
import { showInfoField } from "../../../helpers/showInfoField";
import React from "react";
import { graveDto } from "../../../pages/project/graves/useGraves";

const useShowNotOkRows = (body: INotOkCandidatesOfSync) => {
  const rows = []
  const rgisData = { ...body.rgisData, ...body.rgisData.searchableFields } as any
  for (const {label,fieldName} of graveDto) {
    if (fieldName !== 'id')
      rows.push((
        <TableRow>
          <TableCell>
            {label}
          </TableCell>

          <TableCell>
            {showInfoField(rgisData[fieldName], { dateWithoutTime: true })}
          </TableCell>

          <TableCell>
            {showInfoField(body.graveFromDb[fieldName] as any, { dateWithoutTime: true })}
          </TableCell>
        </TableRow>
      ))
  }

  return rows
}

export default useShowNotOkRows