import React, { FC } from 'react';
import { StyledTableCell, StyledTableRow } from "../../../UI/StyledTable/StyledTable";
import { Button, Stack, Table, TableBody } from "@mui/material";
import styles from "../../findGravesDuplicates.module.scss";
import { IGrave } from "../../../../models/IGrave";
import Icon from "../../../UI/Icons/Icon";
import { graveDto } from "../../../../pages/project/graves/useGraves";
import EditTwoGraves from "../../../EditTwoGraves/EditTwoGraves";
import { showInfoField } from "../../../../helpers/showInfoField";

interface Props {
  openEdit: boolean,
  grave: IGrave,
  duplicate: IGrave,
  onDelete: (id: number, monumentNumber?: number | null) => void,
  onOpenClose: () => void,
  onSyncGraves: (firstGrave: IGrave, secondGrave: IGrave) => void,
}

const GraveRows: FC<Props> = ({ grave, onSyncGraves, onOpenClose, onDelete, openEdit, duplicate }) => {
  if (!openEdit) {
    return (
      <>
        <Table size={'small'}>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                <b>Поле</b>
              </StyledTableCell>

              <StyledTableCell style={{ width: '15rem' }}>
                <b>Могила</b>
              </StyledTableCell>

              <StyledTableCell style={{ width: '5rem' }}>
                <Stack direction={'row'}>
                  <Button onClick={() => onSyncGraves(duplicate, grave)}>
                    <Icon icon={"doubleArrow"} className={styles.rotate_arrow}/>
                  </Button>

                  <Button onClick={() => onSyncGraves(grave, duplicate)}>
                    <Icon icon={"doubleArrow"}/>
                  </Button>
                </Stack>
              </StyledTableCell>

              <StyledTableCell>
                <b>Дубликат</b>
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell>
                <b>Действия</b>
              </StyledTableCell>

              <StyledTableCell>
                <Button color={'error'} onClick={() => onDelete(grave.id, grave.monumentNumber)}>
                  Удалить
                </Button>

                <Button onClick={onOpenClose}>
                  Редактировать
                </Button>
              </StyledTableCell>

              <StyledTableCell/>

              <StyledTableCell>
                <Button color={'error'} onClick={() => onDelete(duplicate.id, grave.monumentNumber)}>
                  Удалить
                </Button>

                <Button onClick={onOpenClose}>
                  Редактировать
                </Button>
              </StyledTableCell>
            </StyledTableRow>

            {graveDto.map(el => (
              <StyledTableRow key={el.fieldName}>
                <StyledTableCell>
                  {el.label}
                </StyledTableCell>

                <StyledTableCell>
                  {// @ts-ignore
                    showInfoField(grave[el.fieldName], {
                      dateWithoutTime: true,
                      withParseInt: false
                    })}
                </StyledTableCell>

                <StyledTableCell/>

                <StyledTableCell>
                  {// @ts-ignore
                    showInfoField(duplicate[el.fieldName], {
                      dateWithoutTime: true,
                      withParseInt: false
                    })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  } else {
    return (
      <Stack direction={"row"} spacing={5} justifyContent={"space-around"}>
        <EditTwoGraves firstGraveId={grave.id} secondGraveId={duplicate.id} cancel={onOpenClose}/>
      </Stack>
    )
  }
};

export default GraveRows;