import React, { MutableRefObject, FC } from 'react';
import styles from './statisticAndActions.module.scss';
import { Box } from '@mui/material';
import Statistic from './Statistic';
import Actions from './Actions';

interface Props {
  timerRef: MutableRefObject<number>
}

const StatisticAndActions: FC<Props> = ({ timerRef }) => {

  return (
    <Box className={styles.wrapper}>
      <Statistic timerRef={timerRef}/>
      <Actions/>
    </Box>
  );
};

export default StatisticAndActions;