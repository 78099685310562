import React, { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Form from '../form/Form';
import { useCustomMutation } from '../../../../api/useCustomMutation';
import styles from './decodeStatistic.module.scss';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { statisticState } from '../../../../store/statisticsStore';
import { GET_DECODE_HISTORY_BY_USER_URL, GET_USERS_BY_SCHEMA_IN_DECODE_HISTORY_URL } from '../../../../api/urls';
import { toast } from 'react-toastify';
import { showDate } from '../../../../helpers/showDate';
import { NavLink } from 'react-router-dom';
import { DECODE_PAGE } from '../../../../router/routeConsts';
import { showInfoField } from '../../../../helpers/showInfoField';
import { deepCopy } from '../../../../helpers/deepCopy';
import dayjs from 'dayjs';
import { IUser } from '../../../../models/IUser';
import { IProject } from '../../../../models/IProject';
import Icon from '../../../../components/UI/Icons/Icon';

interface Props {
  user?: IUser
  projects: IProject[]
}

const DecodeStatistic: FC<Props> = ({ user, projects }) => {
  const [statistic, setStatistic] = useRecoilState(statisticState)
  const resetStatistic = useResetRecoilState(statisticState)
  const mutation = useCustomMutation()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [sortBy, setSortBy] = useState<'date' | 'mistakes'>('date')
  const [placeId, setPlaceId] = useState<number | undefined>(undefined)
  const [allStats, setAllStats] = useState<any>({ history: [] })

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const onSubmit = (data: any) => {
    mutation.mutateAsync({
      method: 'get',
      url: GET_DECODE_HISTORY_BY_USER_URL + `?schema=${data.schema}&userId=${data.userId}`
    })
      .then(({ data }) => {
        toast.success(data.message)
        let stat = deepCopy(data.history)
        stat.history.sort((A: any, B: any) => A.countOfMistakes > B.countOfMistakes ? -1 : 1)
        setAllStats(stat)
        setStatistic(prev => ({ ...prev, decodeUserStatistics: stat }))
        setPlaceId(undefined)
      })
      .catch(({ response }) => {
        toast.error(response?.data.message)
        toast.error(response?.data.error)
      })
  }

  const onChangeSort = (e: SelectChangeEvent) => {
    // @ts-ignore
    setSortBy(e.target.value)
  }

  const onChangeInput = (e: any) => {
    if (Number(e.target.value)) {
      setPlaceId(Number(e.target.value))
    } else if (e.target.value.length === 0) {
      setStatistic(prev => ({ ...prev, decodeUserStatistics: allStats }))
    }
  }

  useEffect(() => {
    if (statistic.decodeUserStatistics.history) {
      if (!sortBy) {
        let stat = deepCopy(statistic.decodeUserStatistics)
        stat.history.sort((A: any, B: any) => A.countOfMistakes > B.countOfMistakes ? -1 : 1)
        setStatistic(prev => ({ ...prev, decodeUserStatistics: stat }))
      } else {
        let stat = deepCopy(statistic.decodeUserStatistics)
        stat.history.sort((A: any, B: any) => dayjs(A.dateCreated).diff(B.dateCreated) > 0 ? -1 : 1)
        setStatistic(prev => ({ ...prev, decodeUserStatistics: stat }))
      }
    }
  }, [sortBy])

  useEffect(() => {
    if (placeId) {
      let stat = deepCopy(allStats)
      stat.history = stat.history.filter((A: any) =>
        A.idPlace ? A.idPlace.toString().search(placeId.toString()) !== -1 : false
      )

      setStatistic(prev => ({ ...prev, decodeUserStatistics: stat }))
    }

    return () => {
      resetStatistic()
    }
  }, [placeId])

  return (
    <Box className={styles.wrapper}>
      <Stack spacing={3}>

        <Form
          projects={projects}
          setStatistic={setStatistic}
          isLoading={mutation.isLoading}
          onSubmit={onSubmit}
          defaultUser={user}
          usersUrl={GET_USERS_BY_SCHEMA_IN_DECODE_HISTORY_URL}
        />

        {statistic.decodeUserStatistics?.history &&
            <div>
                <div className={styles.header}>
                    <p>Обработано: {allStats.history.length}</p>

                    <p>
                        Общее кол-во ошибок:
                      {allStats.history.reduce((acc: any, history: any) => acc + history.countOfMistakes, 0)}
                    </p>

                    <FormControl sx={{ width: '15rem' }}>
                        <InputLabel id="select-sorting">Сортировать по</InputLabel>
                        <Select
                            onChange={onChangeSort}
                            value={sortBy}
                            size={'small'}
                            label={'Сортировать по'}
                            labelId={'select-sorting'}
                        >
                            <MenuItem value={'mistakes'}>Количеству ошибок</MenuItem>
                            <MenuItem value={'date'}>Дате</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="findById"
                        label="Поиск по номеру ограды"
                        variant="outlined"
                        color="secondary"
                        size={'small'}
                        onChange={(e) => onChangeInput(e)}
                    />
                </div>

                <div className={styles.history__wrapper}>
                  {statistic.decodeUserStatistics.history.map((history, index) => (
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      key={index}
                    >

                      <AccordionSummary
                        expandIcon={<Icon icon={'expandMore'}/>}
                        aria-controls="panel1bh-content"
                      >
                        <Stack spacing={2} padding={1} direction={'row'} alignItems={'center'}>
                          <NavLink className={styles.link} to={DECODE_PAGE + history.idPlace}>
                            Открыть ограду с id {history.idPlace} в расшифровщике
                          </NavLink>

                          <Typography>
                            Количество ошибок: {history.countOfMistakes}
                          </Typography>

                          <Typography>
                            Потраченное время: {history.secondsSpent}c
                          </Typography>

                          <Typography sx={{ color: 'text.secondary' }}>
                            {showDate(history.dateCreated, { withTime: true })}
                          </Typography>
                        </Stack>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Stack spacing={1}>
                          {history.fields.map((field, index) => (
                            <Stack direction={'row'} justifyContent={'space-between'} key={index}>
                              <span
                                className={field.mistake ? styles.mistake_field : styles.field}
                              >
                                {Object.keys(field)[0]}
                              </span>

                              <span
                                className={field.mistake ? styles.mistake_field : styles.field}
                              >
                                {showInfoField(field[Object.keys(field)[0]])}
                              </span>
                            </Stack>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
            </div>
        }
      </Stack>
    </Box>
  );
};

export default DecodeStatistic;