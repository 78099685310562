import React, { FC } from 'react';
import GraveRows from "./GraveRows";
import PlaceRows from "./PlaceRows";
import { IGrave } from "../../../../models/IGrave";
import { IPlace } from "../../../../models/IPlace";

interface IOpenEdit {
  grave: boolean,
  place: boolean
}

interface Props {
  openEdit: IOpenEdit,
  grave: IGrave,
  duplicate: IGrave,
  onDeleteGrave: (id: number, monumentNumber?: number | null) => void,
  onDeletePlace: (id: number | undefined, placeNumber?: number | null) => void,
  onOpenClose: (entity: 'grave' | 'place') => void,
  onSyncGraves: (firstGrave: IGrave, secondGrave: IGrave) => void,
  onSyncPlaces: (firstPlace: IPlace | undefined, secondPlace: IPlace | undefined) => void,
}

const GraveDuplicateDataRows: FC<Props> = ({
                                             duplicate,
                                             grave,
                                             openEdit,
                                             onOpenClose,
                                             onDeleteGrave,
                                             onSyncGraves,
                                             onDeletePlace,
                                             onSyncPlaces
                                           }) => {
  return (
    <>
      <GraveRows
        openEdit={openEdit.grave}
        grave={grave}
        duplicate={duplicate}
        onDelete={onDeleteGrave}
        onOpenClose={() => onOpenClose('grave')}
        onSyncGraves={onSyncGraves}
      />

      <PlaceRows
        place={grave.place}
        onSyncPlaces={onSyncPlaces}
        duplicate={duplicate.place}
        onDelete={onDeletePlace}
        onOpenClose={() => onOpenClose('place')}
        openEdit={openEdit.place}/>
    </>
  );
};

export default GraveDuplicateDataRows;