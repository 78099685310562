import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { ls_getProjectInfo } from "../../../helpers/localStorage";
import Icon from "../../UI/Icons/Icon";
import CurrentPhoto from "../../../pages/project/decode/content/currentPhoto/CurrentPhoto";
import { INotOkCandidatesOfSync } from "../../../models/IRgisAttributes";
import NotOkCandidatesAccordionHeader from "./NotOkCandidatesAccordionHeader";
import useShowNotOkRows from "./UseShowNotOkRows";
import { MERGE_RGIS_GRAVE_URL } from "../../../api/urls";
import { useCustomMutation } from "../../../api/useCustomMutation";
import { toast } from "react-toastify";

interface Props {
  body: INotOkCandidatesOfSync
  expanded: boolean
  onChange: () => void
  onDelete: (id: number) => void
}

const ShowNotOkCandidatesAccordion: FC<Props> = ({ body, onChange, expanded, onDelete }) => {
  const mutation = useCustomMutation()
  const project = ls_getProjectInfo()

  const rows = useShowNotOkRows(body)

  const onMerge = async () => {
    try {
      await mutation.mutateAsync({
        method: "patch",
        data: {
          schema: project.schema,
          candidatesForUpdate: [{
            attributes:body.rgisData,
            id: body.graveFromDb.id,
            idPlace: body.graveFromDb.idPlace
          }]
        },
        url: MERGE_RGIS_GRAVE_URL
      })

      onDelete(body.graveFromDb.id)
      toast.success('Могила объединена')
    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Accordion expanded={expanded} onChange={onChange}>

      <AccordionSummary expandIcon={<Icon icon={"expandMore"}/>}>
        <NotOkCandidatesAccordionHeader body={body}/>
      </AccordionSummary>

      <AccordionDetails>

        <Stack direction={'row'} spacing={3}>
          <div>
            <CurrentPhoto projectInfo={project} currentGrave={{ photos: body.graveFromDb.photos }}/>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Поле
                </TableCell>

                <TableCell>
                  РГИС данные
                </TableCell>

                <TableCell>
                  Наши данные
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </Stack>

        <Button onClick={onMerge} variant={'contained'}>
          Объединить
        </Button>

      </AccordionDetails>

    </Accordion>
  );
};

export default ShowNotOkCandidatesAccordion;