import React, { FC, useState } from 'react';
import styles from './footer.module.scss';
import { Box, Button, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SetterOrUpdater } from 'recoil';
import { ICurrentDecodePage, SortType } from '../../../../models/ICurrentDecodePage';
import { ls_setCurrentPageDecode } from '../../../../helpers/localStorage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DECODE_PAGE } from '../../../../router/routeConsts';

interface Props {
  setCurrentDecodePage: SetterOrUpdater<ICurrentDecodePage>
  currentPage: number
  countOfPlaces: number
  sortType: SortType
}

const Footer: FC<Props> =
  ({
     setCurrentDecodePage,
     currentPage,
     countOfPlaces,
     sortType
   }) => {
    const navigate = useNavigate()
    const [placeId, setPlaceId] = useState<string>('')

    const handleChangeSort = (e: any) => {
      setCurrentDecodePage(prev => ({
        ...prev,
        sortType: e.target.value
      }))
    }

    const handleChange = (e: any) => {
      setPlaceId(e.target.value)
    }

    const onNext = () => {
      if (currentPage !== countOfPlaces) {
        setCurrentDecodePage(prev => {

          ls_setCurrentPageDecode(prev.currentPage + 1)
          navigate(DECODE_PAGE + prev.placesIds[prev.currentPage].id)

          return {
            ...prev,
            currentPage: prev.currentPage + 1,
            currentGraveIndex: 0
          }
        })

        // to stop loading images
        window.stop()
      }
    }

    const onPrev = () => {
      if (currentPage > 1) {
        setCurrentDecodePage(prev => {

          ls_setCurrentPageDecode(prev.currentPage - 1)
          navigate(DECODE_PAGE + prev.placesIds[prev.currentPage - 2].id)

          return {
            ...prev,
            currentPage: prev.currentPage - 1,
            currentGraveIndex: 0
          }
        })
      }
    }

    const goToPlace = () => {
      setCurrentDecodePage(prev => {
        const found = prev.placesIds.find(place => Number(place.id) === Number(placeId))

        if (found) {
          navigate(DECODE_PAGE + found.id)

          return {
            ...prev,
            currentPage: prev.currentPage + 1
          }
        } else {
          toast.error('Ограда с таким номером не найдена')
          return prev
        }
      })
    }

    return (
      <Box className={styles.wrapper}>

        <Box className={styles.sort__places}>
          <Select fullWidth size={'small'} value={sortType} onChange={handleChangeSort}>
            <MenuItem value={'default'}>По умолчанию</MenuItem>
            <MenuItem value={'isProcessed'}>Сначала необработанные</MenuItem>
          </Select>
        </Box>
        
        <Box className={styles.go__to}>
          <FormControlLabel
            label={'Перейти к:'}
            labelPlacement={'start'}
            control={
              <TextField
                label={'id ограды'}
                size={'small'}
                onChange={handleChange}
                value={placeId}
              />
            }
          />

          <Button variant={'contained'} onClick={goToPlace}>
            Перейти
          </Button>
        </Box>

        <Box className={styles.buttons}>
          <Button
            disabled={currentPage === 1}
            variant={'contained'}
            onClick={onPrev}
          >
            Пред. ограда
          </Button>

          <Button
            disabled={currentPage === countOfPlaces}
            variant={'contained'}
            onClick={onNext}
          >
            След. ограда
          </Button>
        </Box>

        <Box className={styles.count}>
          <Typography variant={'h5'}>{currentPage} из {countOfPlaces} оград</Typography>
        </Box>
      </Box>
    );
  };

export default React.memo(Footer);