// packages
import React, { useState } from 'react'
import { GridColumns } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
// components
import CemeteryActions from './cemeteryActions/CemeteryActions'
// logic
import { getCemeteries, getDeletedCemeteries } from '../../../api/cemetery/getCemeteries'
import { getMunicipalities } from '../../../api/municipality/getMunicipalities'
import { useCustomMutation } from '../../../api/useCustomMutation'
// helpers
import { showDate } from '../../../helpers/showDate'
// urls
import { DELETE_CEMETERIES_URL, RESTORE_CEMETERY_URL, UPDATE_CEMETERIES_URL } from '../../../api/urls'
// types
import { ICemetery } from '../../../models/ICemetery'

export const useCemeteries = () => {
  let dataObj: any
  const [isLoading, setIsLoading] = useState(false)
  const [deletedToggle, setDeletedToggle] = useState(false)
  const deleted = useQuery(['deletedCemeteries'], getDeletedCemeteries)
  const notDeleted = useQuery(['cemeteries'], getCemeteries)

  if (!deletedToggle) {
    dataObj = notDeleted
  } else {
    dataObj = deleted
  }

  const { refetch: cemeteriesRefetch } = notDeleted
  const { refetch: deletedCemeteriesRefetch } = deleted
  const { data: cemeteries } = dataObj
  const { data: municipalities } = useQuery(['municipalities'], getMunicipalities)
  const mutation = useCustomMutation()

  const findMunicipalityById = (id: any) => {
    const municipality = municipalities?.data.municipalities.find((obj: any) => obj.id === id)
    if (!municipality) {
      return 'Загрузка'
    } else {
      return municipality.name
    }
  }

  const successMutation = (message: string) => {
    toast.success(message);
    cemeteriesRefetch()
    deletedCemeteriesRefetch()
    setIsLoading(false)
  }

  const handleToggle = () => {
    setDeletedToggle(!deletedToggle)
  }

  const rows: any = cemeteries?.data.cemeteries.map((cemetery: ICemetery) => ({
    id: cemetery.id,
    name: cemetery.name,
    idRgis: cemetery.idRgis,
    idMunicipality: cemetery.idMunicipality,
    municipality: findMunicipalityById(cemetery.idMunicipality),
    dateCreated: showDate(cemetery.dateCreated, { withTime: true }),
    dateUpdated: showDate(cemetery.dateUpdated, { withTime: true }),
    dateDeleted: cemetery.dateDeleted ? showDate(cemetery.dateDeleted, { withTime: true }) : 'Не удалён'
  }))

  const onRestore = (id: number) => {
    mutation.mutateAsync({
      method: 'patch',
      url: RESTORE_CEMETERY_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onEdit = (id: any, dto: any) => {
    setIsLoading(true)
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_CEMETERIES_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onRemove = (data: any) => {
    setIsLoading(true)
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_CEMETERIES_URL + data
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
      type: 'string'
    },
    {
      field: 'idRgis',
      headerName: 'ID РГИС',
      width: 200,
      type: 'string'
    },
    {
      field: 'municipality',
      headerName: 'Округ',
      width: 200,
      type: 'string'
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 180
    },
    {
      field: 'dateUpdated',
      headerName: 'Дата изменения',
      width: 180
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 180
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 100,
      renderCell: (params =>
          <CemeteryActions
            params={params}
            onEdit={onEdit}
            onRemove={onRemove}
            isLoading={isLoading}
            municipalities={municipalities?.data.municipalities}
            onRestore={onRestore}
          />
      ),
    }
  ]

  return { columns, rows, handleToggle, isExistDeleted: !deleted.data?.data.cemeteries.length && !deletedToggle }
}