import React, { FC, useEffect, useState } from 'react';
import { AppBar, Button, DialogContent, Pagination, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import styles from "../findGravesDuplicates.module.scss";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import GraveDuplicateAccordion from "./GraveDuplicateAccordion";
import { GravesDuplicatesAtom } from "../../../store/gravesDuplicatesStore";
import { IGravesDuplicates } from "../../../models/_admin/IGravesDuplicates";

interface Props {
  onClose: () => void
}

const ShowGravesDuplicates: FC<Props> = ({ onClose }) => {
  const [duplicates] = useRecoilState(GravesDuplicatesAtom)
  const [duplicatesArray, setDuplicatesArray] = useState<any[]>([])
  const [pagesCount, setPagesCount] = useState(1)
  const [open, setOpen] = useState<string | undefined>(undefined)
  const { register, handleSubmit } = useForm()
  const [page, setPage] = useState<number>(1)

  const goToPage = (form: any) => {
    const pageNumber = Number(form.page)

    if (isNaN(pageNumber)) {
      toast.error('Введите число')
      return
    }

    if (pageNumber > pagesCount || pageNumber < 1) {
      toast.error('Нет такой страницы')
      return;
    }

    setPage(pageNumber);
  }

  const chunkify = (a: any[], size: number) => {
    const arrays = [];

    for (let i = 0; i < a.length; i += size)
      arrays.push(a.slice(i, i + size))

    setPagesCount(arrays.length)

    return arrays[page - 1]
  }

  const onOpenAccordion = (duplicate: IGravesDuplicates) => {
    const key = `${duplicate.id}+${duplicate.duplicateId}`

    if (open !== key) {
      setOpen(key)
    } else {
      setOpen(undefined)
    }
  }

  useEffect(() => {
    const arr = chunkify(duplicates, 10)
    setDuplicatesArray(arr)
  }, [page])

  return (
    <>
      <AppBar>
        <Toolbar>
          <Stack direction={'row'} justifyContent={"space-between"} width={'100%'}>
            <Button
              color={'info'}
              variant={'contained'}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
              Поиск дубликатов могил
            </Typography>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h6">
              Всего найдено: {duplicates.length}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <div className={styles.find_duplicates_wrapper}>
          <div className={styles.find_duplicates__table}>
            {duplicatesArray.map((elem) => (
              <GraveDuplicateAccordion
                key={`${elem.id}+${elem.duplicateId}`}
                onChange={() => onOpenAccordion(elem)}
                expanded={`${elem.id}+${elem.duplicateId}` === open}
                duplicate={elem}
              />
            ))}
          </div>

          <div className={styles.find_duplicates__pagination}>
            <TextField
              label="Перейти на страницу"
              variant="outlined"
              {...register('page')}
            />

            <Button
              variant={"outlined"}
              onClick={handleSubmit(goToPage)}
            >
              Перейти
            </Button>

            <div className={styles.__pagination}>
              <Pagination
                onChange={(e, v) => setPage(v)}
                page={page}
                count={pagesCount}
                siblingCount={10}
                variant="outlined"
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                sx={{ height: '100%' }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default ShowGravesDuplicates;