import React, { useState } from 'react'
import { useQuery } from "react-query"
import { getDeletedMunicipalities, getMunicipalities } from "../../../api/municipality/getMunicipalities"
import { useCustomMutation } from "../../../api/useCustomMutation"
import { IMunicipality } from "../../../models/IMunicipality"
import { showDate } from "../../../helpers/showDate"
import { GridColumns } from "@mui/x-data-grid"
import MunicipalityActions from "./municipalityActions/MunicipalityActions"
import { DELETE_MUNICIPALITY_URL, RESTORE_MUNICIPALITY_URL, UPDATE_MUNICIPALITY_URL } from "../../../api/urls"
import { AxiosResponse } from "axios"
import { toast } from "react-toastify"

export const useMunicipalities = () => {
  let dataObj: any
  const [deletedToggle, setDeletedToggle] = useState(false)
  const deleted = useQuery(['deletedMuni'], getDeletedMunicipalities)
  const notDeleted = useQuery(['muni'], getMunicipalities)
  if (!deletedToggle) {
    dataObj = notDeleted
  } else {
    dataObj = deleted
  }
  const { refetch: muniRefetch } = notDeleted
  const { refetch: deletedMuniRefetch } = deleted
  const mutation = useCustomMutation()
  const { data: municipalities } = dataObj
  const [isLoading, setIsLoading] = useState(false)

  const handleToggle = () => {
    setDeletedToggle(!deletedToggle)
  }

  const successMutation = (message: string) => {
    toast.success(message);
    muniRefetch()
    deletedMuniRefetch()
    setIsLoading(false)
  }

  const rows: any = municipalities?.data.municipalities.map((municipality: IMunicipality) => ({
    id: municipality.id,
    name: municipality.name,
    dateCreated: showDate(municipality.dateCreated, { withTime: true }),
    dateUpdated: showDate(municipality.dateUpdated, { withTime: true }),
    dateDeleted: municipality.dateDeleted ? showDate(municipality.dateDeleted, { withTime: true }) : 'Не удалён'
  }))

  const onRestore = (id: number) => {
    setIsLoading(true)

    mutation.mutateAsync({
      method: 'patch',
      url: RESTORE_MUNICIPALITY_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onEdit = (id: any, dto: any) => {
    setIsLoading(true)

    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_MUNICIPALITY_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onRemove = (id: any) => {
    setIsLoading(true)

    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_MUNICIPALITY_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
      type: 'string'
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 180
    },
    {
      field: 'dateUpdated',
      headerName: 'Дата изменения',
      width: 180
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 180
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 120,
      renderCell: (params =>
          <MunicipalityActions
            params={params}
            onEdit={onEdit}
            onRemove={onRemove}
            isLoading={isLoading}
            onRestore={onRestore}
          />
      ),
    }
  ]

  return { columns, rows, handleToggle, isExistDeleted: !deleted.data?.data.municipalities.length && !deletedToggle }
}