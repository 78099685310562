import React, { FC, useState } from 'react';
import styles from './mapLayoutModal.module.scss';
import Icon from "../../../../components/UI/Icons/Icon";
import { Link } from "react-router-dom";
import { PLACE_PAGE } from "../../../../router/routeConsts";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import { ls_getProjectInfo } from "../../../../helpers/localStorage";
import SelectProject from "../../../../components/SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import { toast } from "react-toastify";
import { MOVE_PLACE_AND_GRAVES_FROM_PROJECT_URL } from "../../../../api/urls";
import { Checkbox, FormControlLabel } from "@mui/material";

interface Props {
  onCloseSelectFinger: () => void
}

const ImportPlacesAndSectionsModal: FC<Props> = ({onCloseSelectFinger}) => {
  const [mapState] = useRecoilState(mapStateAtom)
  const currentProject = ls_getProjectInfo()
  const mutation = useCustomMutation()

  const [recipientProject, setProject] = useState<any>()
  const [checked, setChecked] = useState(false)

  const onProjectClick = (recipientProject: any) => {
    setProject(recipientProject)
  }

  if (!mapState.selectFinger.isOn) {
    return <></>
  }

  const onSubmit = async () => {

    try {
      const sectionsId: number[] = []
      mapState.selectFinger.sectionsArray.map((s) => {
        sectionsId.push(s.id)
      })

      const placesId: number[] = []
      mapState.selectFinger.placesArray.map((p) => {
        placesId.push(p.id)
      })

      let importType: 'sections' | 'places' | 'placesAndSections' | 'withSections' = 'places'

      if (mapState.selectFinger.sectionsArray.length) {
        importType = 'sections'
      }

      if (mapState.selectFinger.placesArray.length) {
        importType = 'places'
      }

      if (mapState.selectFinger.placesArray.length && mapState.selectFinger.sectionsArray.length) {
        importType = 'placesAndSections'
      }

      if (checked) {
        importType = 'withSections'
      }

      if (!mapState.selectFinger.placesArray.length && !mapState.selectFinger.sectionsArray.length) {
        toast.error('Выберите ограды или сектора, которые хотите перенести')
        return
      }

      if (!recipientProject) {
        toast.error('Выберите проект куда хотите перенести')
        return
      }

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: MOVE_PLACE_AND_GRAVES_FROM_PROJECT_URL,
        data: {
          sourceSchema: currentProject.schema,
          recipientSchema: recipientProject.schema,
          importType: importType,
          sources: {
            idPlaces: placesId,
            idSections: sectionsId
          }
        }
      })

      toast.success(data.message)

    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <>
      <div className={styles.modal_select_finger}>

        <div className={styles.icon_wrapper}>
          <div className={styles.select_finger_btn}>
            <LoadingButton
              loading={mutation.isLoading}
              variant={'outlined'}
              onClick={onSubmit}
            >
              Начать
            </LoadingButton>
          </div>
          <span>Выберите ограды или сектора, которые хотите перенести</span>
          <div onClick={onCloseSelectFinger} className={styles.close}>
            <Icon icon={"close"}/>
          </div>
        </div>

        <div className={styles.project_wrapper}>
          <div className={styles.current_project}>
            <span>Откуда:</span>
            <h2>{currentProject.name}</h2>
          </div>

          <div className={styles.recipient_project}>
            <span>Куда:</span>
            {recipientProject &&
                <h2 style={{ paddingBottom: 5 }}>{recipientProject.name}</h2>
            }
            <SelectProject needFirstSelect={false} onProjectClick={onProjectClick}/>
          </div>
        </div>

        <div className={styles.select_finger_content_wrapper}>

          <div>
            <div className={styles.select_finger_checkbox}>
              <FormControlLabel
                disabled={!mapState.selectFinger.sectionsArray.length}
                control={<Checkbox onChange={() => setChecked(!checked)}/>}
                label="Перенести геометрию секторов"
              />
            </div>

            <span className={styles.name_sections}>Сектора:</span>
            {mapState.selectFinger.sectionsArray?.map((section: any) =>
              <div key={section.uuid} className={styles.select_finger_content}>
                <span>Сектор №</span>
                <span>{section.sectionNumber}</span>
              </div>
            )}
          </div>

          <div style={{ paddingTop: 22 }}>
            <span className={styles.name_places}>Ограды:</span>
            {mapState.selectFinger.placesArray?.map((place: any) =>
                <div key={place.uuid} className={styles.select_finger_content}>
                  <Link style={{ textDecoration: 'none' }} to={PLACE_PAGE + place.id}>
                  <span>Ограда №</span>
                  <span>{place.placeNumber}</span>
                  </Link>
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportPlacesAndSectionsModal;