import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import styles from './stubStatistic.module.scss'
import { toast } from "react-toastify";
import StubsTable from "./StubsTable";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRecoilState } from "recoil";
import { useCustomMutation } from "../../api/useCustomMutation";
import { stubsState } from "../../store/stubsStore";
import { ls_setProjectInfo } from "../../helpers/localStorage";
import { SEARCH_STUBS_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const StubsFinding = () => {
  const [project, setProject] = useState<any>()
  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const mutation = useCustomMutation()
  const [stubs, setStubs] = useRecoilState(stubsState)
  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const handleSubmit = async () => {
    try {
      ls_setProjectInfo(project)

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{algorithmName: 'StubsFinding',}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'post',
        data: { schema: project.schema },
        url: SEARCH_STUBS_URL
      })

      setStubs(data.stubs[project.schema])

      toast.success(data.message);
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }
  const onInfoClick = () => {
    toast(
      <>
        <p>Удаляем следующие записи: неизвестно неизвесно н/д нд нет инофрмации данных null информация отсутсвует безымянный безымяный</p>
        <p>Проверяем даты: </p>
        <p>1. Если запись = 2001-01-01, то удаляем её</p>
        <p>2. Удаляем даты меньше 1800 года</p>
        <p>3. Выводим даты позже сегодняшней</p>
        <p>4. Выводим случаи, когда дата рождения позже даты смерти</p>
        <p>5. Проверяем поля с номерами документов. Если номер документа содержит меньше 4 символов, то удаляем его</p>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Card elevation={6} className={styles.card_form}>

        <CustomCardHeader title={algorithmsTitles.StubsFinding} onInfoClick={onInfoClick}/>

        <CardContent className={styles.form__wrapper}>
          <SelectProject onProjectClick={onProjectClick}/>

          <h2>{project ? project.name : 'Выберите проект'}</h2>

          <Stack direction={'row'} spacing={2}>
            <Button
              onClick={onOpen}
              disabled={!stubs.places.suspiciousDates.length &&
                !stubs.graves.suspiciousDates.length &&
                !stubs.graves.stubsCount &&
                !stubs.places.stubsCount}
            >
              Посмотреть результаты
            </Button>

            <LoadingButton
              onClick={handleSubmit}
              disabled={!project || mutation.isLoading}
              loading={mutation.isLoading}
            >
              Начать поиск
            </LoadingButton>
          </Stack>

        </CardContent>
      </Card>

      <Dialog open={open} onClose={onClose} fullScreen>

        <AppBar>
          <Toolbar>
            <Button
              color={'info'}
              variant={'contained'}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
              Заглушки
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {stubs &&
              <Card className={styles.data_card}>
                  <CardHeader title={'Обнаруженые заглушки:'}/>

                  <Card>
                      <CardHeader title={project?.name}/>
                      <CardContent>
                          <li>
                              Подозрительные даты:
                            {' ' + (
                              stubs.places.suspiciousDates.length +
                              stubs.graves.suspiciousDates.length).toString()
                            }
                          </li>

                          <li>
                              Заглушки: {stubs.places.stubsCount + stubs.graves.stubsCount}
                          </li>

                          <Stack spacing={2} direction={'row'}>
                              <StubsTable stubs={stubs.places.suspiciousDates} label={'Ограды'}/>
                              <StubsTable stubs={stubs.graves.suspiciousDates} label={'Могилы'}/>
                          </Stack>
                      </CardContent>
                  </Card>
              </Card>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StubsFinding;