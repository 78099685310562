import React from 'react';
import { Grid } from '@mui/material';
import Navbar from '../../../components/UI/Navbar/Navbar';
import {
  ADMIN_FEATURES,
  ADMIN_OTHER_FEATURES,
  ADMIN_STUBS_FEATURES,
  COMPARING_FEATURES,
  IMPORT_FEATURES
} from '../../../router/routeConsts';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';
import { Outlet } from 'react-router-dom';
import styles from './features.module.scss';

const Features = () => {
  const routes = [
    {
      to: ADMIN_FEATURES,
      label: 'Общие',
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.worker, SCOPES.experiencedWorker]
    },
    {
      to: IMPORT_FEATURES,
      label: 'Импорт',
      scopes: [SCOPES.admin, SCOPES.superAdmin]
    },
    {
      to: COMPARING_FEATURES,
      label: 'Совмещение',
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.worker, SCOPES.experiencedWorker]
    },
    {
      to: ADMIN_STUBS_FEATURES,
      label: 'Поиски',
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.decoder, SCOPES.superUser]
    },
    {
      to: ADMIN_OTHER_FEATURES,
      label: 'Остальное',
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.decoder, SCOPES.superUser]
    },
  ]

  return (
    <Grid container className={styles.wrapper}>
      <Grid item>
        <Navbar routes={routes} label={''}/>
      </Grid>

      <Grid item>
        <Outlet/>
      </Grid>
    </Grid>
  )
}

export default Features;