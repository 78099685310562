import React from 'react';
import { useRecoilValue } from 'recoil';
import { projectsSelector } from '../../../../store/projectStore';
import PhotoStatistic from './PhotoStatistic';

const PhotoStatisticContainer = () => {
  const { projects } = useRecoilValue(projectsSelector)
  return (
    <div>
      <PhotoStatistic projects={projects}/>
    </div>
  );
};

export default PhotoStatisticContainer;