// packages
import React, { FC } from 'react'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import { AxiosResponse } from 'axios'
import { Box, Stack, Typography } from '@mui/material'
// styles
import styles from './photoStatistic.module.scss';
// components
import SyncStatisticTable from './syncStatistic/SyncStatisticTable'
import MainStatisticTable from './mainStatistic/MainStatisticTable'
import Form from '../form/Form';
// hooks
import { useCustomMutation } from '../../../../api/useCustomMutation'
// store
import { statisticState } from '../../../../store/statisticsStore'
// urls
import { GET_USER_PHOTO_STATISTIC_URL } from '../../../../api/urls'
// types
import { IStatisticForm } from '../../../../models/_admin/IStatisticForm';
import { IUser } from '../../../../models/IUser';
import { IProject } from '../../../../models/IProject';

interface Props {
  user?: IUser
  projects: IProject[]
}

const PhotoStatistic: FC<Props> = ({ user, projects }) => {
  const mutation = useCustomMutation()
  const [statistic, setStatistic] = useRecoilState(statisticState)
  const { statistics, project } = statistic

  const onSubmit = (data: IStatisticForm) => {
    mutation.mutateAsync({
      method: 'post',
      url: GET_USER_PHOTO_STATISTIC_URL,
      data
    })
      .then(({ data }: AxiosResponse) => {
        setStatistic(prev => ({
          ...prev,
          statistics: data.statistic
        }))
        toast.success(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data.message)
        toast.error(response?.data.error)
      })
  }

  return (
    <Box className={styles.wrapper}>
      <Stack direction={'column'} spacing={5}>
        <Form
          projects={projects}
          setStatistic={setStatistic}
          onSubmit={onSubmit}
          isLoading={mutation.isLoading}
          defaultUser={user}
        />

        <Stack spacing={1}>
          <Stack className={styles.statistic__header}>
            <Typography>
              Общее кол-во фото: {statistics.length}
            </Typography>

            <Typography>
              Кол-во фото оград: {statistics.filter(s => s.type === 'place').length}
            </Typography>

            <Typography>
              Кол-во фото могил: {statistics.filter(s => s.type === 'grave').length}
            </Typography>
          </Stack>

          <Stack spacing={10} direction={'row'}>
            <MainStatisticTable statistics={statistics} project={project}/>
            <SyncStatisticTable statistic={statistics}/>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PhotoStatistic