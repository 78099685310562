import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../store/mapStore";
import {createRef, useMemo, useState} from "react";
import {convertCoordsToArray} from "../../../../helpers/convertCoordsToArray";
import {getArea, getDistanceBetweenTwoPoints} from "./helpers";
import {toast} from "react-toastify";

interface IMeasurements {
  width: number,
  area: number
}

export const useRulerMarkers = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const [elRefs, setElRefs] = useState([])
  const [measurements, setMeasurements] = useState<IMeasurements>({area: 0, width: 0})

  const onNewPoint = (count: number) => {
    setElRefs((elRefs) =>
      Array(count - 1)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }

  const onCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy)

      toast.success('Координаты скопированы')
    } catch (e: any) {
      console.log(e);
      toast.error(e.message)
    }
  }

  const onDragEnd = (newPoint: any, index: number) => {
    let newGeometry = mapState.ruler.pointsArray.map((point: any, i: number) => {
      return i === index ? {lat: newPoint.lat, lng: newPoint.lng} : point
    })

    if (index === 0) {
      newGeometry[newGeometry.length - 1] = {lat: newPoint.lat, lng: newPoint.lng}
    }

    setMapState((prev) => ({...prev, ruler: {...mapState.ruler, pointsArray: newGeometry}}))
  }

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        const index = Number(e.target.options.title)
        // @ts-ignore
        const marker = elRefs[index].current

        if (marker != null) {
          // @ts-ignore
          onDragEnd(marker.getLatLng(), index)
        }
      },
    }),
    [mapState.ruler.pointsArray],
  )

  const onClick = () => {
    if (mapState.isOnRulers) {
      setMapState((prev) => ({...prev, rowsRuler: {isOn: false, pointsArray: []}}))
    }

    if (!mapState.ruler.isOn) {
      setMapState((prev) => ({...prev, isOnRulers: true, selectedObject: null, ruler: {...mapState.ruler, isOn: true}}))
    } else {
      setMapState((prev) => ({...prev, isOnRulers: false, ruler: {isOn: false, pointsArray: []}}))
    }
  }

  const calcMeasurements = () => {
    if (mapState.ruler.pointsArray.length > 2) {
      const points = mapState.ruler.pointsArray.map((point) => convertCoordsToArray(point))

      let myWidth = 0

      for (let i = 0; i < points.length - 2; i++) {
        const firstPoint = points[i]
        const secondPoint = points[i + 1]

        myWidth += getDistanceBetweenTwoPoints(firstPoint, secondPoint)
      }

      points.pop()

      setMeasurements({
        width: myWidth,
        area: points.length > 2 ? getArea(points) : 0
      })
    } else {
      setMeasurements({width: 0, area: 0})
    }
  }

  return {
    measurements,
    calcMeasurements,
    elRefs,
    eventHandlers,
    onNewPoint,
    onClick,
    mapState,
    setMapState,
    onCopy
  }
}