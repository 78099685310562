import React, { FC } from 'react';
import { Button, Stack, Table, TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../UI/StyledTable/StyledTable";
import { IPlace } from "../../../../models/IPlace";
import styles from "../../findGravesDuplicates.module.scss";
import Icon from "../../../UI/Icons/Icon";
import { placeDto } from "../../../../pages/project/places/usePlaces";
import EditTwoPlaces from "../../../EditTwoPlaces/EditTwoPlaces";
import { showInfoField } from "../../../../helpers/showInfoField";

interface Props {
  openEdit: boolean,
  place?: IPlace,
  duplicate?: IPlace,
  onDelete: (id?: number, placeNumber?: number | null) => void,
  onOpenClose: () => void,
  onSyncPlaces: (firstPlace?: IPlace , secondPlace?: IPlace) => void,
}

const PlaceRows: FC<Props> = ({ place, onDelete, onSyncPlaces, duplicate, onOpenClose, openEdit }) => {
  if(!openEdit) {
    return (
      <Table size={'small'}>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell/>
            <StyledTableCell>
              <h3>Ограды</h3>
            </StyledTableCell>
            <StyledTableCell/>
            <StyledTableCell/>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell style={{ width: '55rem' }}>
              <b>Поле</b>
            </StyledTableCell>

            <StyledTableCell style={{ width: '15rem' }}>
              <b>Ограда</b>
            </StyledTableCell>

            <StyledTableCell style={{ width: '5rem' }}>
              <Stack direction={'row'}>
                <Button onClick={() => onSyncPlaces(duplicate, place)}>
                  <Icon icon={"doubleArrow"} className={styles.rotate_arrow}/>
                </Button>

                <Button onClick={() => onSyncPlaces(place, duplicate)}>
                  <Icon icon={"doubleArrow"}/>
                </Button>
              </Stack>
            </StyledTableCell>

            <StyledTableCell>
              <b>Дубликат</b>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell>
              <b>Действия</b>
            </StyledTableCell>

            <StyledTableCell>
              <Button color={'error'} onClick={() => onDelete(place?.id, place?.placeNumber)}>
                Удалить
              </Button>

              <Button onClick={onOpenClose}>
                Редактировать
              </Button>
            </StyledTableCell>

            <StyledTableCell/>

            <StyledTableCell>
              <Button color={'error'} onClick={() => onDelete(duplicate?.id, place?.placeNumber)}>
                Удалить
              </Button>

              <Button onClick={onOpenClose}>
                Редактировать
              </Button>
            </StyledTableCell>
          </StyledTableRow>

          {placeDto.map(el => (
            <StyledTableRow key={el.fieldName}>
              <StyledTableCell>
                {el.label}
              </StyledTableCell>

              <StyledTableCell>
                {place ?
                  <>
                    {// @ts-ignore
                      showInfoField(place[el.fieldName], {
                        dateWithoutTime: true,
                        withParseInt: false
                      })}
                  </>
                  :
                  <p>Нет ограды</p>
                }

              </StyledTableCell>

              <StyledTableCell/>

              <StyledTableCell>
                {duplicate ?
                  <>
                    {// @ts-ignore
                      showInfoField(duplicate[el.fieldName], {
                        dateWithoutTime: true,
                        withParseInt: false
                      })}
                  </>
                  :
                  <p>Нет ограды</p>
                }
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  }else {
    return (
      <Stack direction={"row"} spacing={5} justifyContent={"space-around"}>
        <EditTwoPlaces firstPlaceId={place?.id} secondPlaceId={duplicate?.id} cancel={onOpenClose}/>
      </Stack>
    )
  }
};

export default PlaceRows;