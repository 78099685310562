// packages
import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'
// styles
import styles from '../photoStatistic.module.scss';
// types
import { IPhotoStatistic } from '../../../../../models/_admin/IPhotoStatistic'
// other
import { syncStatistic } from './syncStatistic'

interface Props {
  statistic: IPhotoStatistic[]
}

const SyncStatisticTable: FC<Props> = ({ statistic }) => {
  const syncStatisticData = syncStatistic(statistic)

  return (
    <TableContainer className={cn(styles.listing, styles.sync__listing)}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell align={'center'}>Время</TableCell>
            <TableCell>Оград</TableCell>
            <TableCell>Могил</TableCell>
            <TableCell>Всего</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {syncStatisticData.map((s, index) =>
            <TableRow key={s.startDate + ''}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{s.startDate.format('DD.MM.YYYY HH:mm')}</TableCell>
              <TableCell>{s.gravesCount}</TableCell>
              <TableCell>{s.placesCount}</TableCell>
              <TableCell>{s.placesCount + s.gravesCount}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SyncStatisticTable