import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Dialog, Stack } from "@mui/material";
import styles from "./findNotExistingPhotos.module.scss";
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../api/useCustomMutation";
import { CHECK_PHOTOS_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { notExistingPhotosAtom } from "../../store/notExistingPhotosStore";
import ShowNotExistingPhotos from "./ShowNotExistingPhotos";
import { ls_setProjectInfo } from "../../helpers/localStorage";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const FindNotExistingPhotos = () => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()
  const [notExisingPhotos, setNotExistingPhotos] = useRecoilState(notExistingPhotosAtom)
  const [open, setOpen] = useState(false)

  const onOpenClose = () => setOpen(!open)

  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  const onSubmit = async () => {
    try {
      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'FindNotExistingPhotos' }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      setNotExistingPhotos({ places: [], graves: [], showOnMap: false })

      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: CHECK_PHOTOS_URL + `?schema=${project.schema}`
      })

      if (data.allNotFoundedPhotos) {
        setNotExistingPhotos((prev) => ({ ...prev, ...data.notExistingPhotos }))
      } else {
        setNotExistingPhotos({ places: [], graves: [], showOnMap: false })
      }

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Ищем в базе ссылки на фото, которых нет в облаке.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.container}>
      <CustomCardHeader title={algorithmsTitles.FindNotExistingPhotos} onInfoClick={onInfoClick}/>

      <CardContent>
        {project &&
            <div>
                <h3>{project.name}</h3>
            </div>
        }
      </CardContent>

      <CardActions>
        <Stack spacing={1} alignItems={"center"}>
          <Stack direction={'row'} spacing={3}>
            <SelectProject onProjectClick={onProjectClick}/>

            <LoadingButton
              variant={'outlined'}
              loading={mutation.isLoading}
              onClick={onSubmit}
            >
              Начать поиск
            </LoadingButton>
          </Stack>

          {notExisingPhotos.graves.length || notExisingPhotos.places.length ?
            <>
              <Button variant={"text"} onClick={onOpenClose}>
                Посмотреть ненайденные фото
              </Button>

              <Dialog open={open} onClose={onOpenClose} fullScreen>
                <ShowNotExistingPhotos onClose={onOpenClose}/>
              </Dialog>
            </>
            :
            <></>
          }

        </Stack>
      </CardActions>
    </Card>
  );
};

export default FindNotExistingPhotos;