import React, { FC, useState } from 'react';
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../../components/UI/StyledTable/StyledTable";
import Icon from "../../../../components/UI/Icons/Icon";
import styles from "../info.module.scss";

interface Props {
  data: any,
  label: string,
  defaultOpen?: boolean
}

const InfoTable: FC<Props> = ({ data, label, defaultOpen }) => {

  const [open, setOpen] = useState(defaultOpen)

  return (
    <>
      <Table size={'small'}>
        <TableBody>
          <StyledTableRow className={styles.row} onClick={() => setOpen(!open)}>
            <StyledTableCell className={styles.cell_none_border}>{label}</StyledTableCell>
            <StyledTableCell className={styles.cell_none_border} align={'right'}>
              <IconButton aria-label="expand row" size="small">
                {open
                  ? <Icon className={styles.close_icon} icon={"expandMore"}/>
                  : <Icon icon={"expandMore"}/>
                }
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>

          <TableRow  className={styles.full_width} >
            <TableCell className={styles.full_width}>
              <Collapse in={open} timeout="auto" unmountOnExit collapsedSize={'5px'}>
                <Table size={'small'} aria-label="purchases">
                  <TableBody>
                    {data.map((element: any) => (
                      <StyledTableRow key={element.label}>
                        <StyledTableCell className={styles.cell_none_border}>
                          {element.label}
                        </StyledTableCell>

                        <StyledTableCell className={styles.cell_none_border}>
                          {element.value}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default InfoTable;