// packages
import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { Confirm } from 'react-st-modal';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import * as turf from '@turf/turf';
import * as uuid from 'uuid'
//components
import PlaceAndGraveHeader from '../../headers/PlaceAndGraveHeader/PlaceAndGraveHeader';
import Loader from '../../../components/UI/Loader/Loader';
import ActionList from '../../../components/UI/ActionList/ActionList';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';
import Icon from '../../../components/UI/Icons/Icon';
import { StyledTableCell, StyledTableRow } from "../../../components/UI/StyledTable/StyledTable";
import NoPhotoText from "../../../components/NoPhotoText/NoPhotoText";
import ShowUserActionsHistory
  from "../../_admin/statistics/userActionsStatistic/userActionsStatisticComponents/ShowUserActionsHistory";
import PhotoWithZoom from "../../../components/PhotoWithZoom/PhotoWithZoom";
import ExportGraveOrPlaceReport from "../../../components/ExportGraveOrPlaceReport/ExportGraveOrPlaceReport";
// hooks
import { placeDto } from './usePlaces';
import { useCustomMutation } from '../../../api/useCustomMutation';
// helpers
import { showInfoField } from '../../../helpers/showInfoField';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import { confirmTextHelper } from '../../../helpers/confirmTextHelper';
import { getRandomPointOnPolygon } from '../../../helpers/getRandomPointOnPolygon';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';
import { IUserActionsHistory } from "../../../models/IUserActionsHistory";
// styles
import styles from './places.module.scss';
// store
import { mapStateAtom } from '../../../store/mapStore';
import { MoveGraveAtom } from "../../../store/moveGraveStore";
// urls/api
import { getPlaceById } from '../../../api/project/projectApi';
import { DECODE_PAGE, EDIT_PLACE_PAGE, GRAVE_PAGE, MAP_PAGE } from '../../../router/routeConsts';
import {
  CREATE_GRAVE_URL,
  DELETE_PLACE_PHOTO_URL,
  DELETE_PLACE_URL,
  DELETE_PLANNED_PLACE_BY_ID_URL,
  GET_USER_ACTIONS_HISTORY_URL,
  UPDATE_GRAVE_URL,
  WRITE_USER_HISTORY_URL
} from '../../../api/urls';


interface IActionsHistory {
  open: boolean,
  data: IUserActionsHistory[]
}

const Place = () => {
  const params = useParams()
  const navigate = useNavigate()
  const mutation = useCustomMutation()
  const [actionsHistory, setActionsHistory] = useState<IActionsHistory>({ open: false, data: [] })
  const [moveGrave, setMoveGrave] = useRecoilState(MoveGraveAtom)
  const type = window.location.search.replace('?', '') as 'common' | 'planned'
  const projectInfo = ls_getProjectInfo()
  const [, setMapState] = useRecoilState(mapStateAtom)
  const [showAutoCalc, setShowAutoCalc] = useState(false);

  const {
    data: response,
    isLoading,
    refetch
  } = useQuery(
    ['place', params.id, type],
    () => getPlaceById(Number(params.id!), type),
    {
    onSuccess: ({ data }) => {
      const value = !data.place.area || !data.place.width || !data.place.length
      setShowAutoCalc(value)
    }
  })

  const [currentPlace, setCurrentPlace] = useState<any>(undefined)

  const imageUrl = projectInfo.hostingUrl + projectInfo.imageFolder + '/'

  const toGrave = (id: number) => navigate(GRAVE_PAGE + id)

  const onSubmit = async () => {
    try {
      setActionsHistory({ open: true, data: [] })

      const actionObject = type === 'common' ? 'place' : 'planned_place'

      const query = `?schema=${projectInfo.schema}&actionObject=${actionObject}&objectId=${currentPlace.id}`

      const { data } = await mutation.mutateAsync({
        method: "get",
        url: GET_USER_ACTIONS_HISTORY_URL + query
      })

      setActionsHistory((prev) => ({ ...prev, data }));

    } catch (e: any) {
      setActionsHistory({ open: false, data: [] })

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onPasteGrave = async () => {
    try {
      if (!moveGrave) {
        return
      }

      await mutation.mutateAsync({
        method: "patch",
        data: { idPlace: currentPlace.id, schema: projectInfo.schema },
        url: UPDATE_GRAVE_URL + moveGrave.id
      })

      const changesDescription = {
        objectId: moveGrave.id,
        action: 'move_grave',
        data: [
          {
            fieldName: 'idPlace',
            oldData: moveGrave.idPlace,
            newData: currentPlace.id
          }
        ]
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'grave'
        },
        url: WRITE_USER_HISTORY_URL
      })

      setMoveGrave(undefined)

      await refetch()
      toast.success('Могила успешно перенесена')
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const calcProperties = (property: string) => {
    if (response) {
      if (response.data.place.geometry) {
        switch (property) {
          case 'area':
            return turf.area(response.data.place.geometry).toFixed(2)

          case 'length':
            let firstLength = turf.length(
              turf.lineString([
                response.data.place.geometry.coordinates[0][0],
                response.data.place.geometry.coordinates[0][1]
              ]),
              { units: 'meters' }
            )
            let secondLength = turf.length(
              turf.lineString([
                response.data.place.geometry.coordinates[0][1],
                response.data.place.geometry.coordinates[0][2]
              ]),
              { units: 'meters' }
            )

            return firstLength > secondLength ? firstLength.toFixed(2) : secondLength.toFixed(2)

          case 'width':
            let firstWidth = turf.length(
              turf.lineString([
                response.data.place.geometry.coordinates[0][0],
                response.data.place.geometry.coordinates[0][1]
              ]),
              { units: 'meters' }
            )
            let secondWidth = turf.length(
              turf.lineString([
                response.data.place.geometry.coordinates[0][1],
                response.data.place.geometry.coordinates[0][2]
              ]),
              { units: 'meters' }
            )

            return firstWidth < secondWidth ? firstWidth.toFixed(2) : secondWidth.toFixed(2)
        }
      }
    }

    return;
  }

  const onRemovePhoto = async (id: number, photo: string) => {
    try {
      const { body, title, cancel, confirm } = confirmTextHelper('фото ограды', 'фото ограды')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_PLACE_PHOTO_URL + `?id=${id}&photo=${photo}&schema=${projectInfo.schema}`,
      })

      toast.success(data.message)
      await refetch()
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onOpenPlaceInDecode = () => {
    if (type !== 'common') {
      toast.error('Нельзя открыть запланированную ограду в расшифровщике');
      return
    }

    navigate(DECODE_PAGE + currentPlace.id)
  }

  const onOpenEditPlace = () => {
    navigate(EDIT_PLACE_PAGE + currentPlace.id + '?' + type)
  }

  const onOpenPlaceInMap = () => {
    if (!currentPlace.geometry) {
      toast.error('Нельзя перейти на карту к ограде без геометрии')
      return
    }

    const point: any = getRandomPointOnPolygon(currentPlace.geometry)

    setMapState(prev => ({
      ...prev,
      center: [point.geometry.coordinates[1], point.geometry.coordinates[0]],
      selectedObject: currentPlace,
      preSelectedObject: { id: currentPlace.id, type },
      zoom: 20
    }))

    navigate(MAP_PAGE)
  }

  const onAddGrave = async () => {
    try {
      const feature = getRandomPointOnPolygon(currentPlace.geometry);
      const graveDto = {
        schema: projectInfo.schema,
        uuid: uuid.v4(),
        idPlace: currentPlace.id,
        geometry: JSON.stringify(feature.geometry)
      }

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: CREATE_GRAVE_URL,
        data: graveDto
      })

      toast.success(data.message)
      await refetch()
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onDelete = async () => {
    try {
      if (currentPlace.graves?.length) {
        toast.error('Нельзя удалить ограду в которой есть могилы');
        return;
      }

      const { body, title, cancel, confirm } = confirmTextHelper('ограды', 'ограду')
      const result = await Confirm(body, title, confirm, cancel)
      const url = type === 'common' ? DELETE_PLACE_URL : DELETE_PLANNED_PLACE_BY_ID_URL

      if (!result) return

      await mutation.mutateAsync({
        method: 'delete',
        url: url + currentPlace.id + `?schema=${projectInfo.schema}`
      })

      const changesDescription = {
        objectId: currentPlace.id,
        action: 'delete',
        objectNumber: currentPlace.placeNumber
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: type === 'common' ? 'place' : 'planned_place'
        },
        url: WRITE_USER_HISTORY_URL
      })

      setMapState(prev => ({ ...prev, selectedObject: null }))
      navigate(-1)
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  useEffect(() => {
    setCurrentPlace(response?.data.place)
  }, [response])

  if (isLoading || !response || !currentPlace) {
    return <Loader/>
  }

  return (
    <div>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>

        <PlaceAndGraveHeader title={'Информация об ограде'}/>

        <Stack direction={'row'} spacing={1}>
          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
            {moveGrave &&
                <Button variant={'contained'} onClick={onPasteGrave}>
                    Вставить могилу
                </Button>
            }

            <LoadingButton
              loading={mutation.isLoading}
              variant={'contained'}
              onClick={onSubmit}
            >
              Посмотреть историю изменений
            </LoadingButton>
          </PermissionsGate>

          <ExportGraveOrPlaceReport id={currentPlace.id} entity={'place'}/>

          <Button variant={'contained'} onClick={onOpenPlaceInMap}>
            На карту
          </Button>

          <PermissionsGate scopes={[SCOPES.canEdit]}>
            <ActionList
              items={[
                { cb: onOpenPlaceInDecode, text: 'Открыть ограду в расшифровщике' },
                { cb: onOpenEditPlace, text: 'Редактировать' },
                { cb: onAddGrave, text: 'Добавить могилу' },
                { cb: () => onDelete(), text: 'Удалить ограду' },
              ]}
            />
          </PermissionsGate>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} className={styles.modal_wrapper}>
        <Stack className={styles.place__graves__wrapper} spacing={2}>
          <Stack className={styles.place_info_wrapper} spacing={2}>
            <div>
              <Table aria-label="customized table" size={'small'} stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell/>

                    <TableCell>
                      Из базы данных
                    </TableCell>

                    {showAutoCalc &&
                        <TableCell>
                            Расчитано автоматически
                        </TableCell>
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {placeDto.map(el => (
                    <PermissionsGate key={el.label} scopes={el.permissions}>
                      <StyledTableRow key={el.label}>
                        <StyledTableCell>
                          {el.label}
                        </StyledTableCell>

                        <StyledTableCell>
                          {showInfoField(currentPlace[el.fieldName], { dateWithoutTime: true })}
                        </StyledTableCell>

                        {showAutoCalc &&
                            <StyledTableCell>
                              {calcProperties(el.fieldName)}
                            </StyledTableCell>
                        }
                      </StyledTableRow>
                    </PermissionsGate>
                  ))}
                </TableBody>

              </Table>
            </div>
          </Stack>

          <div className={styles.graves__wrapper}>
            <Table>
              <TableBody>
                {currentPlace.graves?.map((grave: any) =>
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    key={grave.id}
                    onClick={() => toGrave(grave.id)}
                  >
                    <TableCell size={'small'}>
                      <Typography>{grave.id}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{grave.monumentNumber}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{showInfoField(grave.lastname)}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{showInfoField(grave.firstname)}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{showInfoField(grave.patronymic)}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{showInfoField(grave.dob, { dateWithoutTime: true })}</Typography>
                    </TableCell>

                    <TableCell size={'small'}>
                      <Typography>{showInfoField(grave.dod, { dateWithoutTime: true })}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </Stack>

        <Box className={styles.place_img_wrapper}>
          {currentPlace.photos?.length
            ? currentPlace.photos.map((photo: any) => (
              <div key={photo}>
                <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
                  <Icon
                    icon={'delete'}
                    color={'#ff0000'}
                    className={styles.remove_photo}
                    onClick={() => onRemovePhoto(currentPlace.id, photo)}
                  />
                </PermissionsGate>
                <PhotoWithZoom imageUrl={imageUrl + photo.toLowerCase()}/>
              </div>
            ))
            : <NoPhotoText/>
          }
        </Box>
      </Stack>

      <Dialog
        open={actionsHistory.open}
        onClose={() => setActionsHistory({ open: false, data: [] })}
        maxWidth={"xl"}
      >
        <DialogTitle>
          История изменений ограды с id: {currentPlace.id}
        </DialogTitle>

        <DialogContent>
          {
            mutation.isLoading ?
              <Loader/>
              :
              <>
                {
                  actionsHistory.data.length !== 0 ?
                    <ShowUserActionsHistory actionsHistoryProp={actionsHistory.data}/>
                    :
                    <p>Ограда не изменялась</p>
                }
              </>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(Place);