import React, { FC, useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AppBar, Button, DialogContent, Pagination, Stack, TextField, Toolbar, Typography } from "@mui/material";
import styles from "./notOkCandidates.module.scss";
import { notOkCandidatesOfSyncAtom } from "../../../store/notOkCandidatesOfSyncStore";
import { INotOkCandidatesOfSync } from "../../../models/IRgisAttributes";
import ShowNotOkCandidatesAccordion from "./ShowNotOkCandidatesAccordion";

interface Props {
  onClose: () => void
}

const ShowNotOkCandidatesOfSync: FC<Props> = ({ onClose }) => {
  const [notOkCandidatesOfSyncs, setNotOkCandidatesOfSync] = useRecoilState(notOkCandidatesOfSyncAtom);
  const [page, setPage] = useState<number>(1)
  const [diffArray, setDiffArray] = useState<INotOkCandidatesOfSync[]>([])
  const [pagesCount, setPagesCount] = useState(1);
  const [open, setOpen] = useState<number | undefined>(undefined)
  const { register, handleSubmit } = useForm()

  const onOpenAccordion = (id: number) => {
    if (open !== id) {
      setOpen(id)
    } else {
      setOpen(undefined)
    }
  }

  const onDeleteRow = (id: number) => {
    setOpen(undefined)

    const newArr = notOkCandidatesOfSyncs.filter((data) => data.graveFromDb.id !== id)
    setNotOkCandidatesOfSync(newArr)
  }

  const goToPage = (form: any) => {
    const pageNumber = Number(form.page)

    if (isNaN(pageNumber)) {
      toast.error('Введите число')
      return
    }

    if (pageNumber > pagesCount || pageNumber < 1) {
      toast.error('Нет такой страницы');
      return;
    }

    setPage(pageNumber);
  }

  const chunkify = (a: any[], size: number) => {
    const arrays = [];

    for (let i = 0; i < a.length; i += size)
      arrays.push(a.slice(i, i + size));

    setPagesCount(arrays.length)

    return arrays[page - 1]
  }

  useEffect(() => {
    const arr = chunkify(notOkCandidatesOfSyncs, 15)
    setDiffArray(arr)
  }, [page, notOkCandidatesOfSyncs.length])

  return (
    <>
      <AppBar>
        <Toolbar>
          <Stack direction={'row'} justifyContent={"space-between"} width={'100%'}>

            <Button
              color={'info'}
              variant={'contained'}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
              Найденые по Ргис, но не совпадающие могилы
            </Typography>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h6">
              Всего найдено: {notOkCandidatesOfSyncs.length}
            </Typography>

          </Stack>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <div className={styles.search_diff_wrapper}>

          <div className={styles.search_diff__table}>
            {diffArray.map((elem) => (
              <ShowNotOkCandidatesAccordion
                key={elem.graveFromDb.id}
                onChange={() => onOpenAccordion(elem.graveFromDb.id)}
                expanded={elem.graveFromDb.id === open}
                body={elem}
                onDelete={onDeleteRow}
              />
            ))}
          </div>

          <div className={styles.search_diff__pagination}>

            <TextField
              label="Перейти на страницу"
              variant="outlined"
              {...register('page')}
            />

            <Button variant={"outlined"} onClick={handleSubmit(goToPage)}>Перейти</Button>

            <div className={styles.__pagination}>
              <Pagination
                onChange={(e, v) => setPage(v)}
                page={page}
                count={pagesCount}
                siblingCount={10}
                variant="outlined"
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                sx={{ height: '100%' }}
              />
            </div>

          </div>
        </div>

      </DialogContent>
    </>
  );
};

export default ShowNotOkCandidatesOfSync;