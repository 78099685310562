import React from 'react';
import styles from './noPhotoText.module.scss';
import cn from "classnames";

const NoPhotoText = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.center_text}>
          <h1>У данной могилы и/или ограды нет фотографии.</h1>
        </div>

        <div className={cn(styles.center_text, styles.text_with_margin)}>
          почему так могло произойти:
        </div>

        <div className={styles.center_text}>
          <b>Текущая работа специалистов</b>
        </div>

        <div className={styles.text_with_margin}>
          1. Данные могилы вносились после проведения инвентаризации и не подгружалась фотография.
        </div>

        <div className={styles.center_text}>
          <b> Совмещение с РГИС данными </b>
        </div>

        2. После проведения инвентаризации на кладбище, с полученными данными совмещались данные полученные из
        Региональной геоинформационной системы (РГИС) и по этим данным в ограде есть могила.

        <p>
          2.1. "Раньше была табличка и/или данные могилы, сейчас нет или не читаема"
          По объективным причинам надмогильного сооружения на момент прохождения инвентаризации не было (производили
          работы
          по благоустройству, замене надмогильного сооружения/таблички, табличка в траве, закопана, убрана, пропали
          данные,
          выцвела краска, произвели перенос могилы в другое место, поменяли данные на табличке и т.д.)
        </p>

        <p>
          2.2. "Раньше не было таблички, или была не читаема, сейчас появилась"
          Данные на надмогильном сооружении реставрировали родственники, или произошла замена надгробия.
        </p>

        <p>
          2.3. Могила не нашлась на кладбище, хотя есть в РГИС. В ней не было ни координат, ни привязки к другим
          могилам,
          и/или оградам. Такие могилы также есть на кладбище, но не имеют координат и размеров, они доступны в поиске.
        </p>

        <p>
          2.4. Перенос могилы, или эксгумация.
        </p>

        <div className={styles.center_text}>
          <b>Совмещение с книгами захоронений</b>
        </div>

        3. После проведения инвентаризации данные с книг захоронений синхронизировались с собранными данными с кладбища
        и
        могила не была найдена, или не синхронизировалась из-за разных данных в книге и на надмогильном сооружении.
        (Пример: в книге ФИО - Петров Александр Егорович, а на надгробии Петров Сашенька; также могут быть разночтения в
        датах, инициалах, девичья фамилия).

        <p>
          <b>
            Если вы уверены что перед вами явный дубль одной из могилы которая есть в ограде, вы можете совместить
            данные
            этих
            могил используя функционал по редактированию полей, в том числе добавление фотографии.
          </b>
        </p>
      </div>
    </div>
  );
};

export default NoPhotoText;