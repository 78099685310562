import React from 'react';
import UserIntervals from '../additional/userIntervals/UserIntervals';
import { Grid } from '@mui/material';
import ExportDataFromDB from '../../../../components/ExportDataFromDB/ExportDataFromDB';
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";

const MainFeatures = () => {
  return (
    <Grid container spacing={2}>
      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Grid item>
          <UserIntervals/>
        </Grid>
      </PermissionsGate>

      <Grid item>
        <ExportDataFromDB/>
      </Grid>
    </Grid>
  );
};

export default MainFeatures;


