import React, { useEffect } from 'react';
import useMapPlace from './useMapPlace';
import { useParams } from 'react-router-dom';
import { IPlace } from '../../../../models/IPlace';
import { Controller, useForm } from 'react-hook-form';
import { showInfoField } from '../../../../helpers/showInfoField';
import { placeDto } from '../usePlaces';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import styles from './editPlaceFormStyles.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import EditPhotos from "../../../../components/UI/EditPhotos/EditPhotos";
import CustomInputDate from "../../../../components/UI/Inputs/CustomInputDate/CustomInputDate";
import CustomSwitch from "../../../../components/UI/Inputs/Switch/CustomSwitch";
import { DELETE_PLACE_PHOTO_URL } from "../../../../api/urls";

const EditPlaceForm = () => {
  const params = useParams()
  const type = window.location.search.replace('?', '') as 'common' | 'planned'

  const form = useForm<IPlace>({
    defaultValues: {
      placeNumber: 0,
      idSection: 0,
      sectionNumber: '',
      idPlaceRgis: 0,
      rowNumber: '',
      area: 0,
      length: 0,
      width: 0,
      bookNumber: '',
      nicheNumber: 0,
      placeStatus: '',
      placeType: '',
      source: '',
      section: '',
      responsibleFirstname: '',
      responsibleLastname: '',
      responsiblePatronymic: '',
      responsibleAddress: '',
      responsiblePhone: '',
      responsibleBirthplace: '',
      responsibleContact: '',
      responsibleInn: '',
      responsibleDob: new Date().toString(),
      responsiblePassportSeries: '',
      responsiblePassportNumber: '',
      responsiblePassportIssued: '',
      responsibleSnils: '',
    }
  })

  const { place, isLoading, placeState, onSubmit, placeRefetch, cancel } = useMapPlace(Number(params.id), type)

  useEffect(() => {
    if (place) {
      const filtredPlace: any = {}

      for (const { fieldName } of placeDto) {
        if (typeof place[fieldName] === 'boolean') {
          filtredPlace[fieldName] = place[fieldName]
          continue
        }
        filtredPlace[fieldName] = showInfoField(place[fieldName])
      }

      form.reset(filtredPlace)
    }
  }, [form, place])

  if (isLoading || !place) {
    return (<Loader/>)
  }

  return (
    <Stack className={styles.content} direction={'row'} spacing={2}>
      <div className={styles.left__content}>
        <Stack className={styles.inputs__stack}>

          <form>
            <div className={styles.field}>
              <Typography>Не совмещать с РГИС</Typography>

              <div>
                Нет
                <Controller
                  name={'dontMerge'}
                  control={form.control}
                  render={({ field }) =>
                    <CustomSwitch {...field}/>
                  }
                />
                Да
              </div>
            </div>

            <div className={styles.field}>
              <Typography>ID ограды:</Typography>

              <Controller
                name={'id'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    disabled
                    size={'small'}
                    {...field}
                    label={'ID ограды:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>РГИС id:</Typography>

              <Controller
                name={'idPlaceRgis'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'РГИС id:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер места захоронения</Typography>

              <Controller
                name={'placeNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер места захоронения:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер захоронения по книге регистрации захоронений</Typography>

              <Controller
                name={'bookNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер захоронения по книге регистрации захоронений:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер захоронения по книге регистрации захоронений урн с прахом</Typography>

              <Controller
                name={'ashesUrnBookNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер захоронения по книге регистрации захоронений урн с прахом'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>№ сектора:</Typography>

              <Controller
                name={'sectionNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'№ сектора:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>№ ряда:</Typography>

              <Controller
                name={'rowNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'№ ряда:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер стены скорби (колумбария):</Typography>

              <Controller
                name={'wallNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер стены скорби (колумбария):'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер нишы:</Typography>

              <Controller
                name={'nicheNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер нишы:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Вид места захоронения:</Typography>

              <Controller
                name={'placeType'}
                control={form.control}
                render={({ field }) =>
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name || ''}
                    disablePortal
                    defaultValue={null}
                    sx={{ width: 300 }}
                    // @ts-ignore
                    options={placeState.placeType}
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.placeType = e.target.innerText
                      form.formState.dirtyFields.placeType = true
                    }}
                    renderInput={(params) =>
                      <TextField
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        label={'Тип:'}
                        {...params}
                        {...field}
                      />}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Длина</Typography>

              <Controller
                name={'length'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Длина'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Ширина</Typography>

              <Controller
                name={'width'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Ширина'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Площадь</Typography>

              <Controller
                name={'area'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    disabled={true}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Площадь'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Наличие ограждения</Typography>
              <div>
                Нет
                <Controller
                  name={'hasFence'}
                  control={form.control}
                  render={({ field }) =>
                    <CustomSwitch {...field}/>
                  }
                />
                Да
              </div>
            </div>

            <div className={styles.field}>
              <Typography>Высота ограждения</Typography>

              <Controller
                name={'fenceHeight'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Высота ограждения'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Статус:</Typography>

              <Controller
                name={'placeStatus'}
                control={form.control}
                render={({ field }) =>
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name || ''}
                    disablePortal
                    defaultValue={null}
                    sx={{ width: 300 }}
                    // @ts-ignore
                    options={placeState.placeStatus}
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.placeStatus = e.target.innerText
                      form.formState.dirtyFields.placeStatus = true
                    }}
                    renderInput={(params) =>
                      <TextField
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        label={'Статус:'}
                        {...params}
                        {...field}
                      />}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Гос услуга</Typography>

              <Controller
                name={'placeUslugiUrl'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Гос услуга'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Фамилия ответственного</Typography>

              <Controller
                name={'responsibleLastname'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Фамилия ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Имя ответственного</Typography>

              <Controller
                name={'responsibleFirstname'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Имя ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Отчество ответственного</Typography>

              <Controller
                name={'responsiblePatronymic'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Отчество ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Серия паспорта ответственного</Typography>

              <Controller
                name={'responsiblePassportSeries'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Серия паспорта ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер паспорта ответственного</Typography>

              <Controller
                name={'responsiblePassportNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер паспорта ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Кем выдан паспорт ответственного</Typography>

              <Controller
                name={'responsiblePassportIssued'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Кем выдан паспорт ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Место рождения ответственного</Typography>

              <Controller
                name={'responsibleBirthplace'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Место рождения ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата рождения ответственного</Typography>

              <Controller
                control={form.control}
                name={'responsibleDob'}
                render={({ field, formState }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    {...field}
                    error={!!formState.errors?.responsibleDob}
                    helperText={formState.errors?.responsibleDob?.message}
                    label={'Дата рождения ответственного'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Телефон ответственного</Typography>

              <Controller
                name={'responsiblePhone'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Телефон ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Адрес регистрации ответственного</Typography>

              <Controller
                name={'responsibleAddress'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Адрес регистрации ответственного'}
                  />
                }
              />
            </div>


            <div className={styles.field}>
              <Typography>Контакты ответственного</Typography>

              <Controller
                name={'responsibleContact'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Контакты ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>ИНН ответственного</Typography>

              <Controller
                name={'responsibleInn'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'ИНН ответственного'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>СНИЛС ответственного</Typography>

              <Controller
                name={'responsibleSnils'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'СНИЛС ответственного'}
                  />
                }
              />
            </div>
          </form>
        </Stack>

        <div className={styles.buttons}>
          <Button
            onClick={cancel}
            className={styles.submit__button}
            variant={'contained'}
          >
            Выход
          </Button>

          <Button
            onClick={form.handleSubmit(onSubmit)}
            className={styles.submit__button}
            variant={'contained'}
            color={'success'}
          >
            Подтвердить
          </Button>
        </div>
      </div>

      <EditPhotos refetch={placeRefetch} deleteUrl={DELETE_PLACE_PHOTO_URL} photos={place.photos} uuid={place.uuid}/>
    </Stack>
  );
};

export default EditPlaceForm;