import React, {FC} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import styles from './photos.module.scss';
import {ProjectInfo} from '../../../../../helpers/localStorage';
import PhotoWithZoom from "../../../../../components/PhotoWithZoom/PhotoWithZoom";
import {ROTATE_PHOTO_ON_CROC_URL} from "../../../../../api/urls";
import {toast} from "react-toastify";
import {useCustomMutation} from "../../../../../api/useCustomMutation";
import Icon from "../../../../../components/UI/Icons/Icon";

interface Props {
  currentPlace: any
  currentGrave: any
  projectInfo: ProjectInfo
  onSelect: (index: number) => void
  imagesForcer: string
  forceUpdateImages: () => void
}

const Photos: FC<Props> = ({currentPlace, projectInfo, currentGrave, onSelect, imagesForcer, forceUpdateImages}) => {
  const imageUrl = projectInfo.hostingUrl + projectInfo.imageFolder + '/'
  const mutation = useCustomMutation()

  const rotatePhoto = async (photo: string, direction: 'left' | 'right') => {
    try {
      if (!forceUpdateImages) {
        return
      }

      await mutation.mutateAsync({
        method: 'patch',
        url: ROTATE_PHOTO_ON_CROC_URL +
          `?photo=${photo.split('/')[1]}&direction=${direction}&relativePath=places&imageFolder=${projectInfo.imageFolder}`
      })

      forceUpdateImages()
      toast.success('Картинка перевёрнута успешно')
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const currentClassname = (
    currentGraveId: number,
    graveId: number,
    isProcessed: boolean = false
  ): string => {

    if (currentGraveId === graveId) {
      return styles.active_photo
    }

    if (isProcessed) {
      return styles.is__processed__photo
    } else {
      return styles.is__not__processed__photo
    }
  }

  return (
    <Box className={styles.wrapper}>
      <Stack className={styles.place__photos__wrapper}>
        <Typography className={styles.title}>Фото ограды</Typography>

        <Box className={styles.place__photos}>
          {currentPlace?.photos?.length
            ? currentPlace.photos.map((photo: string, index: number) =>
              <Stack key={photo + index}>
                <div className={styles.place_image}>
                  <PhotoWithZoom
                    needTitle={false}
                    imageUrl={imageUrl + photo.replace('.JPG', '.jpg') + `?` + `${imagesForcer}`}
                  />
                </div>

                <div className={styles.icons_wrapper}>
                  <Icon
                    icon={"rotate"}
                    className={styles.rotate_icon}
                    onClick={() => rotatePhoto(photo, "left")}
                  />
                  <Icon
                    icon={"rotate"}
                    className={styles.mirror_icon}
                    onClick={() => rotatePhoto(photo, "right")}
                  />
                </div>
              </Stack>
            )
            : <p>нет фото</p>
          }
        </Box>
      </Stack>

      <Stack className={styles.graves__with__title}>
        <Typography className={styles.title}>Могилы</Typography>

        {currentGrave &&
          <Box className={styles.graves__wrapper}>
            {currentPlace.graves?.map((grave: any, index: number) =>
              <div key={grave.uuid} className={styles.grave__wrapper}>
                {grave.photos?.length
                  ? <img
                    className={currentClassname(currentGrave.id, grave.id, grave.isProcessed)}
                    alt={'graves'}
                    src={imageUrl + grave.photos[0].replace('.JPG', '.jpg') + `?` + `${imagesForcer}`}
                    onClick={() => onSelect(index)}
                  />
                  : <p
                    className={currentClassname(currentGrave.id, grave.id)}
                    onClick={() => onSelect(index)}
                  >
                    нет фото
                  </p>
                }
              </div>
            )}
          </Box>
        }
      </Stack>
    </Box>
  );
};

export default Photos;