import React from 'react';
import { useQuery } from "react-query";
import { getUserReports } from "../../../api/user-reports/getUserReports";
import Loader from "../../../components/UI/Loader/Loader";
import { useUserTemplates } from "./useUserTemplates";
import { DataGrid } from "@mui/x-data-grid";

const UserTemplates = () => {
  const { data, isLoading, refetch } = useQuery([`all-user-templates`], () => getUserReports('all'))
  const { columns } = useUserTemplates(refetch)

  if (isLoading) {
    return <Loader/>
  }

  return (
    <DataGrid
      rows={data?.data}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
    />
  )
};

export default UserTemplates;