// packages
import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, Table, TableBody } from '@mui/material';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm } from 'react-st-modal';
import dayjs from 'dayjs';
import { useRecoilState } from "recoil";
import LoadingButton from "@mui/lab/LoadingButton";
// components
import Loader from '../../../components/UI/Loader/Loader';
import PlaceAndGraveHeader from '../../headers/PlaceAndGraveHeader/PlaceAndGraveHeader';
import ActionList from '../../../components/UI/ActionList/ActionList';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';
import Icon from '../../../components/UI/Icons/Icon';
import { StyledTableCell, StyledTableRow } from "../../../components/UI/StyledTable/StyledTable";
import NoPhotoText from "../../../components/NoPhotoText/NoPhotoText";
import ShowUserActionsHistory
  from "../../_admin/statistics/userActionsStatistic/userActionsStatisticComponents/ShowUserActionsHistory";
import PhotoWithZoom from "../../../components/PhotoWithZoom/PhotoWithZoom";
import ExportGraveOrPlaceReport from "../../../components/ExportGraveOrPlaceReport/ExportGraveOrPlaceReport";
// helpers
import { showInfoField } from '../../../helpers/showInfoField';
import { getGraveById } from '../../../api/project/projectApi';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import { confirmTextHelper } from '../../../helpers/confirmTextHelper';
import { graveDto } from './useGraves';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';
import { NO_INFO } from '../../../utils/utils';
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation';
// styles
import styles from './graves.module.scss';
// other
import {
  DELETE_GRAVE_PHOTO_URL,
  DELETE_GRAVE_URL,
  GET_USER_ACTIONS_HISTORY_URL,
  WRITE_USER_HISTORY_URL
} from '../../../api/urls';
import { DECODE_PAGE, EDIT_GRAVE_PAGE, PLACE_PAGE } from '../../../router/routeConsts';
import { IUserActionsHistory } from "../../../models/IUserActionsHistory";
import { MoveGraveAtom } from "../../../store/moveGraveStore";

interface IActionsHistory {
  open: boolean,
  data: IUserActionsHistory[]
}

const Grave = () => {
  const params = useParams()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const [actionsHistory, setActionsHistory] = useState<IActionsHistory>({ open: false, data: [] })
  const [moveGrave, setMoveGrave] = useRecoilState(MoveGraveAtom)

  const keyWithFixed = 'monumentHeight monumentDepth monumentWidth'

  const projectInfo = ls_getProjectInfo()
  const {
    data: response,
    isLoading,
    refetch
  } = useQuery(['grave', params.id], () => getGraveById(Number(params.id!)))

  const [currentGrave, setCurrentGrave] = useState<any>(undefined)

  const imageUrl = `${projectInfo.hostingUrl + projectInfo.imageFolder}/`

  const toPlacePage = () => navigate(PLACE_PAGE + currentGrave.idPlace + '?common')

  const toEditGravePage = () => navigate(EDIT_GRAVE_PAGE + currentGrave.id)

  const onOpenPlaceOfGraveInDecode = () => navigate(DECODE_PAGE + currentGrave.idPlace)

  const onRemovePhoto = async (id: number, photo: string) => {
    try {
      const { body, title, cancel, confirm } = confirmTextHelper('фото могилы', 'фото могилы')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_GRAVE_PHOTO_URL + `?id=${id}&photo=${photo}&schema=${projectInfo.schema}`
      })

      toast.success(data.message)
      await refetch()
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onSubmit = async () => {
    try {
      setActionsHistory({ open: true, data: [] })

      const query = `?schema=${projectInfo.schema}&actionObject=grave&objectId=${currentGrave.id}`

      const { data } = await mutation.mutateAsync({
        method: "get",
        url: GET_USER_ACTIONS_HISTORY_URL + query
      })

      setActionsHistory((prev) => ({ ...prev, data }))

    } catch (e: any) {
      setActionsHistory({ open: false, data: [] })

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onDelete = async () => {
    try {
      const { body, title, cancel, confirm } = confirmTextHelper('могилы', 'могилу')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_GRAVE_URL + currentGrave.id + `?schema=${projectInfo.schema}`,
      })

      const changesDescription = {
        objectId: currentGrave.id,
        action: 'delete',
        objectNumber: currentGrave.monumentNumber
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'grave'
        },
        url: WRITE_USER_HISTORY_URL
      })

      toast.success(data.message)
      navigate(-1)
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onMoveGrave = () => {
    if (moveGrave) {
      setMoveGrave(undefined)
    } else {
      setMoveGrave(currentGrave)

      toast('Могила скопированна, перейдите в нужную ограду')
    }
  }

  useEffect(() => {
    if (response) {
      const grave = response.data.grave as any
      const sources = response.data.grave.source.map((source: any) => source.source.name)
      setCurrentGrave({ ...grave, sources })
    }
  }, [response])

  if (!currentGrave || isLoading || !response) {
    return <Loader/>
  }

  return (
    <div>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>

        <PlaceAndGraveHeader title={'Информация о могиле'}/>

        <Stack direction={'row'} spacing={1}>

          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
            <Button variant={'contained'} onClick={onMoveGrave}>
              {moveGrave ? 'Отменить перемещение' : 'Переместить в другую ограду'}
            </Button>

            <LoadingButton
              loading={mutation.isLoading}
              variant={'contained'}
              onClick={onSubmit}
            >
              Посмотреть историю изменений
            </LoadingButton>
          </PermissionsGate>

          <ExportGraveOrPlaceReport id={currentGrave.id} entity={'grave'}/>

          <Button variant={'contained'} onClick={toPlacePage} disabled={!currentGrave.idPlace}>
            {currentGrave.idPlace ? 'К ограде' : 'У данныой могилы нет ограды'}
          </Button>

          <PermissionsGate scopes={[SCOPES.canEdit]}>
            <ActionList
              items={[
                { cb: toEditGravePage, text: 'Редактировать' },
                { cb: onOpenPlaceOfGraveInDecode, text: 'Открыть ограду могилы в расшифровщике' },
                { cb: () => onDelete(), text: 'Удалить могилу' },
              ]}
            />
          </PermissionsGate>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} className={styles.modal_wrapper}>
        <Stack className={styles.grave_info_wrapper} spacing={2}>
          <Table size={'small'}>
            <TableBody>

              {graveDto.map(el => (
                <PermissionsGate key={el.label} scopes={el.permissions}>
                  <StyledTableRow key={el.label}>
                    <StyledTableCell>
                      {el.label}
                    </StyledTableCell>

                    <StyledTableCell>
                      {showInfoField(currentGrave[el.fieldName], {
                        dateWithoutTime: true,
                        withParseInt: keyWithFixed.includes(el.fieldName) && el.fieldName !== 'id'
                      })}
                    </StyledTableCell>
                  </StyledTableRow>
                </PermissionsGate>
              ))}

              <StyledTableRow>
                <StyledTableCell>
                  Возраст могилы
                </StyledTableCell>

                <StyledTableCell>
                  {currentGrave.dod ? dayjs().diff(dayjs(currentGrave.dod), 'years') + ' лет' : NO_INFO}
                </StyledTableCell>
              </StyledTableRow>

            </TableBody>
          </Table>
        </Stack>

        <Box className={styles.grave_img_wrapper}>
          {currentGrave?.photos?.length
            ? currentGrave.photos.map((photo: any) => (
              <div key={photo}>
                <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
                  <Icon
                    icon={'delete'}
                    color={'#ff0000'}
                    className={styles.remove_photo}
                    onClick={() => onRemovePhoto(currentGrave.id, photo)}
                  />
                </PermissionsGate>

                <PhotoWithZoom imageUrl={imageUrl + photo.toLowerCase()}/>

              </div>
            ))
            : <NoPhotoText/>
          }
        </Box>
      </Stack>

      <Dialog
        open={actionsHistory.open}
        onClose={() => setActionsHistory({ open: false, data: [] })}
        maxWidth={"xl"}
      >
        <DialogTitle>
          История изменений могилы с id: {currentGrave.id}
        </DialogTitle>

        <DialogContent>
          {mutation.isLoading ?
              <Loader/>
              :
              <>
                {
                  actionsHistory.data.length !== 0 ?
                    <ShowUserActionsHistory actionsHistoryProp={actionsHistory.data}/>
                    :
                    <p>Могила не изменялась</p>
                }
              </>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(Grave);