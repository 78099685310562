import React, { FC } from 'react';
import EditOneGraveForm from "./EditOneGraveForm";

interface Props {
  firstGraveId: number,
  secondGraveId: number,
  cancel: () => void
}

const EditTwoGraves: FC<Props> = ({ secondGraveId, firstGraveId, cancel }) => {

  return (
    <>
      <EditOneGraveForm cancel={cancel} id={firstGraveId}/>
      <EditOneGraveForm cancel={cancel} id={secondGraveId}/>
    </>
  )
};

export default EditTwoGraves;