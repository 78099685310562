import { atom } from "recoil";
import { IStubs, suspiciousType } from "../models/IStubs";

export const stubsState = atom<IStubs>({
  key: 'stubs-statistic',
  default:{
    graves: {
      suspiciousDates: [] as suspiciousType[],
      stubsCount: 0
    },
    places: {
      suspiciousDates: [] as suspiciousType[],
      stubsCount: 0
    },
  }
})