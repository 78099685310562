import React, { FC, useEffect } from 'react';
import useMapGrave from "../../pages/project/graves/editForm/useMapGrave";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import styles from "./editTwoGraves.module.scss";
import { Controller } from "react-hook-form";
import CustomSwitch from "../UI/Inputs/Switch/CustomSwitch";
import CustomInputDate from "../UI/Inputs/CustomInputDate/CustomInputDate";
import { graveDto } from "../../pages/project/graves/useGraves";
import { showInfoField } from "../../helpers/showInfoField";
import Loader from "../UI/Loader/Loader";

interface Props {
  id: number,
  cancel: () => void
}

const EditOneGraveForm: FC<Props> = ({ id, cancel }) => {

  const {
    isLoading,
    grave,
    graveState,
    MenuProps,
    onSubmit,
    getStyles,
    form,
    handleChange,
    sources
  } = useMapGrave(id)

  useEffect(() => {
    if (grave) {
      const filteredGrave: any = {}

      for (const { fieldName } of graveDto) {
        if (typeof grave[fieldName] === 'boolean') {
          filteredGrave[fieldName] = grave[fieldName]
          continue
        }
        filteredGrave[fieldName] = showInfoField(grave[fieldName], { dateWithoutTime: true })
      }

      const coord = JSON.parse(grave.geometry)

      if (coord && coord.coordinates) {
        filteredGrave['geometry'] = `${coord.coordinates[0]},${coord.coordinates[1]}`
      }

      form.reset(filteredGrave)
    }
  }, [grave])

  if (isLoading || !graveState.monumentType || !grave) {
    return (
      <Loader/>
    )
  }

  return (
    <div>
      <div>
        <Stack className={styles.inputs__stack}>
          <form>

            <div className={styles.field}>
              <Typography>Не совмещать с РГИС</Typography>
              <div>
                Нет
                <Controller
                  name={'dontMerge'}
                  control={form.control}
                  render={({ field }) =>
                    <CustomSwitch {...field}/>
                  }
                />
                Да
              </div>
            </div>

            <div className={styles.field}>
              <Typography>ID могилы</Typography>

              <Controller
                name={'id'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    disabled
                    size={'small'}
                    {...field}
                    label={'ID могилы:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>№ могилы</Typography>

              <Controller
                name={'monumentNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'№ могилы:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>ID ограды</Typography>

              <Controller
                name={'idPlace'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'ID ограды:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>RGIS ID</Typography>

              <Controller
                name={'idMonumentRgis'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'RGIS ID:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Является подзахоронением</Typography>
              <div>
                Нет
                <Controller
                  name={'isSubgrave'}
                  control={form.control}
                  render={({ field }) =>
                    <CustomSwitch {...field}/>
                  }
                />
                Да
              </div>
            </div>

            <div className={styles.field}>
              <Typography>Способ погребения</Typography>

              <Controller
                name={'burialType'}
                control={form.control}
                render={({ field }) =>
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name || ''}
                    disablePortal
                    defaultValue={null}
                    sx={{ width: 300 }}
                    // @ts-ignore
                    options={graveState.burialType}
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.burialType = e.target.innerText
                      form.formState.dirtyFields.burialType = true
                    }}
                    renderInput={(params) =>
                      <TextField
                        label={'Способ погребения:'}
                        {...params}
                        {...field}
                      />}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Тип погребения</Typography>

              <Controller
                name={'casketType'}
                control={form.control}
                render={({ field }) =>
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name || ''}
                    disablePortal
                    defaultValue={null}
                    sx={{ width: 300 }}
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.casketType = e.target.innerText
                      form.formState.dirtyFields.casketType = true
                    }}
                    // @ts-ignore
                    options={graveState.casketType}
                    renderInput={(params) =>
                      <TextField
                        label={'Тип погребения:'}
                        {...params}
                        {...field}
                      />}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Координаты</Typography>

              <Controller
                name={'geometry'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Координаты:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Фамилия</Typography>

              <Controller
                name={'lastname'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Фамилия'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Имя</Typography>

              <Controller
                name={'firstname'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Имя'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Отчество</Typography>

              <Controller
                name={'patronymic'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Отчество'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата рождения</Typography>

              <Controller
                control={form.control}
                name={'dob'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата рождения'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата смерти</Typography>

              <Controller
                control={form.control}
                name={'dod'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата смерти'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Возраст</Typography>

              <Controller
                name={'age'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Возраст'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Пол</Typography>
              <Controller
                name={'gender'}
                control={form.control}
                render={({ field }) =>
                  <Select
                    // @ts-ignore
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.gender = e.target.innerText
                    }}
                    style={{ minWidth: '15rem', height: '3rem' }}
                    {...field}
                  >
                    <MenuItem value={'Мужской'}>Мужской</MenuItem>
                    <MenuItem value={'Женский'}>Женский</MenuItem>
                  </Select>
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>СНИЛС</Typography>

              <Controller
                name={'snils'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'СНИЛС'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Адрес последнего места регистрации умершего</Typography>

              <Controller
                name={'deceasedAddress'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Адрес последнего места регистрации умершего'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата составления записи о смерти</Typography>

              <Controller
                control={form.control}
                name={'deathRecordDate'}
                render={({ field, formState }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    {...field}
                    error={!!formState.errors?.dob}
                    label={'Дата составления записи о смерти'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер записи акта о смерти</Typography>

              <Controller
                name={'deathRecordNum'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер записи акта о смерти'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Место смерти</Typography>

              <Controller
                name={'deathPlace'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Место смерти'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Место государственной регистрации записи о смерти</Typography>

              <Controller
                name={'zagsName'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Место государственной регистрации записи о смерти'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата выдачи свидетельства о смерти</Typography>

              <Controller
                control={form.control}
                name={'zagsDocumentDate'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата выдачи свидетельства о смерти:'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер свидетельства о смерти</Typography>

              <Controller
                name={'zagsDocumentNum'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер свидетельства о смерти'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата кремации умершего</Typography>

              <Controller
                control={form.control}
                name={'docr'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата кремации умершего:'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата захоронения</Typography>

              <Controller
                control={form.control}
                name={'dof'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата захоронения:'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Землекоп</Typography>

              <Controller
                name={'groundsman'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Землекоп'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер надмогильного сооружения</Typography>

              <Controller
                name={'monumentConstructionNum'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер надмогильного сооружения'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Материал надмогильного сооружения (надгробия):</Typography>

              <Controller
                name={'monumentType'}
                control={form.control}
                render={({ field }) =>
                  <Autocomplete
                    getOptionLabel={(option: any) => option.name || ''}
                    disablePortal
                    defaultValue={null}
                    sx={{ width: 300 }}
                    // @ts-ignore
                    options={graveState.monumentType}
                    onChange={(e) => {
                      // @ts-ignore
                      form.control._formValues.monumentType = e.target.innerText
                      form.formState.dirtyFields.monumentType = true
                    }}
                    renderInput={(params) =>
                      <TextField
                        label={'Материал надмогильного сооружения (надгробия):'}
                        {...params}
                        {...field}
                      />}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Ширина надмогильного сооружения:</Typography>

              <Controller
                name={'monumentWidth'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Ширина надмогильного сооружения:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Высота надмогильного сооружения:</Typography>

              <Controller
                name={'monumentHeight'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Высота надмогильного сооружения:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Толщина надмогильного сооружения:</Typography>

              <Controller
                name={'monumentDepth'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Толщина надмогильного сооружения:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Документ производителя</Typography>

              <Controller
                name={'monumentProducerDoc'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Документ производителя:'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата установки:</Typography>

              <Controller
                control={form.control}
                name={'monumentInstallationDate'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата установки памятника:'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Выдано удостоверение №</Typography>

              <Controller
                name={'certificateNum'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Выдано удостоверение №'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата выдачи удостоверения</Typography>

              <Controller
                control={form.control}
                name={'certificateDate'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата выдачи удостоверения'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.field}>
              <Typography>Номер рег. знака</Typography>

              <Controller
                name={'markNumber'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Номер рег. знака'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Описание</Typography>

              <Controller
                name={'description'}
                control={form.control}
                render={({ field }) =>
                  <TextField
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    size={'small'}
                    {...field}
                    label={'Описание'}
                  />
                }
              />
            </div>

            <div className={styles.field}>
              <Typography>Дата фото</Typography>

              <Controller
                control={form.control}
                name={'doph'}
                render={({ field, formState }) =>
                  <TextField
                    {...field}
                    error={!!formState.errors?.dob}
                    helperText={formState.errors?.dob?.message}
                    label={'Дата фото'}
                    size={'small'}
                    InputProps={{
                      inputComponent: CustomInputDate as any
                    }}
                  />}
              />
            </div>

            <div className={styles.sources}>
              <Typography>Источник сведений</Typography>

              <Select
                className={styles.select}
                size={'small'}
                multiple
                value={sources || []}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                  />
                }
                renderValue={(selected: any) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: any, index: number) => (
                      <Chip key={index} label={value}/>
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {graveState.sources.map((source) => (
                  <MenuItem
                    key={source.name}
                    value={source.name}
                    style={getStyles(source.name, sources)}
                  >
                    {source.name}
                  </MenuItem>
                ))}

              </Select>
            </div>

          </form>

          <div className={styles.buttons}>
            <Button
              onClick={cancel}
              className={styles.submit__button}
              variant={'contained'}
            >
              Отмена
            </Button>

            <Button
              onClick={form.handleSubmit(onSubmit)}
              className={styles.submit__button}
              variant={'contained'}
              color={'success'}
            >
              Подтвердить
            </Button>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default EditOneGraveForm;