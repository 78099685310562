import React from 'react';
import { Grid } from "@mui/material";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import FindNotExistingPhotos from "../../../../components/FindNotExistingPhotos/FindNotExistingPhotos";
import FindObjectsWithoutGeometry from "../../../../components/findObjectsWithoutGeometry/FindObjectsWithoutGeometry";
import StubsFinding from "../../../../components/stubsStatistic/StubsFinding";
import SearchDifferenceBetweenRgis from "../../../../components/searchDifference/SearchDifferenceBetweenRgis";
import SyncPhotos from "../../../../components/syncPhotos/SyncPhotos";
import FindGravesDuplicates from "../../../../components/findGravesDuplicates/FindGravesDuplicates";
import DeletePhotosDuplicates from "../../../../components/deletePhotosDuplicates/DeletePhotosDuplicates";
import SearchPlacesSections from "../../../../components/SearchPlacesSections/SearchPlacesSections";

const FindersMenu = () => {
  return (
    <Grid container spacing={4} columns={12}>
      <Grid sm={3} item>
        <StubsFinding/>
      </Grid>

      <Grid sm={3} item>
        <FindObjectsWithoutGeometry/>
      </Grid>

      <Grid sm={3} item>
        <DeletePhotosDuplicates/>
      </Grid>

      <Grid xs={3} item>
        <SearchPlacesSections/>
      </Grid>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Grid sm={3}  item>
          <SyncPhotos/>
        </Grid>

        <Grid xs={4} item>
          <SearchDifferenceBetweenRgis/>
        </Grid>

        <Grid xs={4} item>
          <FindGravesDuplicates/>
        </Grid>
      </PermissionsGate>

      <Grid xs={4} item>
        <FindNotExistingPhotos/>
      </Grid>
    </Grid>
  );
};

export default FindersMenu;