import React from 'react';
import { Card, CardActions, CardContent, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styles from '../CalculatePlacesTypes/calculatePlacesTypes.module.scss';
import { useCustomMutation } from "../../api/useCustomMutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MOVE_FOLDER_ON_CROC_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";

interface Form {
  newFolder: string,
  oldFolder: string
}

const MovePhotosToNewFolder = () => {
  const mutation = useCustomMutation()
  const { register, handleSubmit } = useForm<Form>()

  const onSubmit = async (form: any) => {
    try {
      const { data } = await mutation.mutateAsync({
        method: 'patch',
        data: form,
        url: MOVE_FOLDER_ON_CROC_URL
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Получаем фотографии оград и могил из старой папки и копируем их в новую папку.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>

      <CustomCardHeader title={'Перенос фотографий в новую папку'} onInfoClick={onInfoClick}/>

      <CardContent>
        <Stack spacing={2}>
          <TextField
            {...register('oldFolder', { required: true })}
            size={'small'}
            type={'text'}
            label={'Старая папка'}
          />

          <TextField
            {...register('newFolder', { required: true })}
            size={'small'}
            type={'text'}
            label={'Новая папка'}
          />
        </Stack>
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Начать перенос
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default MovePhotosToNewFolder;