import React, { FC, useState } from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, IconButton,
  InputLabel,
  MenuItem,
  Select, Stack, styled, Tooltip, tooltipClasses, TooltipProps
} from '@mui/material';
import { toast } from 'react-toastify';
import styles from '../../users.module.scss';
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getRoles } from "../../../../../api/user/getUsers";
import Loader from "../../../../../components/UI/Loader/Loader";
import Icon from "../../../../../components/UI/Icons/Icon";

interface Props {
  params: any
  onEditRole: any
}

const UpdateRoleDialog: FC<Props> = ({
                                       params,
                                       onEditRole,
                                     }) => {
  const [role, setRole] = useState('')
  const { data } = useQuery(['roles'], getRoles)
  const [isOpenChoseRole, setIsOpenChoseRole] = useState(false)
  const { handleSubmit } = useForm()

  const roles = data?.data.roles

  const onOpenChoseRole = () => setIsOpenChoseRole(true)
  const handleCloseChoseRole = () => setIsOpenChoseRole(false)

  const handleChange = (e: any) => {
    setRole(e.target.value as string)
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 10000,
      fontSize: theme.typography.pxToRem(18),
      border: '1px solid #dadde9',
    },
  }));

  const tooltipText = (
    <React.Fragment>
      Роли пользователей:<br/>
      user - может только смотреть, редактировать ничего не может<br/>
      superUser - может смотреть, редактировать могилы и ограды, видит админ панель и статистику<br/>
      editorUser - может смотреть, редактировать могилы и ограды<br/>

      Роли работников:<br/>
      superAdmin - может удалять кладбища, округа, проекты, схемы проектов, пользователей<br/>
      admin - может назначать пользователям роли, права на проекты, редактировать пользователей, интервалы расшифровки,
      просматривать статистику всех пользователей/проектов<br/>
      decoder - может смотреть и расшифровывать/редактировать кладбища<br/>
      worker - На маппере всё тоже самое как и у user, в приложении НЕ может удалять ограды/могилы<br/>
      experiencedWorker - На маппере всё тоже самое как и у user, в приложении может удалять ограды/могилы<br/>
    </React.Fragment>
  )

  const onSubmitRole = () => {
    if (!!role) {
      const dto = {
        userId: params.row.id,
        oldRoleId: params.row.roleId,
        newRoleId: role
      }
      onEditRole(dto)
      handleCloseChoseRole()
    } else {
      toast.error('Выберите роль')
    }
  }

  if (!roles) {
    return (
      <Dialog open={isOpenChoseRole} onClose={handleCloseChoseRole}>
        <Loader/>
      </Dialog>
    )
  } else {
    return (
      <>
        <IconButton onClick={onOpenChoseRole}>
          <Icon icon={'updateRole'} color={'#c800ff'}/>
        </IconButton>

        <Dialog open={isOpenChoseRole} onClose={handleCloseChoseRole}>
          <DialogTitle>
            <Stack direction={'row'} spacing={1}>
              <div>
                Назначение роли пользователю {params.row.firstname}
              </div>

              <HtmlTooltip title={tooltipText} placement="top-start" className={styles.role__tooltip}>
                <div>
                  <Icon icon={'info'}/>
                </div>
              </HtmlTooltip>
            </Stack>
          </DialogTitle>

          <DialogContent>
            <p>Текущая роль пользователя: {params.row.role}</p>
            Выберите роль пользователю {params.row.name}

            <div style={{ paddingTop: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Роль</InputLabel>

                <Select
                  value={role}
                  label="Role"
                  onChange={handleChange}
                >
                  {roles.map((role: any) => (
                    <MenuItem value={role.id} key={role.id + role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseChoseRole} autoFocus>
              Отмена
            </Button>

            <Button onClick={handleSubmit(onSubmitRole)}>
              Обновить
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default UpdateRoleDialog;