import { IKeyWithWidth } from '../../../models/IKeysWithWidth';
import { IGrave } from '../../../models/IGrave';
import { IGraveAndPlaceDto } from '../../../models/IGraveAndPlaceDto';
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";
import { IHeader } from "../../../models/IHeader";

export const graveDto: IGraveAndPlaceDto<IGrave>[] = [
  {
    fieldName: 'id',
    label: 'ID могилы:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'monumentNumber',
    label: '№ могилы:',
    disabled: true,
    type: 'text',
    nameInImportsFile: 'ext_grave_id',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'idPlace',
    label: 'ID ограды:',
    disabled: true,
    type: 'text',
    nameInImportsFile: 'place_id',
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'idMonumentRgis',
    label: 'RGIS ID:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'grave_id',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'bookNumber',
    label: '№ из книги захоронений:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'grave_booknumber',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'isSubgrave',
    label: 'Является подзахоронением:',
    disabled: false,
    type: 'bool',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'burialType',
    label: 'Способ погребения:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'burial_type',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'casketType',
    label: 'Тип погребения:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'casket_type',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'geometry',
    label: 'Координаты:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'geometry',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'lastname',
    label: 'Фамилия:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'lastname',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'firstname',
    label: 'Имя:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'firstname',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'patronymic',
    label: 'Отчество:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'patronymic',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dob',
    label: 'Дата рождения:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'dob',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dod',
    label: 'Дата смерти:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'dod',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'age',
    label: 'Возраст:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'age',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'gender',
    label: 'Пол:',
    disabled: false,
    type: 'bool',
    nameInImportsFile: 'gender',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'snils',
    label: 'СНИЛС:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'snils',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deceasedAddress',
    label: 'Адрес последнего места регистрации умершего:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'deceased_address',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathRecordDate',
    label: 'Дата составления записи о смерти:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'death_record_date',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathRecordNum',
    label: 'Номер записи акта о смерти:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'death_record_num',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'actNumber',
    label: 'Номер свидетельства о смерти:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'act_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathPlace',
    label: 'Место смерти:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'death_place',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathCertificateOrganization',
    label: 'Кем выдано свидетельство о смерти:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'death_certificate_organization',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathCertificateDate',
    label: 'Дата составления сертификата:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'death_certificate_date',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'zagsName',
    label: 'Место государственной регистрации записи о смерти:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'zags',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'zagsDocumentDate',
    label: 'Дата выдачи свидетельства о смерти:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'sas',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'docr',
    label: 'Дата кремации умершего:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'docr',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dontMerge',
    label: 'Не совмещать:',
    disabled: false,
    type: 'bool',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'dof',
    label: 'Дата захоронения:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'dof',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'groundsman',
    label: 'Землекоп:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'groundsman',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentVisitAddress',
    label: 'Адрес места пребывания:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_visit_address',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentLiveAddress',
    label: 'Адрес места жительства:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_live_address',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentConstructionNum',
    label: 'Номер надмогильного сооружения:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_construction_num',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentType',
    label: 'Материал надмогильного сооружения (надгробия):',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'monument_material',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentWidth',
    label: 'Ширина:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_width',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentHeight',
    label: 'Высота:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_height',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentDepth',
    label: 'Глубина:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_depth',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentProducerDoc',
    label: 'Документ производителя:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'monument_producer_doc',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentInstallationDate',
    label: 'Дата установки памятника:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'monument_installation_date',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'certificateNum',
    label: 'Выдано удостоверение №',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'certificate_num',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'certificateDate',
    label: 'Дата выдачи удостоверения',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'certificate_date',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'markNumber',
    label: 'Номер рег. знака',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'mark_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'doph',
    label: 'Дата фото:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'doph',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'description',
    label: 'Описание',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'grave_description',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sources',
    label: 'Источники',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'source',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentSize',
    label: 'Размеры:',
    disabled: false,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: []
  },
]

export const gravesTablesHeaders: IHeader[] = [
  {
    fieldName: 'id',
    label: 'ID могилы:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'monumentNumber',
    label: '№ могилы:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'idPlace',
    label: 'ID ограды:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'idMonumentRgis',
    label: 'RGIS ID:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'isSubgrave',
    label: 'Является подзахоронением:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'burialType',
    label: 'Способ погребения:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'casketType',
    label: 'Тип погребения:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'geometry',
    label: 'Координаты:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'lastname',
    label: 'Фамилия:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'firstname',
    label: 'Имя:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'patronymic',
    label: 'Отчество:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dob',
    label: 'Дата рождения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dod',
    label: 'Дата смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'age',
    label: 'Возраст:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'gender',
    label: 'Пол:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'snils',
    label: 'СНИЛС:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deceasedAddress',
    label: 'Адрес последнего места регистрации умершего:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathRecordDate',
    label: 'Дата составления записи о смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathRecordNum',
    label: 'Номер записи акта о смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'actNumber',
    label: 'Номер свидетельства о смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathPlace',
    label: 'Место смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathCertificateOrganization',
    label: 'Кем выдано свидетельство о смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'deathCertificateDate',
    label: 'Дата составления сертификата:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'zagsName',
    label: 'Место государственной регистрации записи о смерти:',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'zagsDocumentDate',
    label: 'Дата выдачи свидетельства о смерти:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'docr',
    label: 'Дата кремации умершего:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dontMerge',
    label: 'Не совмещать:',
    searchable: false,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'dof',
    label: 'Дата захоронения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'groundsman',
    label: 'Землекоп:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentVisitAddress',
    label: 'Адрес места пребывания:',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'monumentLiveAddress',
    label: 'Адрес места жительства:',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'monumentConstructionNum',
    label: 'Номер надмогильного сооружения:',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'monumentType',
    label: 'Материал надмогильного сооружения (надгробия):',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentWidth',
    label: 'Ширина:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentHeight',
    label: 'Высота:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentDepth',
    label: 'Глубина:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentProducerDoc',
    label: 'Документ производителя:',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'monumentInstallationDate',
    label: 'Дата установки памятника:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'certificateNum',
    label: 'Выдано удостоверение №',
    searchable: true,
    width: "medium",
    permissions: []
  },
  {
    fieldName: 'certificateDate',
    label: 'Дата выдачи удостоверения',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'markNumber',
    label: 'Номер рег. знака',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'doph',
    label: 'Дата фото:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'description',
    label: 'Описание',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sources',
    label: 'Источники',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'bookNumber',
    label: '№ из книги захоронений:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'monumentSize',
    label: 'Размеры:',
    searchable: false,
    width: "small",
    permissions: []
  },
]

export const gravesKeysWithWidth: IKeyWithWidth[] = [
    {
      fieldName: 'id',
      searchable: true,
      width: 'small',
      label: 'ID могилы',
      permissions: [SCOPES.admin, SCOPES.superAdmin]
    },
    {
      fieldName: 'monumentNumber',
      searchable: true,
      label: '№ могилы:',
      width: 'small'
    },
    {
      fieldName: 'idPlace',
      searchable: true,
      label: 'ID ограды:',
      width: 'small',
      permissions: [SCOPES.admin, SCOPES.superAdmin]
    },
    {
      fieldName: 'placeNumber',
      searchable: true,
      label: 'Номер могилы:',
      width: 'small'
    },
    {
      fieldName: 'countPhotos',
      searchable: true,
      label: 'Кол-во фото:',
      width: 'small'
    },
    {
      fieldName: 'lastname',
      searchable: true,
      label: 'Фамилия:',
      width: 'medium'
    },
    {
      fieldName: 'firstname',
      searchable: true,
      label: 'Имя:',
      width: 'medium'
    },
    {
      fieldName: 'patronymic',
      searchable: true,
      label: 'Отчество:',
      width: 'medium'
    },
    {
      fieldName: 'dob',
      searchable: true,
      label: 'Дата рождения:',
      width: 'small'
    },
    {
      fieldName: 'dod',
      searchable: true,
      label: 'Дата смерти:',
      width: 'small'
    },
    {
      fieldName: 'idMonumentRgis',
      searchable: true,
      label: 'RGIS ID:',
      width: 'medium'
    },
  ]