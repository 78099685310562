import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import Icon from "../../../../../components/UI/Icons/Icon";
import PermissionsGate from "../../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../../helpers/permissions/permissionsMaps";

interface Props {
  onEdit: (params: any, dto: any) => void
  params: any
}

const EditUserDialog: FC<Props> = ({ onEdit, params }) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
  const { handleSubmit, register, reset, formState: { dirtyFields, errors }, getValues } = useForm({
    defaultValues: {
      email: params.row.email,
      lastname: params.row.lastname,
      firstname: params.row.firstname,
      patronymic: params.row.patronymic,
      newPassword: '',
      confirmPassword: ''
    }
  })

  const handleCloseEdit = () => setIsOpenEditDialog(false)
  const onOpenEdit = () => setIsOpenEditDialog(true)

  const onEditUser = (dto: any) => {
    onEdit(params.value, dto)
    reset()
    handleCloseEdit()
  }

  return (
    <>
      <IconButton onClick={onOpenEdit}>
        <Icon icon={'edit'} color={'#ff5b00'}/>
      </IconButton>

      <Dialog open={isOpenEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>
          Редактирование пользователя
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('email')} label={'E-mail'}/>
            <TextField {...register('lastname')} label={'Фамилия'}/>
            <TextField {...register('firstname')} label={'Имя'}/>
            <TextField {...register('patronymic')} label={'Отчество'}/>

            <PermissionsGate scopes={[SCOPES.superAdmin]}>
              <TextField
                {...register('newPassword', {
                  minLength: {
                    value: 6,
                    message: 'Пароль должен содержать не менее 6 символов'
                  }
                })}
                label={'Пароль'}
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
              />
              <TextField
                {...register('confirmPassword', {
                  validate: (value) => {
                    const currentValue = getValues('newPassword')
                    if (value !== currentValue) {
                      return 'Пароли не совпадают'
                    }
                    return true;
                  }
                })}
                label={'Подтвердите пароль'}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            </PermissionsGate>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEdit} autoFocus>
            Отмена
          </Button>
          <Button onClick={handleSubmit(onEditUser)}>
            Обновить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUserDialog;