import React, { useState } from 'react';
import { Button, Card, CardContent, Dialog, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "../stubsStatistic/stubStatistic.module.scss";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import ShowSearchDifferenceResults from "./searchDifferenceComponents/ShowSearchDifferenceResults";
import { DifferenceRgisAtom } from "../../store/differenceRgisStore";
import { IProject } from "../../models/IProject";
import { useCustomMutation } from "../../api/useCustomMutation";
import { GET_DIFFERENCE_BETWEEN_RGIS_AND_OUR_DATA_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const SearchDifferenceBetweenRgis = () => {

  const [difference, setDifference] = useRecoilState(DifferenceRgisAtom);
  const [project, setProject] = useState<IProject | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const { register, handleSubmit } = useForm()
  const mutation = useCustomMutation()

  const inputLabelProps = { shrink: true }

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onProjectClick = (project: any) => {
    setProject(project)
  }
  const submit = async ({ File }: any) => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      const formData = new FormData()

      formData.set('file', File[0])
      formData.set('type', 'new_2024')

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{algorithmName: 'SearchDifferenceBetweenRgis',}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })


      const { data } = await mutation.mutateAsync({
        method: "post",
        data: formData,
        url: GET_DIFFERENCE_BETWEEN_RGIS_AND_OUR_DATA_URL + `?schema=${project.schema}`
      })

      setDifference(data.changedData);

      toast.success(data.message);

    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Сравниваем наши данные и РГИС. Если находим отличия наших данных от РГИС, то выводим их.
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Card elevation={6}>

        <CustomCardHeader title={algorithmsTitles.SearchDifferenceBetweenRgis} onInfoClick={onInfoClick}/>

        <CardContent className={styles.duplicates_form}>

          <div className={styles.duplicates_form__select_project}>
            <SelectProject onProjectClick={onProjectClick}/>

            {project && <Typography variant={'h6'}>{project.name}</Typography>}
          </div>

          <TextField
            {...register('File', { required: true })}
            size={'small'}
            type={'file'}
            label={'РГИС'}
            InputLabelProps={inputLabelProps}
          />

          <Stack direction={'row'} spacing={2} marginTop={1}>
            <Button onClick={onOpen} disabled={!difference.length}>
              Посмотреть результаты
            </Button>

            <LoadingButton
              loading={mutation.isLoading}
              onClick={handleSubmit(submit)}
            >
              Начать поиск
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={open} fullScreen onClose={onClose}>
        <ShowSearchDifferenceResults onClose={onClose}/>
      </Dialog>
    </div>
  );
};

export default SearchDifferenceBetweenRgis;