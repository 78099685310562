import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import styles from './stubStatistic.module.scss';
import { useNavigate } from "react-router-dom";
import { suspiciousType } from "../../models/IStubs";
import Icon from "../UI/Icons/Icon";
import { EDIT_GRAVE_PAGE, EDIT_PLACE_PAGE } from "../../router/routeConsts";

interface Props {
  stubs: suspiciousType[],
  label: string
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'doubleArrow'}/> :
          <Icon icon={'doubleArrow'} className={styles.transform__icon}/>}
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'arrowBack'}/> : <Icon icon={'arrowBack'}/>}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'arrowBack'}/> :
          <Icon icon={'arrowBack'} className={styles.transform__icon}/>}
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'doubleArrow'}/> : <Icon icon={'doubleArrow'}/>}
      </IconButton>
    </Box>
  );
}

const StubsTable: FC<Props> = ({ stubs, label }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const navigate = useNavigate()

  const onIdClick = (id: number) => {
    navigate(label === 'Ограды' ? (EDIT_PLACE_PAGE + id + `?common`) : EDIT_GRAVE_PAGE + id)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stubs.length) : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0);
  };

  if (!stubs.length) {
    return (
      <div style={{ width: '49rem' }}>
        <h2>{label}</h2>
        Заглушек не обнаружено
      </div>
    )
  }


  return (
    <div>
      <h2>{label}</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
                ? stubs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : stubs
            ).map((row) => (
              <TableRow key={row.id + row.field}>
                <TableCell component="th" scope="row">
                  <Button
                    onClick={() => onIdClick(row.id)}
                  >
                    {row.id}
                  </Button>
                </TableCell>

                <TableCell>
                  {row.field}
                </TableCell>

                <TableCell>
                  {row.value}
                </TableCell>

                <TableCell>
                  {row.message}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6}/>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 75, 100, { label: 'All', value: -1 }]}
                colSpan={3}
                count={stubs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StubsTable;