import { ls_getProjectInfo } from "../../helpers/localStorage";
import { GET_BURIAL_TYPES_URL, GET_CASKET_TYPES_URL, GET_GRAVE_TYPES_URL, GET_SOURCES_URL } from "../urls";
import { axiosInstance } from "../axios";

export const getMonumentTypes = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_GRAVE_TYPES_URL + `?schema=${schema}`)
}

export const getCasketTypes = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_CASKET_TYPES_URL + `?schema=${schema}`)
}

export const getBurialTypes = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_BURIAL_TYPES_URL + `?schema=${schema}`)
}

export const getSources = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_SOURCES_URL + `?schema=${schema}`)
}