import React, { ChangeEventHandler } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { importProjectSelector, importProjectState } from '../../../../../store/projectManageStore';
import { useCustomMutation } from '../../../../../api/useCustomMutation';
import { toast } from 'react-toastify';
import { IMPORT_FROM_KZ_URL } from '../../../../../api/urls';
import { Button, Card, CardActions, CardContent, CardHeader, Chip, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styles from './importShemas.module.scss';

const ImportSchemas = () => {
  const [_, setImportProject] = useRecoilState(importProjectState)
  const { schemas, schema, skippedSchemas } = useRecoilValue(importProjectSelector)
  const mutation = useCustomMutation()

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setImportProject(prev => ({
      ...prev,
      schema: e.target.value,
    }))
  }

  const onAddSchema = () => {
    if (!schemas.includes(schema)) {
      setImportProject(prev => ({
        ...prev,
        schemas: [...prev.schemas, schema],
        schema: ''
      }))
      toast.success('Схема добавлена!')
      return
    }
    toast.error('Такая схема уже добавлена!')
  }

  const onRemoveSchema = (schema: string) => {
    setImportProject(prev => ({
      ...prev,
      schemas: schemas.filter(s => s !== schema)
    }))
  }

  const onClear = () => {
    setImportProject(prev => ({
      ...prev,
      schemas: [],
      schema: '',
      skippedSchemas: []
    }))
  }

  const onSubmit = async () => {
    try {
      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: IMPORT_FROM_KZ_URL,
        data: { schemas: schemas }
      })

      toast.success(data.message)
      onClear()

      setImportProject(prev => ({
        ...prev,
        skippedSchemas: data.skipped
      }))
    } catch (e: any) {
      toast.error(
        <div>
        <p>{e.response?.data?.error}</p>
        <p>{e.response?.data?.message}</p>
      </div>
      )
    }
  }

  return (
    <Card elevation={7} className={styles.card}>
      <CardHeader title={'Импорт из КЗ'}/>

      <CardContent>
        {schemas.length > 0 &&
            <Stack direction={'row'} spacing={1} pb={2} flexWrap={'wrap'} maxWidth={'55rem'}>
              {schemas.map(schema =>
                <Chip
                  key={schema}
                  style={{ margin: '5px' }}
                  color={'primary'}
                  label={schema}
                  onDelete={() => onRemoveSchema(schema)}
                />
              )}
            </Stack>}

        <TextField
          label={'Название схемы'}
          onChange={handleChange}
          value={schema}
          fullWidth
          size={'small'}
        />

        {skippedSchemas.length > 0 &&
            <Stack direction={'row'} spacing={1} paddingTop={1} flexWrap={'wrap'}>
                <span>Пропущены - </span>
              {skippedSchemas.map(schema => <span key={schema}>{schema}</span>)}
            </Stack>
        }
      </CardContent>

      <CardActions>
        <Button
          onClick={onAddSchema}
          variant={'contained'}
          disabled={!schema}
        >
          Добавить ещё
        </Button>

        <LoadingButton
          loading={mutation.isLoading}
          onClick={onSubmit}
          variant={'contained'}
          disabled={!schemas.length}
        >
          Импортировать
        </LoadingButton>

        <LoadingButton
          onClick={onClear}
          variant={'contained'}
          disabled={!schemas.length && !skippedSchemas.length}
        >
          Очистить
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default ImportSchemas;