import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import useShowRows from "./useShowRows";
import ShowDifference from "./ShowDifference";
import { IDifferenceRgis } from "../../../../models/IDifferenceRgis";
import { ls_getProjectInfo } from "../../../../helpers/localStorage";
import Icon from "../../../UI/Icons/Icon";
import CurrentPhoto from "../../../../pages/project/decode/content/currentPhoto/CurrentPhoto";

interface Props {
  body: IDifferenceRgis
  expanded: boolean
  onChange: () => void
}

const SearchDifferenceAccordion: FC<Props> = ({ body, onChange, expanded }) => {
    const project = ls_getProjectInfo()

    const rows = useShowRows(body)

    return (
      <Accordion expanded={expanded} onChange={onChange}>

        <AccordionSummary expandIcon={<Icon icon={"expandMore"}/>}>
          <ShowDifference body={body}/>
        </AccordionSummary>

        <AccordionDetails>

          <Stack direction={'row'} height={'70vh'} spacing={3}>
            <div>
              <CurrentPhoto projectInfo={project} currentGrave={{ photos: body.photos }}/>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Поле
                  </TableCell>

                  <TableCell>
                    РГИС данные
                  </TableCell>

                  <TableCell>
                    Наши данные
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </Stack>

        </AccordionDetails>

      </Accordion>
    );
  }
;

export default SearchDifferenceAccordion;