import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { DECODE_PAGE, GRAVE_PAGE, PLACE_PAGE } from '../../../../router/routeConsts';
import { useRecoilState } from 'recoil';
import { decodeState } from '../../../../store/projectStore';
import { ls_getProjectInfo, ls_setCurrentPageDecode } from '../../../../helpers/localStorage';
import ActionList from '../../../../components/UI/ActionList/ActionList';
import * as uuid from 'uuid'
import { getRandomPointOnPolygon } from '../../../../helpers/getRandomPointOnPolygon';
import { Confirm, CustomDialog } from 'react-st-modal';
import { useCustomMutation } from '../../../../api/useCustomMutation';
import { CREATE_GRAVE_URL, DELETE_GRAVE_URL, WRITE_USER_HISTORY_URL } from '../../../../api/urls';
import { toast } from 'react-toastify';
import { deepCopy } from '../../../../helpers/deepCopy';
import CustomConfirmDeleteDialog from '../../../../components/UI/CustomConfirmDialog/CustomConfirmDeleteDialog';

const Actions = () => {
  const projectInfo = ls_getProjectInfo()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const [currentDecodePage, setCurrentDecodePage] = useRecoilState(decodeState)
  const { currentGrave, currentPlace, currentGraveIndex } = currentDecodePage

  const toGravePage = () => {
    navigate(GRAVE_PAGE + currentGrave.id)
  }

  const toPlacePage = () => {
    navigate(PLACE_PAGE + currentPlace.id + `?common`)
  }

  const toFirstPlace = () => {
    ls_setCurrentPageDecode(1)
    setCurrentDecodePage(prev => ({
      ...prev,
      currentPage: 1
    }))
    navigate(DECODE_PAGE + ':id')
  }

  const copyGrave = async () => {
    const result = await Confirm('Скопировать могилу?', 'Копирование')

    if (!result) {
      return
    }

    const graveFeature = getRandomPointOnPolygon(currentPlace.geometry)

    const newGrave = {
      schema: projectInfo.schema,
      uuid: uuid.v4(),
      photos: currentGrave.photos,
      geometry: JSON.stringify(graveFeature.geometry),
      idPlace: currentPlace.id,
      idMonumentType: currentGrave.idMonumentType,
      idCasketType: currentGrave.idCasketType || 1,
      idBurialType: currentGrave.idBurialType || 1,
      lastname: null,
      firstname: null,
      patronymic: null,
      dob: null,
      dod: null,
      isProcessed: false
    }

    const res = await mutation.mutateAsync({
      method: 'post',
      data: newGrave,
      url: CREATE_GRAVE_URL
    })

    toast.success(res.data.message)

    const newGraves = deepCopy(currentPlace.graves)

    if (currentGraveIndex === currentPlace.graves.length - 1) {
      newGraves.push({ ...newGrave, id: res.data.grave.id })
    } else {
      newGraves.splice(currentGraveIndex + 1, 0, { ...newGrave, id: res.data.grave.id })
    }

    setCurrentDecodePage(prev => ({
      ...prev,
      currentPlace: {
        ...prev.currentPlace,
        graves: newGraves
      },
      currentGraveIndex: prev.currentGraveIndex + 1,
    }))
  }

  const removeGrave = async () => {

    const result = await CustomDialog(
      <CustomConfirmDeleteDialog
        stringForConfirm={currentGrave.uuid}
        okButtonText={'Удалить'}
        cancelButtonText={'Отмена'}
        title={'Вы уверены что хотите удалить могилу?'}
        body={
          <div>
            <p>Введите <b>{currentGrave.uuid}</b> для потдверждения</p>
          </div>
        }
      />
    )

    if (!result) {
      return
    }

    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_GRAVE_URL + currentGrave.id + `?schema=${projectInfo.schema}`
    })
      .then(({ data }) => {
        const changesDescription = {
          objectId: currentGrave.id,
          action: 'delete'
        }

        mutation.mutateAsync({
          method: "post",
          data: {
            schema: projectInfo.schema,
            changesDescription,
            actionObject: 'grave'
          },
          url: WRITE_USER_HISTORY_URL
        })

        toast.success(data.message)

        const newGraves = deepCopy(currentPlace.graves)
          .filter((grave: any) => grave.uuid !== currentGrave.uuid)

        setCurrentDecodePage(prev => ({
          ...prev,
          currentPlace: {
            ...prev.currentPlace,
            graves: newGraves
          },
          currentGraveIndex: 0,
        }))
      })
      .catch(({ response }) => {
        if (response.status === 403) {
          toast.error('Вы не можете удалить могилу, недостаточно прав, обратитесь к администратору')
        } else {
          toast.error(response?.data.message)
          toast.error(response?.data.error)
        }
      })
  }


  return (
    <Box>
      <ActionList
        items={[
          { cb: toFirstPlace, text: 'Перейти к первой ограде' },
          { cb: toGravePage, text: 'Перейти на страницу могилы' },
          { cb: toPlacePage, text: 'Перейти на страницу ограды' },
          { cb: copyGrave, text: 'Копировать выбранную могилу' },
          { cb: removeGrave, text: 'Удалить выбранную могилу' },
        ]}
      />
    </Box>
  )
}

export default Actions