import React, { FC } from 'react';
import Icon from "../../../components/UI/Icons/Icon";
import { Button } from "@mui/material";
import { useCustomMutation } from "../../../api/useCustomMutation";
import { DELETE_USER_REPORT_URL } from "../../../api/urls";
import { toast } from "react-toastify";
import { Confirm } from "react-st-modal";
import { confirmTextHelper } from "../../../helpers/confirmTextHelper";

interface Props {
  id: number,
  refetch: () => void
}

const UserTemplatesActions: FC<Props> = ({ id, refetch }) => {
  const mutation = useCustomMutation()

  const onDelete = async () => {
    try {
      const { title, confirm, body, cancel } = confirmTextHelper('шаблона', 'шаблон')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) {
        return
      }

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        data: { id },
        url: DELETE_USER_REPORT_URL
      })

      refetch()

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Button variant={'text'} onClick={onDelete}>
      <Icon icon={"delete"}/>
    </Button>
  );
};

export default UserTemplatesActions;