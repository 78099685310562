import { IKeyWithWidth } from '../../../models/IKeysWithWidth';
import { IPlace } from '../../../models/IPlace';
import { IGraveAndPlaceDto } from "../../../models/IGraveAndPlaceDto";
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";
import { IHeader } from "../../../models/IHeader";

export const placeDto: IGraveAndPlaceDto<IPlace>[] = [
  {
    fieldName: 'id',
    label: 'ID ограды:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'uuid',
    label: 'Uuid ограды:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'idPlaceRgis',
    label: 'Ргис ID:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_id',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeNumber',
    label: '№ места захоронения:',
    disabled: true,
    type: 'text',
    nameInImportsFile: 'ext_place_id',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'bookNumber',
    label: 'Номер захоронения по книге регистрации захоронений:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_booknumber',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'ashesUrnBookNumber',
    label: 'Номер захоронения по книге регистрации захоронений урн с прахом:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'ashes_urn_book_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'rowNumber',
    label: '№ ряда:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'row_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'wallNumber',
    label: 'Номер стены скорби (колумбария):',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'wall_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'nicheNumber',
    label: 'Номер нишы:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'niche_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeType',
    label: 'Тип:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'place_type',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeUslugiUrl',
    label: 'Гос услуга:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_uslugi_url',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'measurements',
    label: 'Размеры:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'measurements',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'length',
    label: 'Длина(м):',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_length',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'width',
    label: 'Ширина(м):',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_width',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'area',
    label: 'Площадь(м²):',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'place_area',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'hasFence',
    label: 'Наличие ограждения:',
    disabled: false,
    type: 'bool',
    nameInImportsFile: 'has_fence',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dontMerge',
    label: 'Не совмещать:',
    disabled: false,
    type: 'bool',
    nameInImportsFile: 'dont_merge',
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'fenceHeight',
    label: 'Высота ограждения:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'fence_height',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeStatus',
    label: 'Статус:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'status',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleLastname',
    label: 'Фамилия ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_last_name',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleFirstname',
    label: 'Имя ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_first_name',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePatronymic',
    label: 'Отчество ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_patronymic',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportSeries',
    label: 'Серия паспорта ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_passport_series',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportNumber',
    label: 'Номер паспорта ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_passport_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportIssued',
    label: 'Кем выдан паспорт ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_passport_issued',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleBirthplace',
    label: 'Место рождения ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_birthplace',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleDob',
    label: 'Дата рождения ответственного:',
    disabled: false,
    type: 'date',
    nameInImportsFile: 'responsible_dob',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePhone',
    label: 'Телефон ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_phone',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleAddress',
    label: 'Адрес ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_address',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleContact',
    label: 'Контакты ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_contact',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleInn',
    label: 'ИНН ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_inn',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleSnils',
    label: 'Снилс ответственного:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'responsible_snils',
    searchable: true,
    width: "small",
    permissions: []
  },
]

export const placesTablesHeaders: IHeader[] = [
  {
    fieldName: 'id',
    label: 'ID ограды:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'uuid',
    label: 'Uuid ограды:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'idPlaceRgis',
    label: 'Ргис ID:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeNumber',
    label: '№ места захоронения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'bookNumber',
    label: 'Номер захоронения по книге регистрации захоронений:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'ashesUrnBookNumber',
    label: 'Номер захоронения по книге регистрации захоронений урн с прахом:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'rowNumber',
    label: '№ ряда:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'wallNumber',
    label: 'Номер стены скорби (колумбария):',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'nicheNumber',
    label: 'Номер нишы:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeType',
    label: 'Тип:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeUslugiUrl',
    label: 'Гос услуга:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'measurements',
    label: 'Размеры:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'length',
    label: 'Длина(м):',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'width',
    label: 'Ширина(м):',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'area',
    label: 'Площадь(м²):',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'hasFence',
    label: 'Наличие ограждения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'dontMerge',
    label: 'Не совмещать:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.superAdmin, SCOPES.admin]
  },
  {
    fieldName: 'fenceHeight',
    label: 'Высота ограждения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'placeStatus',
    label: 'Статус:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleLastname',
    label: 'Фамилия ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleFirstname',
    label: 'Имя ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePatronymic',
    label: 'Отчество ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportSeries',
    label: 'Серия паспорта ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportNumber',
    label: 'Номер паспорта ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePassportIssued',
    label: 'Кем выдан паспорт ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleBirthplace',
    label: 'Место рождения ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleDob',
    label: 'Дата рождения ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsiblePhone',
    label: 'Телефон ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleAddress',
    label: 'Адрес ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleContact',
    label: 'Контакты ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleInn',
    label: 'ИНН ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'responsibleSnils',
    label: 'Снилс ответственного:',
    searchable: true,
    width: "small",
    permissions: []
  },
]

export const placesKeysWithWidth: IKeyWithWidth[] = [
    {
      fieldName: 'id',
      searchable: true,
      label: 'ID ограды:',
      width: 'small',
      permissions: [SCOPES.admin, SCOPES.superAdmin]
    },
    {
      fieldName: 'placeNumber',
      searchable: true,
      label: '№ места захоронения:',
      width: 'small'
    },
    {
      fieldName: 'sectionNumber',
      searchable: true,
      label: '№ сектора:',
      width: 'small'
    },
    {
      fieldName: 'countPhotos',
      searchable: true,
      label: 'Кол-во фото:',
      width: 'small'
    },
    {
      fieldName: 'responsibleLastname',
      searchable: true,
      label: 'Фамилия ответственного:',
      width: 'medium'
    },
    {
      fieldName: 'responsibleFirstname',
      searchable: true,
      label: 'Имя ответственного:',
      width: 'medium'
    },
    {
      fieldName: 'responsiblePatronymic',
      searchable: true,
      label: 'Отчество ответственного:',
      width: 'medium'
    },
    {
      fieldName: 'idPlaceRgis',
      searchable: true,
      label: 'Ргис ID:',
      width: 'medium'
    },
  ]