import React, { FC, MutableRefObject, useEffect } from 'react';
import styles from './form.module.scss';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { IDecodeForm } from '../../../../../models/IDecodeForm';
import CustomInputDate from '../../../../../components/UI/Inputs/CustomInputDate/CustomInputDate';
import { GraveType } from '../../../../../models/ICurrentDecodePage';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeFormSchema } from '../../../../../validationSchemas/decodeFormSchema';
import { showDate } from '../../../../../helpers/showDate';

interface FormProps {
  onSubmit: any
  currentGrave: any
  graveTypes: GraveType[]
  currentPlace: any
  dirtyFieldsRef: MutableRefObject<any>
}

const defaultInputLabelProps = {
  shrink: true
}

const Form: FC<FormProps> = ({
                               onSubmit,
                               currentGrave,
                               graveTypes,
                               currentPlace,
                               dirtyFieldsRef
                             }) => {

  const { handleSubmit, formState, control, setValue, } = useForm({
    defaultValues: {
      lastname: '',
      firstname: '',
      patronymic: '',
      dod: '',
      dob: '',
      isProcessed: false,
      idMonumentType: 1
    } as IDecodeForm,
    mode: 'all',
    resolver: yupResolver(decodeFormSchema)
  })

  const _onSubmit = (e: any) => {
    e.preventDefault()
    handleSubmit(onSubmit)()
    dirtyFieldsRef.current = formState.dirtyFields
  }

  useEffect(() => {
    if (currentGrave) {
      setValue('firstname', currentGrave.firstname && currentGrave.firstname.length ? currentGrave.firstname : '');
      setValue('lastname', currentGrave.lastname && currentGrave.lastname.length ? currentGrave.lastname : '');
      setValue('patronymic', currentGrave.patronymic && currentGrave.patronymic.length ? currentGrave.patronymic : '');
      setValue('dod', showDate(currentGrave.dod));
      setValue('dob', showDate(currentGrave.dob));
      setValue('isProcessed', currentGrave.isProcessed);
      setValue('idMonumentType', currentGrave.idMonumentType || undefined);
    }
  }, [currentGrave])

  return (
    <Box className={styles.wrapper}>

      <Box className={styles.grave__info}>
        <Typography>Id могилы: {currentGrave?.id}</Typography>
        <Typography>Id ограды: {currentPlace?.id}</Typography>
        <Typography>Дата создания: {showDate(currentGrave?.dateCreated)}</Typography>
      </Box>

      {currentGrave &&
          <Box
              component={'form'}
              onSubmit={_onSubmit}
              className={styles.form}
              sx={{
                '& .MuiTextField-root': { mt: '1.5rem', width: '20rem' },
              }}
          >
              <Controller
                  control={control}
                  name={'lastname'}
                  render={({ field, formState }) =>
                    <TextField
                      {...field}
                      error={!!formState.errors?.lastname}
                      helperText={formState.errors?.lastname?.message}
                      label={'Фамилия'}
                      size={'small'}
                      InputLabelProps={defaultInputLabelProps}
                    />}
              />

              <Controller
                  control={control}
                  name={'firstname'}
                  render={({ field, formState }) =>
                    <TextField
                      {...field}
                      error={!!formState.errors?.firstname}
                      helperText={formState.errors?.firstname?.message}
                      label={'Имя'}
                      size={'small'}
                      InputLabelProps={defaultInputLabelProps}
                    />}
              />

              <Controller
                  control={control}
                  name={'patronymic'}
                  render={({ field, formState }) =>
                    <TextField
                      {...field}
                      error={!!formState.errors?.patronymic}
                      helperText={formState.errors?.patronymic?.message}
                      label={'Отчество'}
                      size={'small'}
                      InputLabelProps={defaultInputLabelProps}
                    />}
              />

              <FormHelperText>Если вы не можете разобрать что-то, оставьте эти поля пустыми</FormHelperText>

              <Controller
                  control={control}
                  name={'dob'}
                  render={({ field, formState }) =>
                    <TextField
                      {...field}
                      error={!!formState.errors?.dob}
                      helperText={formState.errors?.dob?.message}
                      label={'Дата рождения'}
                      size={'small'}
                      InputProps={{
                        inputComponent: CustomInputDate as any
                      }}
                    />}
              />

              <Controller
                  control={control}
                  name={'dod'}
                  render={({ field, formState }) => {
                    return (
                      <TextField
                        {...field}
                        label={'Дата смерти'}
                        size={'small'}
                        error={!!formState.errors?.dod}
                        helperText={formState.errors?.dod?.message}
                        InputProps={{
                          inputComponent: CustomInputDate as any
                        }}
                      />)
                  }}
              />

              <Controller
                  control={control}
                  name={'idMonumentType'}
                  render={({ field }) =>
                    <Select {...field} className={styles.select} size={'small'}>
                      {graveTypes.map(graveType =>
                        <MenuItem key={graveType.name} value={graveType.id}>
                          {graveType.name}
                        </MenuItem>
                      )}
                    </Select>}
              />

              <Controller
                  control={control}
                  name={'isProcessed'}
                  render={({ field }) =>
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value}/>}
                      label="Обработано"
                    />}
              />
              <Button type={'submit'} variant={'contained'}>Обновить и продолжить</Button>
          </Box>}
    </Box>
  );
};

export default Form;