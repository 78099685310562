import React from 'react';
import { TableCell, TableRow } from "@mui/material";
import { showInfoField } from "../../../../helpers/showInfoField";
import { graveFieldNames } from "../../../../utils/utils";

const UseShowRows = (body: any) => {

  const { newData, oldData } = body.data
  const rows = []

  for (const [key, value] of Object.entries(newData)) {
    if (key !== 'id')
      rows.push((
        <TableRow>
          <TableCell>
            {graveFieldNames[key]}
          </TableCell>

          <TableCell>
            {showInfoField(oldData[key], { dateWithoutTime: true })}
          </TableCell>

          <TableCell>
            {showInfoField(value as any, { dateWithoutTime: true })}
          </TableCell>
        </TableRow>
      ))
  }

  return rows
};

export default UseShowRows;