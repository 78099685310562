import React, {FC, MutableRefObject, useCallback, useRef, useState} from 'react';
import {Box, Typography} from '@mui/material';
import styles from './content.module.scss';
import CurrentPhoto from './currentPhoto/CurrentPhoto';
import Form from './form/Form';
import {IDecodeForm} from '../../../../models/IDecodeForm';
import Photos from './photos/Photos';
import {useCustomMutation} from '../../../../api/useCustomMutation';
import {ls_getProjectInfo, ls_setCurrentPageDecode} from '../../../../helpers/localStorage';
import {CREATE_HISTORY_URL, UPDATE_GRAVE_URL} from '../../../../api/urls';
import {toast} from 'react-toastify';
import {ICurrentDecodePage} from '../../../../models/ICurrentDecodePage';
import {SetterOrUpdater} from 'recoil';
import {IDecodeHistory} from '../../../../models/IDecodeHistory';
import {IDecodeStatistics} from '../../../../models/IDecodeStatistics';
import {validateDatesInObject} from '../../../../helpers/validateDatesInObject';
import {useNavigate} from 'react-router-dom';
import {DECODE_PAGE} from '../../../../router/routeConsts';
import dayjs from "dayjs";
import * as uuid from 'uuid';

interface Props {
  currentDecodePage: ICurrentDecodePage
  setCurrentDecodePage: SetterOrUpdater<ICurrentDecodePage>
  setDecodeStatistic: SetterOrUpdater<IDecodeStatistics>
  timerRef: MutableRefObject<number>
}

const Content: FC<Props> =
  ({
     currentDecodePage,
     setCurrentDecodePage,
     setDecodeStatistic,
     timerRef
   }) => {
    const navigate = useNavigate()
    const projectInfo = ls_getProjectInfo()
    const mutation = useCustomMutation()
    const {currentGrave, graveTypes, currentPlace, currentGraveIndex, placesIds} = currentDecodePage
    const dirtyFieldsRef = useRef<any>()
    const dateKeys = ['dob', 'dod']
    const [forcer, forceUpdateImages] = useState<string>('uiKiY')

    const memoizedForcer = useCallback(() => forceUpdateImages(uuid.v4()), [])

    const onSelectGraveByPhoto = (index: number) => {
      setCurrentDecodePage(prev => ({
        ...prev,
        currentGraveIndex: index
      }))
    }

    const saveStatistic = async (formData: IDecodeForm, time: number) => {
      try {
        const historyDto = {} as IDecodeHistory

        for (const [key] of Object.entries<any>(dirtyFieldsRef.current)) {
          if (!historyDto.updatedFields) {
            historyDto.updatedFields = {}
          }
          // @ts-ignore
          historyDto.updatedFields[key] = formData[key]
        }

        historyDto.schema = projectInfo.schema
        historyDto.secondsSpent = time
        historyDto.graveUuid = currentGrave.uuid
        historyDto.updatedFields = JSON.stringify(historyDto.updatedFields)

        const {data} = await mutation.mutateAsync({
          method: 'post',
          data: historyDto,
          url: CREATE_HISTORY_URL
        })
        toast.success(data.message)

        if (!data.updated) {
          setDecodeStatistic(prev => ({
            ...prev,
            decodeMe: prev.decodeMe + 1,
            avgTime: prev.avgTime + time
          }))
        }
      } catch (e: any) {
        toast.error(e.response?.data.message)
        toast.error(e.response?.data.error)
      }
    }

    const updateDecodeState = (formData: IDecodeForm) => {
      setCurrentDecodePage(prev => {

        const newState = {} as ICurrentDecodePage

        if (currentGraveIndex === currentPlace.graves.length - 1) {
          ls_setCurrentPageDecode(prev.currentPage + 1)
          navigate(DECODE_PAGE + placesIds[prev.currentPage].id)
          newState.currentPage = prev.currentPage + 1
          newState.currentGraveIndex = 0
        } else {
          newState.currentGraveIndex = prev.currentGraveIndex + 1
        }

        const newGraves = currentPlace.graves.map((grave: any) => {
          return grave.id === currentGrave.id
            ? {...grave, ...formData}
            : grave
        })

        return {
          ...prev,
          ...newState,
          currentPlace: {
            ...prev.currentPlace,
            graves: newGraves
          }
        }
      })
    }

    const findStubs = (value: string | null) => {

      const stubsString = 'неизвестно н/д нет инофрмации null'

      if (!value) {
        return false
      }
      if (value.length < 3) {
        return false
      }
      if (stubsString.includes(value.toLowerCase())) {
        toast.error(`Не ставьте заглушки в поля, если не можете разобрать что-то оставьте поле пустым (${value})`)
        return true
      }
    }

    const onSubmit = async (formData: IDecodeForm) => {
      try {
        validateDatesInObject(formData, dateKeys)

        if (dayjs('1800-01-01').diff(dayjs(formData.dob)) > 0) {
          toast.error(`Невозможная дата рождения: ${dayjs(formData.dob)}`)
          return
        }

        if (dayjs('1800-01-01').diff(dayjs(formData.dod)) > 0) {
          toast.error(`Невозможная дата смерти: ${dayjs(formData.dod)}`)
          return
        }

        if (findStubs(formData.lastname)) {
          return
        }
        if (findStubs(formData.firstname)) {
          return
        }
        if (findStubs(formData.patronymic)) {
          return
        }

        const {data} = await mutation.mutateAsync({
          method: 'patch',
          url: UPDATE_GRAVE_URL + currentGrave.id,
          data: {...formData, schema: projectInfo.schema}
        })

        toast.success(data.message)

        updateDecodeState(formData)

        if (Object.keys(dirtyFieldsRef.current).length) {
          await saveStatistic(formData, timerRef.current)
        }
      } catch (e: any) {
        toast.error(e.response?.data.message)
        toast.error(e.response?.data.error)
      }
    }

    return (
      <Box className={styles.wrapper}>
        <div className={styles.content}>

          {currentGrave
            ?
            <CurrentPhoto
              projectInfo={projectInfo}
              currentGrave={currentGrave}
              forcer={forcer}
              forceUpdateImages={memoizedForcer}
            />
            :
            <Box className={styles.empty__place}>
              <Typography variant={'h4'}>Пустая ограда</Typography>
            </Box>
          }

          <Form
            dirtyFieldsRef={dirtyFieldsRef}
            onSubmit={onSubmit}
            graveTypes={graveTypes}
            currentGrave={currentGrave}
            currentPlace={currentPlace}
          />

          <Photos
            projectInfo={projectInfo}
            currentPlace={currentPlace}
            currentGrave={currentGrave}
            onSelect={onSelectGraveByPhoto}
            imagesForcer={forcer}
            forceUpdateImages={memoizedForcer}
          />
        </div>
      </Box>
    )
  };

export default React.memo(Content);