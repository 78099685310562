import React, {useEffect} from 'react';
import cn from "classnames";
import styles from "../customButtons.module.scss";
import Icon from "../../../UI/Icons/Icon";
import {useRulerMarkers} from "./useRulerMarkers";

const RulerWindow = () => {
  const {calcMeasurements, measurements, onClick, mapState, onCopy} = useRulerMarkers()

  useEffect(() => {
    calcMeasurements()
  }, [mapState.ruler.pointsArray])

  if (!mapState.ruler.isOn) {
    return (
      <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={onClick}>
        <Icon icon={"ruler"}/>
      </div>
    )
  } else {
    return (
      <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_ruler_is_on_btn)}>
        <div className={styles.off_ruler_btn} onClick={onClick}>
          <Icon icon={"close"}/>
        </div>

        <div className={styles.ruler_info}>
          <p>Длинна: {measurements.width.toFixed(2)} м</p>
          <p>Площадь: {measurements.area.toFixed(2)} м²</p>
          <p>Координты последней точки:</p>

          <div className={styles.ruler__coordinates_wrapper}>
            {mapState.ruler.pointsArray.length
              ?
              <>
                {mapState.ruler.pointsArray[mapState.ruler.pointsArray.length - 2].lat.toFixed(6)}
                {`, `}
                {mapState.ruler.pointsArray[mapState.ruler.pointsArray.length - 2].lng.toFixed(6)}

                <Icon
                  onClick={
                    () => onCopy(
                      `${mapState.ruler.pointsArray[mapState.ruler.pointsArray.length - 2].lat.toFixed(6)}, ${mapState.ruler.pointsArray[mapState.ruler.pointsArray.length - 2].lng.toFixed(6)}`
                    )
                  }
                  className={styles.ruler__coordinates__copy_btn}
                  icon={"copy"}
                />
              </>
              :
              <>Выберите точку</>
            }
          </div>

          <div className={styles.markers_info__container}>
            <div className={styles.markers_info__wrapper}>
              <p>Первая точка -</p>
              <img
                src={'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png'}
              />
            </div>

            <div className={styles.markers_info__wrapper}>
              <p>Последняя точка -</p>
              <img
                src={'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default RulerWindow;