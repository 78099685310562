export const ROLES = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  superUser: 'superUser',
  editorUser: 'editorUser',
  user: 'user',
  decoder: 'decoder',
  worker: 'worker',
  experiencedWorker: 'experiencedWorker',
}

export const SCOPES = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  superUser: 'superUser',
  editorUser: 'editorUser',
  user: 'user',
  decoder: 'decoder',
  canCreate: 'can-create',
  canEdit: 'can-edit',
  canDelete: 'can-delete',
  canView: 'can-view',
  worker: 'worker',
  experiencedWorker: 'experiencedWorker',
}

export const PERMISSIONS = {
  [ROLES.superAdmin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.superAdmin,
    SCOPES.admin,
    SCOPES.decoder,
    SCOPES.user,
    SCOPES.superUser,
    SCOPES.worker,
    SCOPES.experiencedWorker,
  ],
  [ROLES.admin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canDelete,
    SCOPES.canCreate,
    SCOPES.admin,
    SCOPES.decoder,
    SCOPES.superUser,
    SCOPES.worker,
    SCOPES.experiencedWorker,
  ],
  [ROLES.decoder]: [
    SCOPES.canView,
    SCOPES.canCreate,
    SCOPES.canEdit,
    SCOPES.decoder
  ],
  [ROLES.user]: [
    SCOPES.canView,
    SCOPES.user,
  ],
  [ROLES.superUser]: [
    SCOPES.editorUser,
    SCOPES.superUser,
    SCOPES.canCreate,
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.user,
  ],
  [ROLES.editorUser]: [
    SCOPES.canCreate,
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.user,
    SCOPES.editorUser,
  ],
  [ROLES.worker]: [
    SCOPES.canView,
    SCOPES.user,
    SCOPES.worker
  ],
  [ROLES.experiencedWorker]: [
    SCOPES.canView,
    SCOPES.user,
    SCOPES.worker,
  ],
}