import { useEffect, useRef } from 'react';
import 'leaflet-draw';
import isEqual from 'fast-deep-equal';
import { useLeafletContext } from '@react-leaflet/core';
import L, { Control, ControlPosition, LeafletEvent } from 'leaflet';
import _local from './_localization.json';

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onDeleted: 'draw:deleted',
  onDeleteStart: 'draw:deletestart',
  onDeleteStop: 'draw:deletestop',
};

type LeafletCallback = (e: LeafletEvent) => void

interface EditControlProps {
  onMounted?: (e: Control.Draw) => void
  position: ControlPosition
  draw: Control.DrawOptions,
  edit?: Partial<Control.EditOptions>
  onCreated?: LeafletCallback
  onDeleted?: LeafletCallback
  onEdited?: LeafletCallback
  onDrawStart?: LeafletCallback
  onDrawStop?: LeafletCallback
  onDrawVertex?: LeafletCallback
  onEditStart?: LeafletCallback
  onEditStop?: LeafletCallback
  onEditVertex?: LeafletCallback
  onEditMove?: LeafletCallback
  onEditResize?: LeafletCallback
  onDeleteStart?: LeafletCallback
  onDeleteStop?: LeafletCallback
}

function EditControl(props: EditControlProps) {

  const context = useLeafletContext();
  const drawRef = useRef<Control.Draw>();
  const propsRef = useRef(props);
  const isAddedControl = useRef(false);

  // @ts-ignore
  L.drawLocal = _local['RU-ru'];

  function onDrawCreate(e: LeafletEvent) {
    const { onCreated } = props;
    const container = context.layerContainer || context.map;
    container.addLayer(e.layer);
    onCreated && onCreated(e);
  }

  useEffect(() => {
    const { map } = context;
    const { onMounted } = props;
    if (!isAddedControl.current) {
      for (const key in eventHandlers) {
        // @ts-ignore
        map.on(eventHandlers[key], (event) => {

          let handlers = Object.keys(eventHandlers).filter(
            // @ts-ignore
            (handler) => eventHandlers[handler] === event.type
          );

          if (handlers.length === 1) {
            let handler = handlers[0];
            // @ts-ignore
            props[handler] && props[handler](event);
          }
        });
      }
    }

    map.on(L.Draw.Event.CREATED, onDrawCreate);
    drawRef.current = createDrawElement(props, context);

    if (!isAddedControl.current) {
      map.addControl(drawRef.current);
      isAddedControl.current = true;
      onMounted && onMounted(drawRef.current);
    }

    return () => {
      map.off(L.Draw.Event.CREATED, onDrawCreate);

      for (const key in eventHandlers) {
        // @ts-ignore
        if (props[key]) {
          // @ts-ignore
          map.off(eventHandlers[key], props[key]);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (
      !isEqual(props.draw, propsRef.current.draw) &&
      props.position !== propsRef.current.position
    ) {
      const { map } = context;

      drawRef.current?.remove();
      drawRef.current = createDrawElement(props, context);
      drawRef.current.addTo(map);

      const { onMounted } = props;
      onMounted && onMounted(drawRef.current);
    }
  }, [props.draw, props.position]);

  return null;
}

function createDrawElement(props: any, context: any) {
  const { layerContainer } = context;
  const { draw, edit, position } = props;
  const options: any = {
    edit: {
      ...edit,
      featureGroup: layerContainer,
    },
  };

  if (draw) {
    options.draw = { ...draw };
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

export default EditControl;
