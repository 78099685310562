import { atom, selector } from 'recoil';

export const importProjectState = atom({
  key: 'import-project-state',
  default: {
    schemas: [] as string[],
    schema: '',
    skippedSchemas: []
  }
})

export const importProjectSelector = selector({
  key: 'import-project-selector',
  get: ({ get }) => {
    const state = get(importProjectState)
    return state
  }
})