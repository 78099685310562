import React, { useEffect } from 'react';
import cn from "classnames";
import styles from "../customButtons.module.scss";
import Icon from "../../../UI/Icons/Icon";
import useMovePlaceButtons from "./useMovePlaceButtons";
import { Marker } from "react-leaflet";

const MovePlaceButtons = () => {
  const {
    onRotate,
    onKeyDown,
    onInfo,
    onSave,
    mapState,
    onArrowClick,
    onSelect,
    points,
    eventHandlers,
    elRefs
  } = useMovePlaceButtons()

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    if (mapState.selectedObject && mapState.preSelectedObject?.type !== 'grave') {
      onSelect(mapState.selectedObject.geometry)
    }

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [mapState.selectedObject])

  if (!mapState.selectedObject || mapState.preSelectedObject?.type === 'grave') {
    return <></>
  }

  return (
    <>
      <div className={cn('leaflet-top', 'leaflet-left', styles.save_after_moving_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={onSave}>
          <Icon icon={"save"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.info_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn, styles.info_btn_animation)}
             onClick={onInfo}>
          <Icon icon={"info"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.rotate_left_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={() => {
          onRotate('left')
        }}>
          <Icon icon={"rotate"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.rotate_right_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={() => {
          onRotate('right')
        }}>
          <Icon icon={"rotate"} className={styles.mirroring}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.move_left_btn_container, styles.rotate_out_center)}>
        <div
          className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
          onClick={() => onArrowClick('left')}
          tabIndex={0}
        >
          <Icon icon={"arrowBack"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_top_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)} onClick={() => onArrowClick('top')}>
          <Icon icon={"arrowBack"} className={styles.arrow_top}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_right_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
             onClick={() => onArrowClick('right')}>
          <Icon icon={"arrowBack"} className={styles.arrow_right}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_bottom_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
             onClick={() => onArrowClick('bottom')}>
          <Icon icon={"arrowBack"} className={styles.arrow_bottom}/>
        </div>
      </div>

      {points?.map((point, index) => {
          {
            if (index !== points?.length - 1)
              return (
                <Marker
                  key={index}
                  ref={elRefs[index]}
                  draggable={true}
                  alt={index.toString()}
                  eventHandlers={eventHandlers}
                  position={{ lat: point[1], lng: point[0] }}
                />
              )
          }
        }
      )}
    </>
  );
};

export default MovePlaceButtons;