// packages
import React, { FC, forwardRef } from 'react'
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { TableVirtuoso } from 'react-virtuoso'
import dayjs from 'dayjs'
// types
import { IPhotoStatistic } from '../../../../../models/_admin/IPhotoStatistic'
import { IProject } from '../../../../../models/IProject'

interface Props {
  statistics: IPhotoStatistic[],
  project: IProject
}

const MainStatisticTable: FC<Props> = ({ statistics, project }) => {
  return (
    <TableVirtuoso
      data={statistics}
      style={{ height: '70vh', width: '50rem' }}
      totalCount={statistics.length}
      components={{
        Scroller: forwardRef((props, ref) =>
          <TableContainer component={Paper} {...props} ref={ref}/>),
        Table: (props) =>
          <Table {...props} style={{ borderCollapse: 'separate' }}/>,
        // @ts-ignore
        TableHead: TableHead,
        TableRow: TableRow,
        TableBody: forwardRef((props, ref) =>
          // @ts-ignore
          <TableBody {...props} ref={ref}/>),
      }}
      fixedHeaderContent={() => (
        <TableRow style={{ backgroundColor: '#fff' }}>
          <TableCell>№</TableCell>
          <TableCell>Тип</TableCell>
          <TableCell>Имя фото</TableCell>
          <TableCell>Дата создания</TableCell>
        </TableRow>
      )}
      itemContent={(index, s) => (
        <>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{s.type}</TableCell>

          <TableCell>
            <Link
              target={'_blank'}
              href={project?.hostingUrl + project?.imageFolder + '/' + s.uuid}>
              {s.uuid}
            </Link>
          </TableCell>

          <TableCell>{dayjs(s.dateCreated).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
        </>
      )}
    />
  )
}

export default MainStatisticTable