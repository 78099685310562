import { atom } from 'recoil';
import { IPhotoStatistic } from '../models/_admin/IPhotoStatistic';
import { IProject } from '../models/IProject';
import { IUser } from '../models/IUser';
import { IStatisticAtom } from '../models/_admin/IStatisticAtom';
import { IDecodeUserStatistic } from "../models/_admin/IDecodeUserStatistic";

export const statisticState = atom<IStatisticAtom>({
  key: 'statistic-state',
  default: {
    statistics: [] as IPhotoStatistic[],
    user: {} as IUser,
    project: {} as IProject,
    decodeUserStatistics: {} as IDecodeUserStatistic
  } as IStatisticAtom
})