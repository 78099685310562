import React, { useState } from 'react';
import { useCustomMutation } from "../../api/useCustomMutation";
import {
  DELETE_PLACES_WITHOUT_GEOMETRY_AND_PHOTOS_URL,
  SEARCH_PLACES_SECTIONS_URL,
  WRITE_USER_HISTORY_URL
} from "../../api/urls";
import styles from '../CalculatePlacesTypes/calculatePlacesTypes.module.scss';
import { toast } from "react-toastify";
import { Card, CardActions, CardContent, Stack } from "@mui/material";
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const SearchPlacesSections = () => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onSubmit = async () => {
    try {
      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'SearchPlacesSections' }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const currentSchema = project.schema

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: SEARCH_PLACES_SECTIONS_URL,
        data: {schema: currentSchema}
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Ищем сектора оград по их центру
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.SearchPlacesSections} onInfoClick={onInfoClick}/>

      <CardContent>
        <div>
          <h3>{project?.name}</h3>
        </div>
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default SearchPlacesSections;