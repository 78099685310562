import React, { useCallback, useEffect } from 'react';
import CustomCreatePlaceDialog from "../../../UI/CustomConfirmDialog/CustomCreatePlaceDialog";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import { CustomDialog } from "react-st-modal";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import { toast } from "react-toastify";
import {
  CREATE_BORDER_URL,
  CREATE_NOTE_OR_TASK_URL,
  CREATE_PLACE_URL,
  CREATE_PLANNED_PLACE_URL,
  CREATE_SECTION_URL
} from "../../../../api/urls";
import { useNavigate } from "react-router-dom";
import { PLACE_PAGE } from "../../../../router/routeConsts";
import { ls_getProjectInfo } from "../../../../helpers/localStorage";

const CustomCreatePlace = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const navigate = useNavigate()

  const confirm = useCallback(async () => {
    const req = await CustomDialog(
      <CustomCreatePlaceDialog
        title={'Какой объект вы хотите создать?'}
        body={''}
      />
    ) as any

    if (!req || !req.confirm) {
      return
    }

    const projectId = ls_getProjectInfo().id

    switch (req.type) {
      case 'common':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_PLACE_URL,
            data: mapState.createdObject
          })

          toast.success(data.message)
          setTimeout(() => navigate(PLACE_PAGE + data.place[0].id + '?common'), 1000)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break;

      case 'section':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_SECTION_URL,
            data: mapState.createdObject
          })
          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break

      case 'border':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_BORDER_URL,
            data: mapState.createdObject
          })
          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break

      case 'planned':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_PLANNED_PLACE_URL,
            data: mapState.createdObject
          })

          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break;

      case 'task':
      case 'note':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_NOTE_OR_TASK_URL,
            data: {
              description: req.description,
              geometry: mapState.createdObject.geometry,
              idProject: projectId,
              type: req.type
            }
          })

          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break;
      default:
        toast.error('ERROR TYPE OF OBJECT')
        return
    }

    setMapState(prev => ({ ...prev, createdObject: null, needReFetch: true, }))
  }, [mapState.createdObject])

  useEffect(() => {
    if (!mapState.createdObject) {
      return
    }

    confirm()
  }, [mapState.createdObject])

  return (
    <>
    </>
  );
};

export default CustomCreatePlace;