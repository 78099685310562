import { atom } from "recoil";
import { IWrongPlacesRgis } from "../models/IWrongPlacesRgis";
import { IPlace } from "../models/IPlace";

export const wrongPlacesRgisAtom = atom({
  key: 'wrong-places-rgis',
  default: [] as IWrongPlacesRgis[]
})

export const showWrongPlacesRgisAtom = atom({
  key: 'show-wrong-places-on-map',
  default: { places: [] as IPlace[], showOnMap: false }
})