import React, { FC } from 'react';
import { BusyInterval, FreeInterval, ProcessedInterval } from "../../../models/_admin/IProjectBusyAndFreeInterval";
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './doughnutChart.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import Icon from "../Icons/Icon";

interface Props {
  max: number
  busyIntervals: BusyInterval[]
  colors?: { freeColor: string, busyColor: string[] }
  centerLabels: string[]
  processedIntervals: ProcessedInterval[]
  freeIntervals: FreeInterval[]
  withAccordions?: boolean
  count: number
}

const DoughnutChart: FC<Props> =
  ({
     colors = {
       freeColor: '#e5e5e5',
       busyColor: [
         '#ff3c00',
         '#0059ff',
         '#8900ff',
         '#00ffc3',
         '#009aff',
         '#af0000',
         '#eb00ff',
         '#ffc400',
         '#ff4500'
       ]
     },
     max,
     centerLabels,
     busyIntervals,
     processedIntervals,
     freeIntervals,
     withAccordions = false,
     count
   }) => {

    const allIntervals = freeIntervals.map((interval) => ({
      ...interval,
      backgroundColor: '#bbbbbb'
    }));

    const processedAndNotProcessedIntervals: any[] = []

    const datasetsBusy: { data: number[], backgroundColor: string[] } = { data: [], backgroundColor: [] }
    const datasetsProcessed: { data: number[], backgroundColor: string[] } = { data: [], backgroundColor: [] }

    processedIntervals.forEach((interval: any) => {
      let lastElement = processedAndNotProcessedIntervals.at(-1)
      interval.backgroundColor = '#00ff20'
      if (lastElement) {
        if (interval.startId !== lastElement.endId) {
          processedAndNotProcessedIntervals
            .push({ startId: lastElement.endId, endId: interval.startId, backgroundColor: '#646464' }, interval)
        } else {
          processedAndNotProcessedIntervals.push(interval)
        }
      } else {
        processedAndNotProcessedIntervals.push(interval)
      }
    })

    busyIntervals.forEach((interval, index) => {
      allIntervals.push({ ...interval, backgroundColor: colors.busyColor[index > 10 ? index % 10 : index] })
    })

    const sortIntervals = allIntervals
      .sort((a, b) => a.startId - b.startId)


    sortIntervals.forEach((interval, index) => {
      datasetsBusy.data.push(interval.endId - interval.startId + 1)
      datasetsBusy.backgroundColor.push(interval.backgroundColor)
    })

    processedAndNotProcessedIntervals.forEach((interval) => {
      datasetsProcessed.data.push(interval.endId - interval.startId + 1)
      datasetsProcessed.backgroundColor.push(interval.backgroundColor)
    })

    const countIntervals = datasetsBusy.data.reduce((acc, interval) => acc + interval, 0)
    datasetsBusy.data.push(count - countIntervals)
    datasetsBusy.backgroundColor.push('#646464')

    datasetsProcessed.data.push(max - processedIntervals[processedIntervals.length - 1]?.endId)
    datasetsProcessed.backgroundColor.push('#646464')

    const countProcessed = processedIntervals.reduce((acc, interval) => acc + interval.endId - interval.startId, 0)

    const data = { datasets: [datasetsBusy, datasetsProcessed] };

    return (
      <Stack direction={'row'}>

        <div className={styles.wrapper}>
          <div className={styles.doughnut}>
            <Doughnut data={data}/>
          </div>

          <div className={styles.center}>
            {centerLabels.map((label, index) =>
              <span key={index}>{label}</span>
            )}
          </div>
        </div>

        <div>
          {withAccordions &&
              <div className={styles.legend}>
                  <p> Кладбище расшифрованно на {(countProcessed / max * 100).toFixed(2)}% </p>
                {
                  busyIntervals.map(({ user, endId, startId, countProcessed }, index) => (
                    <Accordion key={user.email + index}>

                      <AccordionSummary
                        expandIcon={<Icon icon={'expandMore'}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className={styles.colorContainer}
                             style={{ backgroundColor: colors.busyColor[index > 10 ? index % 10 : index] }}/>
                        <Typography>{user.email}</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Stack direction={'row'}>

                          <div style={{ width: '10rem', height: '10rem' }}>
                            <Doughnut data={{
                              datasets: [{
                                data: [countProcessed || 0, endId - startId - (countProcessed || 0)],
                                backgroundColor: ['#00ff20', '#646464']
                              }]
                            }}/>
                          </div>

                          <div>
                            <p>Пользователь: {user.lastname} {user.firstname} {user.patronymic}</p>
                            <p>Интервал: {startId} - {endId}</p>
                            <p>
                              Расшифровка интервала закончена
                              на {((countProcessed || 0) / (endId - startId) * 100).toFixed(2)}%
                            </p>
                          </div>

                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </div>
          }
        </div>
      </Stack>
    );
  };

export default DoughnutChart;