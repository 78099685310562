// packages
import React, { FC, MutableRefObject } from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
// components
import Timer from '../../../../components/timer/Timer';
// styles
import styles from './statisticAndActions.module.scss';
// store
import { decodeState, decodeStatisticState } from '../../../../store/projectStore';
// urls/api
import { getUserDecodeInterval } from '../../../../api/statistic/statisticApi';

interface Props {
  timerRef: MutableRefObject<number>
}

const Statistic: FC<Props> = ({ timerRef }) => {
  const { data } = useQuery(['user-decode-interval'], getUserDecodeInterval)
  const [currentDecodePage] = useRecoilState(decodeState)
  const [decodeStatistic] = useRecoilState(decodeStatisticState)
  const { currentGraveIndex, currentPage } = currentDecodePage
  const { decodeMe, avgTime } = decodeStatistic

  const avgTimeDecode = avgTime / decodeMe

  return (
    <Box className={styles.statistic__wrapper}>
      <Timer
        timerRef={timerRef}
        changeDependencies={[currentGraveIndex, currentPage]}
      />
      <Box className={styles.field}>
        <span className={styles.label}>Расшифровано мной: </span>
        <span className={styles.value}>{decodeMe}</span>
      </Box>
      <Box className={styles.field}>
        <span className={styles.label}>
          Среднее время расшифровки:
        </span>
        <span className={styles.value}>
          {!isNaN(avgTimeDecode) ? (avgTimeDecode).toFixed(2) : 0}
        </span>
        <span className={styles.label}>
          сек.
        </span>
      </Box>
      <Box className={styles.field}>
        {data?.interval
          ? <>
            <span className={styles.label}>Ваш интервал:</span>
            <span className={styles.value}>
              от {data.interval.startId} до {data.interval.endId}
            </span>
          </>
          : <span>Интервал не задан все ограды доступны</span>
        }

      </Box>
    </Box>
  );
};

export default React.memo(Statistic);