import React, {useState} from 'react';
import {IProject} from "../../models/IProject";
import {useForm} from "react-hook-form";
import {useCustomMutation} from "../../api/useCustomMutation";
import {toast} from "react-toastify";
import {REPLACE_PLACES_BY_FILE_URL} from "../../api/urls";
import {Card, CardContent, Select, Stack, TextField, Typography} from "@mui/material";
import styles from "../CalculatePlacesTypes/calculatePlacesTypes.module.scss";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import {algorithmsTitles} from "../../utils/utils";
import SelectProject from "../SelectProject/SelectProject";
import LoadingButton from "@mui/lab/LoadingButton";


const ReplacePlaces = () => {
  const [project, setProject] = useState<IProject>()
  const {handleSubmit, register} = useForm()
  const mutation = useCustomMutation()

  const onProjectClick = (selectedProject: IProject) => {
    setProject(selectedProject)
  }

  const onSubmit = async ({File}: any) => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      const formData = new FormData()
      formData.append('schema', project?.schema)
      formData.append('file', File[0])

      await mutation.mutateAsync({
        method: 'post',
        url: REPLACE_PLACES_BY_FILE_URL,
        data: formData
      })

      toast.success('Ограды успешно заменён')
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Замена ограды
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader
        title={algorithmsTitles.ReplacePlaces}
        onInfoClick={onInfoClick}
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <SelectProject onProjectClick={onProjectClick}/>
            <Typography variant={'h5'}>{project?.name}</Typography>
          </Stack>

          <TextField
            {...register('File', {required: true})}
            size={'small'}
            type={'file'}
            label={'Замена'}
            InputLabelProps={{shrink: true}}
          />

          <LoadingButton variant={'outlined'} onClick={handleSubmit(onSubmit)}>
            Заменить
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReplacePlaces;