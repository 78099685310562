// packages
import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
// components
import Footer from './footer/Footer';
import Content from './content/Content';
import Loader from '../../../components/UI/Loader/Loader';
import StatisticAndActions from './statisticAndActions/StatisticAndActions';
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation';
// helpers
import { ls_getProjectInfo, ls_setCurrentPageDecode } from '../../../helpers/localStorage';
// styles
import styles from './decode.module.scss';
// store
import { decodeState, decodeStatisticState } from '../../../store/projectStore';
// urls/api
import { getDecodeData } from '../../../api/project/projectApi';
import { GET_PLACE_URL } from '../../../api/urls';
import { DECODE_PAGE } from '../../../router/routeConsts';

const Decode = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const projectInfo = ls_getProjectInfo()
  const mutation = useCustomMutation()

  const [currentDecodePage, setCurrentDecodePage] = useRecoilState(decodeState)
  const [, setDecodeStatistic] = useRecoilState(decodeStatisticState)
  const { currentPage, placesIds, currentPlace, currentGraveIndex, sortType } = currentDecodePage
  const timerRef = useRef(0)

  const { data: response, isLoading } = useQuery(['decode', sortType], () => getDecodeData(sortType))

  const loadPlace = (
    indexPlace: number,
    graveIndex: number | null = null,
    currentPage: null | number = null
  ) => {
    mutation.mutateAsync({
      method: 'get',
      url: GET_PLACE_URL + placesIds[indexPlace]?.id + `?schema=${projectInfo.schema}`
    })
      .then(({ data }) => {
        setCurrentDecodePage(prev => ({
          ...prev,
          currentPlace: data.place,
          currentGrave: data.place.graves[graveIndex ? graveIndex : prev.currentGraveIndex],
          currentPage: currentPage ? currentPage : prev.currentPage
        }))
      })
  }

  useEffect(() => {
    if (response?.decodeData) {
      setCurrentDecodePage(prev => ({
        ...prev,
        placesIds: response.decodeData,
        graveTypes: response.graveTypes
      }))

      setDecodeStatistic({
        decodeMe: response.history.length,
        avgTime: response.history
          .reduce((acc: number, elHistory: any) => acc + elHistory.secondsSpent, 0),
        dirtyFields: '',
      })
    }
  }, [response])

  useEffect(() => {
    if (placesIds?.length) {
      let indexPlace = currentPage - 1

      if (!isNaN(Number(id))) {
        indexPlace = placesIds.findIndex(place => place.id === Number(id))

        if (indexPlace >= 0) {
          setCurrentDecodePage(prev => ({
            ...prev,
            currentPage: indexPlace + 1
          }))
        } else {
          toast.error('Не найдена ограда или у вас задан интервал. Вы будете возвращены к первой ограде')
          setTimeout(() => {
            ls_setCurrentPageDecode(1)
            navigate(DECODE_PAGE + ':id')
          }, 3000)
        }
      } else {
        navigate(DECODE_PAGE + placesIds[0].id)
      }

      ls_setCurrentPageDecode(currentPage)
      loadPlace(indexPlace)
    }
  }, [placesIds, id])

  useEffect(() => {
    if (currentPlace) {
      setCurrentDecodePage(prev => ({
        ...prev,
        currentGrave: prev.currentPlace.graves[prev.currentGraveIndex]
      }))
    }
  }, [currentGraveIndex])

  if (!projectInfo?.schema) {
    return <h2>Загрузите проект</h2>
  }

  if (isLoading || !response || !placesIds) {
    return <Loader/>
  }

  return (
    <Box className={styles.wrapper}>
      <Stack>
        <StatisticAndActions
          timerRef={timerRef}
        />
        <Content
          timerRef={timerRef}
          currentDecodePage={currentDecodePage}
          setCurrentDecodePage={setCurrentDecodePage}
          setDecodeStatistic={setDecodeStatistic}
        />
        <Footer
          sortType={sortType}
          setCurrentDecodePage={setCurrentDecodePage}
          currentPage={currentPage}
          countOfPlaces={placesIds.length}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(Decode);