import React, { FC, SyntheticEvent, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import styles from './userIntervals.module.scss';
import DoughnutChart from '../../../../../components/UI/DoughnutChart/DoughnutChart';
import { IProject } from '../../../../../models/IProject';
import { IUser } from '../../../../../models/IUser';
import { IIntervalForm } from '../../../../../models/_admin/IIntervalForm';
import { toast } from 'react-toastify';
import { IProjectBusyAndFreeInterval } from '../../../../../models/_admin/IProjectBusyAndFreeInterval';
import Icon from '../../../../../components/UI/Icons/Icon';

interface Props {
  isOpen: boolean
  onClose: () => void
  projects: IProject[]
  users: IUser[]
  onSubmit: (data: IIntervalForm) => void
  intervals: IProjectBusyAndFreeInterval
  defaultValues?: IIntervalForm
  title: string
}


const IntervalForm: FC<Props> = ({
                                   isOpen,
                                   users,
                                   onClose,
                                   projects,
                                   onSubmit,
                                   intervals,
                                   defaultValues = undefined,
                                   title
                                 }) => {
  const [form, setForm] = useState<IIntervalForm>(defaultValues || { schema: '', userId: 0, startId: 0, endId: 0 })
  const [expanded, setExpanded] = useState<string | false>('freeIntervals')

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (e: any) => {
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = () => {
    if (
      (form.startId && !isNaN(Number(form.startId))) &&
      (form.endId && !isNaN(Number(form.endId))) &&
      (Number(form.startId) < Number(form.endId))
    ) {
      form.startId = Number(form.startId)
      form.endId = Number(form.endId)
      onSubmit(form)
      return
    }

    toast.error('Укажите действительные startId и endId')
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'lg'}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <div className={styles.create__form__wrapper}>
          <Stack spacing={2} padding={1}>
            <Select
              onChange={handleChange}
              value={form.schema}
              name={'schema'}
            >
              {projects.map(project =>
                <MenuItem key={project.schema} value={project.schema}>
                  {project.name}
                </MenuItem>
              )}
            </Select>

            <Select
              onChange={handleChange}
              value={form.userId}
              name={'userId'}
            >
              <MenuItem value={0}> Выберите пользователя </MenuItem>
              {users.map(user =>
                <MenuItem key={user.id} value={user.id}>
                  {`${user.lastname}  ${user.email}`}
                </MenuItem>
              )}
            </Select>

            <TextField
              name={'startId'}
              onChange={handleChange}
              value={form.startId}
              label={'startID'}
            />

            <TextField
              name={'endId'}
              onChange={handleChange}
              value={form.endId}
              label={'endID'}
            />
          </Stack>

          <div className={styles.free__intervals}>
            {form.schema ?
              intervals[form.schema] &&
                <Stack direction={'row'}>
                    <div>
                        <DoughnutChart
                            count={intervals[form.schema].count}
                            busyIntervals={intervals[form.schema].busyIntervals}
                            freeIntervals={intervals[form.schema].freeIntervals}
                            processedIntervals={intervals[form.schema].processedIntervals}
                            max={intervals[form.schema].max}
                            centerLabels={[
                              projects.find(project => project.schema === form.schema)?.name || '',
                              form.schema,
                              `${intervals[form.schema].count} оград`
                            ]}
                        />
                    </div>

                    <Stack spacing={1}>
                        <Accordion
                            expanded={expanded === 'freeIntervals'}
                            onChange={handleAccordionChange('freeIntervals')}
                        >
                            <AccordionSummary
                                expandIcon={<Icon icon={'expandMore'}/>}
                                aria-controls="panel1bh-content"
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    Свободные интервалы (серые)
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              {intervals[form.schema].freeIntervals.map(interval =>
                                <Stack spacing={1} direction={'row'} key={interval.endId + interval.startId + '1'}>
                                  <span>{interval.startId}</span>
                                  <span>-</span>
                                  <span>{interval.endId}</span>
                                </Stack>
                              )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon icon={'expandMore'}/>}
                                aria-controls="panel1bh-content"
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    Заданные интервалы
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              {intervals[form.schema].busyIntervals.map(interval =>
                                <Stack spacing={1} direction={'row'} key={interval.endId + interval.startId + '2'}>
                                  <span>{interval.startId}</span>
                                  <span>-</span>
                                  <span>{interval.endId}</span>
                                </Stack>
                              )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon icon={'expandMore'}/>}
                                aria-controls="panel1bh-content"
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    Обработаные интервалы (зелёные)
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              {intervals[form.schema].processedIntervals.map(interval =>
                                <Stack spacing={1} direction={'row'} key={interval.endId + interval.startId + '3'}>
                                  <span>{interval.startId}</span>
                                  <span>-</span>
                                  <span>{interval.endId}</span>
                                </Stack>
                              )}
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                </Stack>
              :
              'Выберите проект'
            }
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button onClick={handleSubmit}>Создать</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IntervalForm;