import { bbox, booleanWithin, randomPoint } from '@turf/turf';

export const getRandomPointOnPolygon = (polygon: any) => {
  let poly: any = polygon

  if (typeof polygon === 'string') {
    poly = JSON.parse(polygon)
  }

  let locations;
  do {
    locations = randomPoint(1, { bbox: bbox(poly) });
  } while (!booleanWithin(locations.features[0], poly));
  return locations.features[0];
}