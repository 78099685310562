import dayjs from 'dayjs';
import { NO_INFO } from '../utils/utils';

type Data = number | string | boolean | null | undefined | Date | string[]

type Options = {
  dateWithoutTime?: boolean
  withParseInt?: boolean
}

const initOptions: Options = {
  dateWithoutTime: false,
  withParseInt: false
}

export const showInfoField =
  (data: Data, { dateWithoutTime, withParseInt }: Options = initOptions): string | number => {
    if (!data) {
      return NO_INFO
    }

    if (data.toString().includes('type')) {
      const checkGeometry = JSON.parse(data.toString())

      if (checkGeometry.type) {
        return `${checkGeometry.coordinates[0]}, ${checkGeometry.coordinates[1]}`
      }
    }

    if (Array.isArray(data)) {
      return data.toString()
    }

    if (typeof data === 'boolean') {
      return data ? 'ДА' : 'НЕТ'
    }

    const date = dayjs(data)

    if (
      data &&
      date.isValid() &&
      typeof data !== 'number' &&
      typeof data === 'string' &&
      data.includes('Z') &&
      isNaN(Number(data))
    ) {
      return date.format(dateWithoutTime ? 'DD-MM-YYYY' : 'DD.MM.YYYY HH:mm')
    }

    if (data || data === 0) {
      if (typeof data === 'number' && withParseInt) {
        return data.toFixed(2)
      }
      return data.toString()
    }

    return NO_INFO
  }