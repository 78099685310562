import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from '../headers.module.scss';
import Icon from '../../../components/UI/Icons/Icon';

interface Props {
  title: string
}

const PlaceAndGraveHeader: FC<Props> = ({ title }) => {
  const navigate = useNavigate()

  const onGoBack = () => {
    navigate(-1);
  }

  return (
    <div className={styles.title__wrapper}>
      <Icon icon={'arrowBack'} className={styles.arrow} onClick={onGoBack}/>
      <Typography variant={'h5'}>{title}</Typography>
    </div>
  );
};

export default PlaceAndGraveHeader;