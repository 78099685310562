export const INFO_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/info.svg'
export const BAN_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/gavel.svg'
export const ACTIVATE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/electrical_services.svg'
export const ROLE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/theater_comedy.svg'
export const DELETE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/delete.svg'
export const SET_PERMISSION_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/border_inner.svg'
export const EDIT_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/edit.svg'
export const RESTORE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/restore_from_trash.svg'
export const DOUBLE_ARROW_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/double_arrow.svg'
export const ARROW_BACK_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/arrow_back.svg'
export const EXPAND_MORE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/expand_more.svg'
export const VISIBILITY_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/visibility.svg'
export const VISIBILITY_OFF_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/visibility_off.svg'
export const ADD_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/add.svg'
export const COPY_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/copy.svg'
export const SAVE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/save.svg'
export const ROTATE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/rotate.svg'
export const EDIT_CEMETERY_PERMISSIONS_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/rules.svg'
export const EDIT_MUNICIPALITY_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/edit_municipality.svg'
export const RULER_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/ruler.svg'
export const START_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/start.svg'
export const CLOSE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/close.svg'
export const CHECK_CIRCLE_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/check_circle.svg'
export const SELECT_FINGER_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/select_finger.svg'
export const SETTINGS_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/settings.svg'
export const SEARCH_ICON_URL = 'https://storage.cloud.croc.ru:443/new.cdn.cloudmaps.ru/cloudmaps_icons/search.svg'
export const ADD_GROUP_ICON_URL = 'https://storage.cloud.croc.ru/new.cdn.cloudmaps.ru/cloudmaps_icons/ad_group.svg'

