// packages
import React, { useState } from 'react'
import { Button, FormControlLabel, Stack, Switch } from '@mui/material'
import { useQuery } from 'react-query'
import { DataGrid, ruRU } from '@mui/x-data-grid';
// components
import CreateCemeteryForm from './CreateCemeteryForm'
import Loader from '../../../components/UI/Loader/Loader';
// logic
import { useCemeteries } from './useCemeteries'
import { getCemeteries } from '../../../api/cemetery/getCemeteries'
import { getMunicipalities } from '../../../api/municipality/getMunicipalities'
// styles
import styles from './cemeteries.module.scss';

const Cemeteries = () => {
  const [showCreate, setShowCreate] = useState(false)
  const { data: cemeteries } = useQuery(['cemeteries'], getCemeteries)
  const { data: municipalities } = useQuery(['municipalities'], getMunicipalities)

  const { columns, rows, handleToggle, isExistDeleted } = useCemeteries()

  const onClose = () => setShowCreate(false)

  if (!cemeteries || !municipalities) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1}>
        <div className={styles.title}>
          <h1>Управление кладбищами</h1>

          <Button onClick={() => setShowCreate(true)} variant={'outlined'}>
            Создать кладбище
          </Button>
        </div>

        <CreateCemeteryForm isOpen={showCreate} onClose={onClose}/>

        <FormControlLabel
          control={
            <Switch
              defaultChecked={false}
              onChange={handleToggle}
              disabled={isExistDeleted}
            />}
          label="Удалёные кладбища"
        />

        <div className={styles.table_wrapper}>
          <DataGrid
            columns={columns}
            rows={rows}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Stack>
    </div>
  )
}

export default Cemeteries