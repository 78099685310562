import React, { FC } from 'react';
import { useDialog } from 'react-st-modal';
import { Button, Stack } from "@mui/material";
import styles from './styles.module.scss';

interface Props {
  okButtonText: string
  cancelButtonText: string
  title: string
}

const CustomConfirmDeleteDialogNoteOrTask: FC<Props> = ({ okButtonText, cancelButtonText, title }) => {
  const dialog = useDialog();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>

      <div className={styles.body}>

        <Stack direction={'row'} spacing={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close(true)
            }}
          >
            {okButtonText}
          </Button>

          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close(false)
            }}
          >
            {cancelButtonText}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default CustomConfirmDeleteDialogNoteOrTask;