import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  Toolbar,
  Typography
} from "@mui/material";
import Icon from "../../UI/Icons/Icon";
import { graveDto } from "../../../pages/project/graves/useGraves";
import { StyledTableCell, StyledTableRow } from "../../UI/StyledTable/StyledTable";
import styles from '../importVectorsRgis.module.scss';
import { placeDto } from "../../../pages/project/places/usePlaces";

interface Props {
  onClose: () => void
}

const ImportVectorsFileMap: FC<Props> = ({ onClose }) => {
  return (
    <>
      <AppBar>
        <Toolbar>
          <Button
            color={'info'}
            variant={'contained'}
            onClick={onClose}
          >
            Закрыть
          </Button>

          <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
            Карта файлов для первичной и повторной инвентаризаций
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent className={styles.files_map__content}>
        <Card className={styles.files_map__card}>

          <CardHeader title={'Первичная инвентаризация'}/>

          <CardContent className={styles.files_map__card_content}>
            <Typography variant={'h6'}>
              Файлы для первичной инвентаризации должны содержать только координаты и номера объектов для сохранения
              связей
            </Typography>

            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon={'expandMore'}/>}
                aria-controls="panel1bh-content"
              >
                <Typography variant={'h5'}>
                  Файл оград
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        Поле
                      </StyledTableCell>

                      <StyledTableCell>
                        Название поля в файле
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>id</StyledTableCell>
                      <StyledTableCell>id</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell>id сектора</StyledTableCell>
                      <StyledTableCell>id_section</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell>Координаты</StyledTableCell>
                      <StyledTableCell>geometry</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon={'expandMore'}/>}
                aria-controls="panel1bh-content"
              >
                <Typography variant={'h5'}>
                  Файл могил
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        Поле
                      </StyledTableCell>
                      <StyledTableCell>
                        Название поля в файле
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>id</StyledTableCell>
                      <StyledTableCell>id</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell>id ограды</StyledTableCell>
                      <StyledTableCell>place_id</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell>Координаты</StyledTableCell>
                      <StyledTableCell>geometry</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>

          </CardContent>
        </Card>

        <Card className={styles.files_map__card}>

          <CardHeader title={'Повторная инвентаризация'}/>

          <CardContent className={styles.files_map__card_content}>

            <Typography variant={'h6'}>
              Файлы границ и секторов должны содержать только координаты и номера объектов для сохранения
              связей
            </Typography>

            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon={'expandMore'}/>}
                aria-controls="panel1bh-content"
              >
                <Typography variant={'h5'}>
                  Файл оград
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        Поле
                      </StyledTableCell>

                      <StyledTableCell>
                        Название поля в файле
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    {placeDto.map(({ nameInImportsFile, label }) => (
                      <StyledTableRow key={nameInImportsFile}>
                        <StyledTableCell>{label}</StyledTableCell>
                        <StyledTableCell>{nameInImportsFile || 'Не заполняется'}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                    <StyledTableRow>
                      <StyledTableCell>Координаты</StyledTableCell>
                      <StyledTableCell>geometry</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon={'expandMore'}/>}
                aria-controls="panel1bh-content"
              >
                <Typography variant={'h5'}>
                  Файл могил
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        Поле
                      </StyledTableCell>
                      <StyledTableCell>
                        Название поля в файле
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {graveDto.map(({ nameInImportsFile, label }) => (
                      <StyledTableRow key={nameInImportsFile}>
                        <StyledTableCell>{label}</StyledTableCell>
                        <StyledTableCell>{nameInImportsFile || 'Не заполняется'}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                    <StyledTableCell>Координаты</StyledTableCell>
                    <StyledTableCell>geometry</StyledTableCell>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </DialogContent>
    </>
  );
};

export default ImportVectorsFileMap;