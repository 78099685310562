import React, { FC } from 'react';
import EditOnePlaceForm from "./EditOnePlaceForm";
import Loader from "../UI/Loader/Loader";

interface Props {
  firstPlaceId: number | undefined
  secondPlaceId: number | undefined
  cancel: () => void
}

const EditTwoPlaces: FC<Props> = ({ secondPlaceId, firstPlaceId, cancel }) => {
  if (firstPlaceId && secondPlaceId) {
    return (
      <>
        <EditOnePlaceForm id={firstPlaceId} cancel={cancel}/>
        <EditOnePlaceForm id={secondPlaceId} cancel={cancel}/>
      </>
    );
  } else {
    if (firstPlaceId) {
      return (
        <>
          <EditOnePlaceForm id={firstPlaceId} cancel={cancel}/>
        </>
      )
    } else if (secondPlaceId) {
      return (
        <>
          <EditOnePlaceForm id={secondPlaceId} cancel={cancel}/>
        </>
      )
    } else {
      return <Loader/>
    }
  }
};

export default EditTwoPlaces;