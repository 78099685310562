import React, { FC, useState } from 'react';
import { useRecoilState } from "recoil";
import { UserActionsHistoryState } from "../../../../../store/userActionsHistoryStore";
import { Table, TableBody, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../../../components/UI/StyledTable/StyledTable";
import { showInfoField } from "../../../../../helpers/showInfoField";
import UserActionsHistoryDescriptionCell from "./UserActionsHistoryDescriptionCell";
import { actionObjectType, IUserActionsHistory } from "../../../../../models/IUserActionsHistory";
import TableContainer from "@mui/material/TableContainer";
import { GRAVE_PAGE, PLACE_PAGE } from "../../../../../router/routeConsts";
import { Link } from "react-router-dom";
import { TablePaginationActions } from "../../../../../components/stubsStatistic/StubsTable";
import { algorithmsTitles } from "../../../../../utils/utils";

interface Props {
  actionsHistoryProp?: IUserActionsHistory[]
}

const ShowUserActionsHistory: FC<Props> = ({ actionsHistoryProp = [] }) => {
  const [actionsHistoryFromState] = useRecoilState(UserActionsHistoryState)
  let actionsHistory = actionsHistoryFromState
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  if (actionsHistoryProp?.length) {
    actionsHistory = actionsHistoryProp
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0);
  };

  const getObjectNameOnRussian = (objName: actionObjectType) => {
    switch (objName) {
      case "project":
        return 'Проект'
      case "place":
        return 'Ограда'
      case "planned_place":
        return 'Запланированная ограда'
      case "grave":
        return 'Могила'
      default:
        return 'Ошибка'
    }
  }

  const getCellWithActionObject = (row: IUserActionsHistory) => {
    if (row.actionObject !== 'project') {
      if (row.changesDescription.action === 'delete') {
        if (!row.changesDescription.objectNumber) {
          return <p>{getObjectNameOnRussian(row.actionObject)} с id: {row.changesDescription.objectId}</p>
        } else {
          return <p>{getObjectNameOnRussian(row.actionObject)} с номером: {row.changesDescription.objectNumber}</p>
        }
      } else {
        let link;

        switch (row.actionObject) {
          case "grave":
            link = GRAVE_PAGE + row.changesDescription.objectId
            break
          case "place":
            link = PLACE_PAGE + row.changesDescription.objectId + '?common'
            break
          case "planned_place":
            link = PLACE_PAGE + row.changesDescription.objectId + '?planned'
            break
          default:
            link = PLACE_PAGE
        }

        if (!row.changesDescription.objectNumber) {
          return (
            <Link to={link}>
              {getObjectNameOnRussian(row.actionObject)} с id: {row.changesDescription.objectId}
            </Link>
          )
        } else {
          return (
            <Link to={link}>
              {getObjectNameOnRussian(row.actionObject)} с номером: {row.changesDescription.objectNumber}
            </Link>
          )
        }
      }
    } else {
      return <p>{getObjectNameOnRussian(row.actionObject)}</p>
    }
  }

  const getActionNameOnRussian = (row: IUserActionsHistory) => {
    if (row.actionObject === "project") {
      // @ts-ignore
      return algorithmsTitles[row.changesDescription.data[0].algorithmName]
    }

    switch (row.changesDescription.action) {
      case 'edit':
        return 'Редактирование'
      case 'delete':
        return 'Удаление'
      case 'delete_photo':
        return 'Удаление фото'
      case 'create_photo':
        return 'Добавление фото'
      case 'move_grave':
        return 'Перемещение могилы в другую ограду'
      default:
        return 'Ошибка'
    }
  }

  if (!actionsHistory.length) {
    return <></>
  }

  return (
    <TableContainer>
      <Table sx={{ width: '100rem' }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              Пользователь
            </StyledTableCell>

            <StyledTableCell>
              Объект действия
            </StyledTableCell>

            <StyledTableCell>
              Действие
            </StyledTableCell>

            <StyledTableCell>
              Дата и время действия
            </StyledTableCell>

            <StyledTableCell>
              Описание
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
              ? actionsHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : actionsHistory
          ).map((row) => (
            <StyledTableRow>
              <StyledTableCell>
                <p>
                  {row.user.lastname + ' '}
                  {row.user.firstname + ' '}
                  {row.user.patronymic + ' '}
                </p>
                <p>{row.user.email}</p>
              </StyledTableCell>

              <StyledTableCell>
                <p>{getCellWithActionObject(row)}</p>
              </StyledTableCell>

              <StyledTableCell>
                {getActionNameOnRussian(row)}
              </StyledTableCell>

              <StyledTableCell>
                {showInfoField(row.dateCreated, { dateWithoutTime: false })}
              </StyledTableCell>

              <StyledTableCell>
                <UserActionsHistoryDescriptionCell
                  action={row.changesDescription.action}
                  description={row.changesDescription}
                  entity={row.actionObject}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Всё', value: -1 }]}
              colSpan={3}
              count={actionsHistory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ShowUserActionsHistory;