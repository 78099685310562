import React, { FC, useEffect, useState } from 'react';
import styles from './form.module.scss';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SetterOrUpdater } from 'recoil';
import Loader from '../../../../components/UI/Loader/Loader';
import { IStatisticAtom } from '../../../../models/_admin/IStatisticAtom';
import { useForm } from 'react-hook-form';
import { IStatisticForm } from '../../../../models/_admin/IStatisticForm';
import { ls_setProjectInfo } from '../../../../helpers/localStorage';
import { IUser } from '../../../../models/IUser';
import { IProject } from '../../../../models/IProject';
import SelectProject from "../../../../components/SelectProject/SelectProject";
import { GET_ALL_USERS_URL } from "../../../../api/urls";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface Props {
  isLoading: boolean
  onSubmit: (data: IStatisticForm) => void
  setStatistic: SetterOrUpdater<IStatisticAtom>
  defaultUser?: IUser
  projects: IProject[]
  usersUrl?: string
}

type FormFields = {
  user: string
}

const Form: FC<Props> = ({
                           isLoading,
                           onSubmit,
                           setStatistic,
                           defaultUser,
                           projects,
                           usersUrl = GET_ALL_USERS_URL
                         }) => {
  const { register, handleSubmit } = useForm<FormFields>()
  const [project, setProject] = useState<IProject>()
  const mutation = useCustomMutation()

  const [users, setUsers] = useState<IUser[] | undefined>()

  const onProjectClick = (selectedProject: IProject) => {
    ls_setProjectInfo(selectedProject)
    setProject(selectedProject)
  }

  const submit = (formData: FormFields) => {
    const foundUser = defaultUser || users?.find((u) => u.email === formData.user)

    if (foundUser && project) {
      setStatistic(prev => ({
        ...prev,
        project: project,
        user: foundUser
      }))

      ls_setProjectInfo(project)

      onSubmit({
        userId: foundUser.id,
        schema: project.schema
      })
    }
  }

  useEffect(() => {
    mutation.mutateAsync({
      method: 'get',
      url: usersUrl + `?schema=${project?.schema}`
    }).then(({ data }: AxiosResponse) => {
      setUsers(data)
    })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }, [project])

  if (!projects.length) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <SelectProject onProjectClick={onProjectClick}/>

      {!defaultUser &&
          <>
            {users?.length ?
              <>
                {mutation.isLoading ?
                  <Loader/>
                  :
                  <Autocomplete
                    size={'small'}
                    className={styles.select}
                    getOptionLabel={(option: any) => option.email || ''}
                    options={users}
                    renderInput={(params) =>
                      <TextField
                        {...register('user')}
                        {...params}
                        label={'Пользователи'}
                      />}
                  />
                }
              </>
              :
              <>
                <Typography variant={'h5'}> Проект не выбран или нет записей о расшифровке этого проекта </Typography>
              </>
            }
          </>
      }

      <LoadingButton
        variant={'contained'}
        loading={isLoading}
        onClick={handleSubmit(submit)}
      >
        Запросить
      </LoadingButton>

      {project ?
        <Stack direction={"row"} spacing={1} marginLeft={2}>
          <Typography variant={'h6'}>Выбранный проект:</Typography>
          <Typography variant={'h6'}>{project.name}</Typography>
        </Stack>
        :
        <></>
      }
    </div>
  );
};

export default Form;