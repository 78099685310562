import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select } from "@mui/material";
import { EXPORT_GRAVE_URL, EXPORT_PLACE_URL, EXPORT_USER_REPORT_URL } from "../../api/urls";
import { ls_getProjectInfo } from "../../helpers/localStorage";
import { useCustomMutation } from "../../api/useCustomMutation";
import { Controller, useForm } from 'react-hook-form';
import styles from './exportGraveOrPlaceReport.module.scss';
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import UserReports from "./UserReports/UserReports";
import { IExportUserReport } from "./IExportUserReport";

interface Form {
  File: string
  url: string
}

const ExportGraveOrPlaceReport: FC<IExportUserReport> = ({ id, entity }) => {
  const projectInfo = ls_getProjectInfo()
  const [open, setOpen] = useState(false)
  const mutation = useCustomMutation()
  const { handleSubmit, control, watch } = useForm<Form>()
  const [currentUrl, setCurrentUrl] = useState<string | undefined>(undefined)
  const watchUrl = watch('url');

  const urls: { label: string, url: string }[] = [
    {
      label: 'Автоматически сформированный',
      url: entity === 'place' ? (EXPORT_PLACE_URL) : (EXPORT_GRAVE_URL) + `?schema=${projectInfo.schema}&id=${id}`
    },
    {
      label: 'По шаблону',
      url: EXPORT_USER_REPORT_URL
    }
  ]

  const onOpenLink = () => {
    if (!currentUrl) {
      toast.error('Нет ссылки')
      return
    }

    window.open(currentUrl, '_blank', 'noopener,noreferrer')
  }

  const onOpenClose = () => {
    setCurrentUrl(undefined)
    setOpen(!open)
  }

  const onLoad = async (form?: any) => {
    try {
      setCurrentUrl(undefined)
      let data

      if (form.url === EXPORT_USER_REPORT_URL) {
        if (!form.File[0]) {
          toast.error('Шаблон отчёта не выбран');
          return
        }

        const formData = new FormData()

        formData.append('file', form.File[0])
        formData.append('schema', projectInfo.schema)
        formData.append('entity', entity)
        formData.append('id', id.toString())

        const { data: userReportData } = await mutation.mutateAsync({
          method: 'post',
          data: formData,
          url: form.url,
        })

        data = userReportData
      } else {
        let url = EXPORT_PLACE_URL

        if(entity === 'grave') {
          url = EXPORT_GRAVE_URL
        }

        const { data: reportData } = await mutation.mutateAsync({
          method: "get",
          url: url + `?schema=${projectInfo.schema}&id=${id}`
        })

        data = reportData
      }

      setCurrentUrl(data.urls[0])

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  useEffect(() => {
    setCurrentUrl(undefined)
  }, [watchUrl])

  return (
    <>
      <Button variant={'contained'} onClick={onOpenClose}>
        Отчёты
      </Button>

      <Dialog open={open} onClose={onOpenClose}>
        <DialogTitle>
          Выберите тип отчёта
        </DialogTitle>

        <DialogContent>
          <div className={styles.export_grave_or_place_report__form_wrapper}>
            <div className={styles.export_grave_or_place_report__select}>
              <InputLabel id="demo-simple-select-label">Формат</InputLabel>

              <Controller
                name={'url'}
                control={control}
                render={({ field }) =>
                  <Select
                    {...field}
                    sx={{ width: '100%' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Формат"
                  >
                    {urls.map(({ label, url }) => (
                      <MenuItem key={url} value={url}>{label}</MenuItem>
                    ))}
                  </Select>
                }
              />
            </div>

            {watchUrl === EXPORT_USER_REPORT_URL ?
              <UserReports
                entity={entity}
                id={id}
                currentUrl={currentUrl}
                setCurrentUrl={setCurrentUrl}
                onOpenClose={onOpenClose}
                onOpenLink={onOpenLink}
              />
              :
              <>
                <DialogActions>
                  {currentUrl &&
                      <Button onClick={onOpenLink}>
                          Скачать
                      </Button>
                  }

                  <LoadingButton
                    onClick={handleSubmit(onLoad)}
                    loading={mutation.isLoading}
                  >
                    Сформировать отчёт
                  </LoadingButton>

                  <Button onClick={onOpenClose}>
                    Отмена
                  </Button>
                </DialogActions>
              </>
            }
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExportGraveOrPlaceReport;