import React, { FC, useState } from 'react';
import { Card, CardActions, CardContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styles from '../CalculatePlacesTypes/calculatePlacesTypes.module.scss';
import { toast } from "react-toastify";
import { useCustomMutation } from "../../api/useCustomMutation";
import { CHECK_GRAVES_COORDINATES_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import Loader from "../UI/Loader/Loader";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

interface Props {
  schema?: string
  isLoading?: boolean
}

const CheckGravesCoordinates: FC<Props> = ({ isLoading, schema }) => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onSubmit = async () => {
    try {

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'CheckGravesCoordinates' }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      let currentSchema = schema || project.schema

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: CHECK_GRAVES_COORDINATES_URL + `?schema=${currentSchema}`
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  if (isLoading) {
    return <Loader/>
  }

  const onInfoClick = () => {
    toast(
      <>
        Проходимся по всем могилам.
        Если нет координат могилы, но есть координаты ограды, то в координаты могилы записываем случайную точку её
        ограды.
        Если могила находится не внутри её ограды, переносим её в её ограду.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>

      <CustomCardHeader title={algorithmsTitles.CheckGravesCoordinates} onInfoClick={onInfoClick}/>

      <CardContent>
        {!schema &&
            <div>
                <h3>{project?.name}</h3>
            </div>
        }
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать проверку
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default CheckGravesCoordinates;