export const HOME_PAGE = '/'
// auth
// export const SIGN_UP_PAGE = '/signUp'
export const SIGN_IN_PAGE = '/signIn'
export const RESET_PASSWORD_PAGE = '/reset-password/'
export const FORGOT_PASSWORD_PAGE = '/forgot-password'

// SITE ROUTES
export const OVERVIEW_PAGE = '/overview/'
export const PROJECTS_PAGE = OVERVIEW_PAGE + 'projects'
export const PLACES_PAGE = OVERVIEW_PAGE + 'places'
export const PLACE_PAGE = OVERVIEW_PAGE + 'place/'
export const EDIT_PLACE_PAGE = OVERVIEW_PAGE + 'place/edit/'
export const GRAVES_PAGE = OVERVIEW_PAGE + 'graves'
export const GRAVE_PAGE = OVERVIEW_PAGE + 'grave/'
export const EDIT_GRAVE_PAGE = OVERVIEW_PAGE + 'grave/edit/'
export const MAP_PAGE = OVERVIEW_PAGE + 'map'
export const DECODE_PAGE = OVERVIEW_PAGE + 'decode/'

// ADMIN ROUTES
export const OVERVIEW_ADMIN_PAGE = '/admin/'

export const ADMIN_FEATURES = OVERVIEW_ADMIN_PAGE + 'features'
export const IMPORT_FEATURES = ADMIN_FEATURES + '/import'
export const COMPARING_FEATURES = ADMIN_FEATURES + '/compare'
export const ADMIN_STUBS_FEATURES = ADMIN_FEATURES + '/finders'
export const ADMIN_OTHER_FEATURES = ADMIN_FEATURES + '/other'

export const ADMIN_PROJECTS = OVERVIEW_ADMIN_PAGE + 'projects'
export const ADMIN_CEMETERIES = OVERVIEW_ADMIN_PAGE + 'cemeteries'
export const ADMIN_MUNICIPALITIES = OVERVIEW_ADMIN_PAGE + 'municipalities'
export const ADMIN_USERS = OVERVIEW_ADMIN_PAGE + 'users'
export const ADMIN_USERS_TEMPLATES = OVERVIEW_ADMIN_PAGE + 'users-templates'

export const ADMIN_STATISTICS = OVERVIEW_ADMIN_PAGE + 'statistics'
export const ADMIN_PHOTO_STATISTIC = ADMIN_STATISTICS + '/photos'
export const ADMIN_DECODE_STATISTIC = ADMIN_STATISTICS + '/decode'
export const ADMIN_USER_ACTIONS_STATISTIC = ADMIN_STATISTICS + '/actions-history'

// profile
export const PROFILE_PAGE = '/profile/'
export const EDIT_PROFILE_PAGE = PROFILE_PAGE + 'edit'
export const STATISTIC_PROFILE_PAGE = PROFILE_PAGE + 'statistic'
export const PHOTO_STATISTIC_PROFILE_PAGE = PROFILE_PAGE + 'statistic/photo'