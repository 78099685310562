import React, { FC } from 'react';
import dayjs from "dayjs";
import styles from "./notOkCandidates.module.scss";
import { Link } from "react-router-dom";
import { GRAVE_PAGE } from "../../../router/routeConsts";
import { graveFieldNames, NO_INFO } from "../../../utils/utils";
import { INotOkCandidatesOfSync } from "../../../models/IRgisAttributes";

interface Props {
  body: INotOkCandidatesOfSync
}

const NotOkCandidatesAccordionHeader: FC<Props> = ({ body }) => {
  const diff = []
  const newData = body.graveFromDb as any
  const oldData = body.rgisData.searchableFields as any

  for (const [key, value] of Object.entries<any>(body.rgisData.searchableFields)) {

    if (!newData[key]) {
      diff.push({ key, diff: { old: oldData[key], newV: value } })
      continue
    }

    if (!value) {
      diff.push({ key, diff: { old: oldData[key], newV: value } })
      continue
    }

    if (dayjs(value).isValid()) {
      if (
        dayjs(value).diff(dayjs(oldData[key]), 'days') >= 2 ||
        dayjs(value).diff(dayjs(oldData[key]), 'days') <= -2
      ) {
        diff.push({
          key,
          diff: { old: dayjs(oldData[key]).format('DD-MM-YYYY'), newV: dayjs(value).format('DD-MM-YYYY') }
        })
      }
    } else {
      if (oldData[key].toLowerCase() !== value.toLowerCase()) {
        diff.push({ key, diff: { old: newData[key], newV: value } })
      }
    }
  }

  return (
    <div className={styles.search_diff__accordion__elem}>
      <Link to={GRAVE_PAGE + body.graveFromDb.id}>
        ID могилы: {body.graveFromDb.id}
      </Link>

      {diff.map(({ key, diff }) => (
        <li key={key}>
          {graveFieldNames[key]} : {diff.old || NO_INFO} ===={'>'}{'>'}{'>'}{'>'} {diff.newV || NO_INFO}
        </li>
      ))}
    </div>
  )
};

export default NotOkCandidatesAccordionHeader;