// packages
import React, { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
// components
import Info from './info/Info';
import ExportDataFromDB from '../../components/ExportDataFromDB/ExportDataFromDB';
import SelectProject from "../../components/SelectProject/SelectProject";
import SyncSchemaRgis from "../../components/SyncSchema/SyncSchemaRgis";
// styles
import styles from './project.module.scss'
// helpers
import { ls_setProjectInfo } from '../../helpers/localStorage';
// hooks
import { useCustomMutation } from '../../api/useCustomMutation';
// store
import { currentProjectAtom } from '../../store/projectStore';
import { mapStateAtom } from "../../store/mapStore";
// urls
import { GET_PROJECT_URL } from '../../api/urls';

const Project: FC = () => {
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectAtom)
  const [_, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()

  const onProjectClick = async (project: any) => {
    try {
      if (currentProject?.schema === project.schema && currentProject?.projectData) {
        return
      }

      setCurrentProject(undefined)

      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: GET_PROJECT_URL + project.schema
      })

      setCurrentProject(data.project)
      ls_setProjectInfo(project)

      if (project.schema !== currentProject?.schema) {
        setMapState(prev => ({
          ...prev,
          selectedObject: null,
          preSelectedObject: null,
          selectFinger: { isOn: false, placesArray: [], sectionsArray: [] }
        }))
      }

    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Grid container className={styles.wrapper}>
      <Grid item>
        <Stack>
          <h2>
            {!currentProject ? <div/> : <>{currentProject.name}</>}
          </h2>

          <SelectProject onProjectClick={onProjectClick}/>
        </Stack>
      </Grid>

      <Grid item>
        <Info isLoading={mutation.isLoading || !currentProject}/>
      </Grid>

      <Grid item>
        {currentProject &&
            <div>
                <Stack spacing={2}>
                    <ExportDataFromDB isLoading={mutation.isLoading} needShowSelect={false}/>

                    <SyncSchemaRgis loading={mutation.isLoading} needShowSelect={false}/>
                </Stack>
            </div>
        }
      </Grid>
    </Grid>
  );
};

export default React.memo(Project);