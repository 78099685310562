import { useRecoilValue } from "recoil";
import { currentProjectSelector } from "../../../store/projectStore";
import { showDate } from "../../../helpers/showDate";
import { Link } from "react-router-dom";
import { GRAVE_PAGE } from "../../../router/routeConsts";
import React from "react";
import { Stack } from "@mui/material";

interface ProjectTableData {
  label: string,
  value: string | number | JSX.Element | undefined
}

export const useInfo = () => {
  const project = useRecoilValue(currentProjectSelector)
  const mainData: ProjectTableData[] = [
    { label: 'Название кладбища: ', value: project?.cemetery?.name },
    { label: 'Дата создания: ', value: showDate(project?.dateCreated) },
    { label: 'Схема кладбища: ', value: project?.schema }
  ]

  const earthData: ProjectTableData[] = [
    { label: 'Площадь в границах кладбища:', value: project?.projectData?.cemeteryArea + ' м2' },
    { label: 'Площадь в границах мест захоронений:', value: project?.projectData?.placesArea.toFixed(2) + ' м2' },
    { label: 'Средняя площадь места захоронения:', value: project?.projectData?.placesMiddleArea + ' м2' },
    { label: 'Среднее количество могил в ограде:', value: project?.projectData?.middleCountGravesInPlace },
    { label: 'Количество мест захоронений', value: project?.projectData?.placesCount },
    { label: 'Количество запланированых мест захоронений', value: project?.projectData?.plannedPlacesCount },
    { label: 'Кол-во секторов', value: project?.projectData?.sectionsCount },
    { label: 'Кол-во границ', value: project?.projectData?.bordersCount },
  ]

  const peopleData: ProjectTableData[] = [
    { label: 'Количество могил:', value: project?.projectData?.gravesCount },
    { label: 'Средний возраст могил на кладбище:', value: project?.projectData?.middleAge },
    {
      label: 'Возраст самой старой могилы:',
      value:
        (
          <Link to={GRAVE_PAGE + project?.projectData?.max.id}>
            {project?.projectData?.max.age} лет
          </Link>
        )
    },
    {
      label: 'Возраст самой молодой могилы:',
      value:
        (
          <Link to={GRAVE_PAGE + project?.projectData?.min.id}>
            {project?.projectData?.min.age} лет
          </Link>
        )
    },
  ]

  const placesTypeData: ProjectTableData[] = [
    {
      label: '',
      value:
        <Stack direction={'row'} justifyContent={'space-around'} width={'100%'}>
          <div>Кол-во</div>
          <div>Площадь</div>
        </Stack>
    },
    {
      label: 'Нет информации',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.noInfo.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.noInfo.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
    {
      label: 'Одиночное',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.single.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.single.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
    {
      label: 'Родственное',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.related.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.related.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
    {
      label: 'Семейное (родовое)',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.family.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.family.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
    {
      label: 'Почётное',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.honorary.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.honorary.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
    {
      label: 'Воинское',
      value:
        <Stack direction={'row'} spacing={2} width={'100%'} justifyContent={'space-around'}>
          <div>
            {project?.projectData?.countPlacesByType.military.count}
          </div>

          <div>
            {project?.projectData?.countPlacesByType.military.area.toFixed(2)}{' '}м2
          </div>
        </Stack>
    },
  ]

  return {
    mainData,
    earthData,
    placesTypeData,
    peopleData
  }
}