import React from 'react';
import icon from "../../../assets/where-am-i.svg";
import cn from 'classnames';
import styles from './customButtons.module.scss';
import { useMap } from "react-leaflet";
import { toast } from "react-toastify";

const WhereAmIButton = () => {
  const mapRef = useMap()

  const onClick = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        mapRef.setView([position.coords.latitude, position.coords.longitude])
      },
      (positionError) => {
        toast.error(positionError.message)
        console.log(positionError)
      },
      {})
  }

  return (
      <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={onClick}>
        <img src={icon} alt="I"/>
      </div>
  );
};

export default WhereAmIButton;