import React, { useState } from 'react';
import styles from "../stubsStatistic/stubStatistic.module.scss";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useCustomMutation } from "../../api/useCustomMutation";
import { noGeometryState } from "../../store/noGeometryStore";
import { ls_setProjectInfo } from "../../helpers/localStorage";
import { FIND_OBJECTS_WITHOUT_GEOMETRY_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import { GRAVE_PAGE, PLACE_PAGE } from "../../router/routeConsts";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const FindObjectsWithoutGeometry = () => {
  const [project, setProject] = useState<any>()
  const mutation = useCustomMutation()
  const [noGeometry, setNoGeometry] = useRecoilState(noGeometryState)
  const [open, setOpen] = useState(false)

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const handleSubmit = async () => {
    try {
      ls_setProjectInfo(project)

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{algorithmName: 'FindObjectsWithoutGeometry',}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: FIND_OBJECTS_WITHOUT_GEOMETRY_URL + `?schema=${project.schema}`
      })

      setNoGeometry(data)

      toast.success(data.message);
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Получем из БД ограды и могилы, у которых нет координат.
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Card elevation={6} className={styles.card_form}>
        <CustomCardHeader title={algorithmsTitles.FindObjectsWithoutGeometry} onInfoClick={onInfoClick}/>

        <CardContent className={styles.form__wrapper}>
          <SelectProject onProjectClick={onProjectClick}/>

          <h2>{project ? project.name : 'Выберите проект'}</h2>

          <Stack direction={'row'} spacing={2}>
            <Button
              onClick={onOpen}
              disabled={!noGeometry.graves.length && !noGeometry.places.length}
            >
              Посмотреть результаты
            </Button>

            <LoadingButton
              onClick={handleSubmit}
              disabled={!project?.name || mutation.isLoading}
              loading={mutation.isLoading}
            >
              Начать поиск
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={onClose} fullScreen>
        <AppBar>
          <Toolbar>
            <Button
              color={'info'}
              variant={'contained'}
              onClick={onClose}
            >
              Закрыть
            </Button>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h4">
              Заглушки
            </Typography>

            <Typography sx={{ ml: 2, mr: 4 }} variant="h6">
              Перед удалением ограды, удалите все её могилы
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Card className={styles.data_card}>
            <CardHeader title={'Обнаруженые ограды/могилы:'}/>
            <Card>
              <CardHeader title={project?.name}/>
              <CardContent>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                  <div>
                    <h2>Ограды</h2>

                    <Grid container spacing={2} className={styles.no__geometry__data}>
                      {noGeometry.places.map((id) => (
                        <Grid item>
                          <Link to={PLACE_PAGE + id + `?common`}>{id}</Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  <div>
                    <h2>Могилы</h2>

                    <Grid container spacing={2} className={styles.no__geometry__data}>
                      {noGeometry.graves.map((id) => (
                        <Grid item>
                          <Link to={GRAVE_PAGE + id}>{id}</Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Stack>
              </CardContent>
            </Card>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FindObjectsWithoutGeometry;