import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from '../pages/layout/Layout';
import SignIn from '../pages/authorization/SignIn/SignIn';
import Home from '../pages/home/Home';
import OverView from '../pages/overview/OverView';
import {
  ADMIN_CEMETERIES,
  ADMIN_DECODE_STATISTIC,
  ADMIN_FEATURES,
  ADMIN_MUNICIPALITIES,
  ADMIN_OTHER_FEATURES,
  ADMIN_PHOTO_STATISTIC,
  ADMIN_PROJECTS,
  ADMIN_STATISTICS,
  ADMIN_STUBS_FEATURES,
  ADMIN_USER_ACTIONS_STATISTIC,
  ADMIN_USERS,
  ADMIN_USERS_TEMPLATES,
  COMPARING_FEATURES,
  DECODE_PAGE,
  EDIT_GRAVE_PAGE,
  EDIT_PLACE_PAGE,
  EDIT_PROFILE_PAGE,
  FORGOT_PASSWORD_PAGE,
  GRAVE_PAGE,
  GRAVES_PAGE,
  HOME_PAGE,
  IMPORT_FEATURES,
  MAP_PAGE,
  OVERVIEW_ADMIN_PAGE,
  OVERVIEW_PAGE,
  PHOTO_STATISTIC_PROFILE_PAGE,
  PLACE_PAGE,
  PLACES_PAGE,
  PROFILE_PAGE,
  PROJECTS_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  STATISTIC_PROFILE_PAGE
} from './routeConsts';
import { RequireAuth } from '../helpers/RequireAuth';
import Main from '../pages/main/Main';
import Profile from '../pages/profile/Profile';
import Project from '../pages/project/Project';
import Places from '../pages/project/places/Places';
import Graves from '../pages/project/graves/Graves';
import MapLayout from '../pages/project/map/MapLayout';
import Decode from '../pages/project/decode/Decode';
import PermissionsGate from '../helpers/permissions/PermissionsGate';
import { SCOPES } from '../helpers/permissions/permissionsMaps';
import Overview from '../pages/_admin/overview/OverView';
import Features from '../pages/_admin/features/Features';
import Grave from '../pages/project/graves/Grave';
import Place from '../pages/project/places/Place';
import Projects from '../pages/_admin/projects/Projects';
import Cemeteries from '../pages/_admin/cemeteries/Cemeteries';
import Municipalities from '../pages/_admin/municipalities/Municipalities';
import Statistics from '../pages/_admin/statistics/Statistics';
import Users from '../pages/_admin/users/Users';
import EditGraveForm from '../pages/project/graves/editForm/EditGraveForm';
import EditPlaceForm from '../pages/project/places/editForm/EditPlaceForm';
import MainStatistic from '../pages/_admin/statistics/mainStatistic/MainStatistic';
import MainProfilePage from '../pages/profile/pages/MainProfilePage';
import EditProfilePage from '../pages/profile/pages/EditProfilePage';
import DecodeStatisticProfilePage from '../pages/profile/pages/DecodeStatisticProfilePage';
import PhotoStatisticProfilePage from '../pages/profile/pages/PhotoStatisticProfilePage';
import PhotoStatisticContainer from '../pages/_admin/statistics/photoStatistic/PhotoStatisticContainer';
import DecodeStatisticContainer from '../pages/_admin/statistics/decodeStatistic/DecodeStatisticContainer';
import MainFeatures from '../pages/_admin/features/main/MainFeatures';
import ImportFeatures from '../pages/_admin/features/import/ImportFeatures';
import CompareFeatures from '../pages/_admin/features/compare/CompareFeatures';
import ResetPassword from "../pages/reset/ResetPassword";
import ForgotPassword from "../pages/authorization/ForgotPassword/ForgotPassword";
import OtherFeatures from "../pages/_admin/features/other/OtherFeatures";
import FindersMenu from "../pages/_admin/features/finders/FindersMenu";
import UserActionsStatistic from "../pages/_admin/statistics/userActionsStatistic/UserActionsStatistic";
import UserTemplates from "../pages/_admin/userTemplates/UserTemplates";
import SignUp from "../pages/authorization/SignUp/SignUp";

const NotAccess = () =>
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <p style={{ fontSize: '2rem' }}>Такой страницы не существует</p>
    <p style={{ fontSize: '2rem' }}>Или у вас нет к ней доступа</p>
    <p style={{ fontSize: '2rem' }}>Можете обратиться к администрации <strong>Cloudmaps.</strong></p>
  </div>

type IRoute = { path: string, Element: any, scopes: string[] }
export const router = () => {

  const mainRoutes: IRoute[] = [
    { path: PROJECTS_PAGE, Element: Project, scopes: [] },
    { path: PLACES_PAGE, Element: Places, scopes: [] },
    { path: PLACE_PAGE + ':id', Element: Place, scopes: [] },
    { path: GRAVES_PAGE, Element: Graves, scopes: [] },
    {
      path: EDIT_PLACE_PAGE + ':id',
      Element: EditPlaceForm,
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.editorUser, SCOPES.canEdit]
    },
    {
      path: EDIT_GRAVE_PAGE + ':id',
      Element: EditGraveForm,
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.editorUser, SCOPES.canEdit]
    },
    { path: GRAVE_PAGE + ':id', Element: Grave, scopes: [] },
    { path: MAP_PAGE, Element: MapLayout, scopes: [] },
    { path: DECODE_PAGE + ':id', Element: Decode, scopes: [SCOPES.admin, SCOPES.decoder, SCOPES.superAdmin] },
  ];

  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route element={<Home/>} path={HOME_PAGE}/>

        <Route element={<SignIn/>} path={SIGN_IN_PAGE}/>
        {/*<Route element={<SignUp/>} path={SIGN_UP_PAGE}/>*/}

        <Route element={<ResetPassword/>} path={`${RESET_PASSWORD_PAGE}*`}/>
        <Route element={<ForgotPassword/>} path={FORGOT_PASSWORD_PAGE}/>

        <Route element={<RequireAuth><Main/></RequireAuth>}>

          <Route element={<OverView/>} path={OVERVIEW_PAGE}>

            {mainRoutes.map(({ Element, scopes, path }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PermissionsGate scopes={scopes} RenderError={() => <NotAccess/>}>
                    <Element/>
                  </PermissionsGate>
                }
              />
            ))}

          </Route>

          <Route
            element={
              <PermissionsGate
                scopes={[SCOPES.worker, SCOPES.admin, SCOPES.superUser, SCOPES.experiencedWorker]}
                RenderError={() => <NotAccess/>}
              >
                <Overview/>
              </PermissionsGate>
            }
            path={OVERVIEW_ADMIN_PAGE}
          >

            <Route element={<Features/>} path={ADMIN_FEATURES}>
              <Route element={<MainFeatures/>} index/>
              <Route element={<ImportFeatures/>} path={IMPORT_FEATURES}/>
              <Route element={<CompareFeatures/>} path={COMPARING_FEATURES}/>
              <Route element={<FindersMenu/>} path={ADMIN_STUBS_FEATURES}/>
              <Route element={<OtherFeatures/>} path={ADMIN_OTHER_FEATURES}/>
            </Route>

            <Route element={<Projects/>} path={ADMIN_PROJECTS}/>
            <Route element={<Cemeteries/>} path={ADMIN_CEMETERIES}/>
            <Route element={<Municipalities/>} path={ADMIN_MUNICIPALITIES}/>
            <Route element={<Users/>} path={ADMIN_USERS}/>
            <Route element={<UserTemplates/>} path={ADMIN_USERS_TEMPLATES}/>

            <Route element={<Statistics/>} path={ADMIN_STATISTICS}>
              <Route element={<MainStatistic/>} index/>
              <Route element={<PhotoStatisticContainer/>} path={ADMIN_PHOTO_STATISTIC}/>
              <Route element={<DecodeStatisticContainer/>} path={ADMIN_DECODE_STATISTIC}/>
              <Route element={<UserActionsStatistic/>} path={ADMIN_USER_ACTIONS_STATISTIC}/>
            </Route>

          </Route>

          <Route element={<Profile/>} path={PROFILE_PAGE}>
            <Route element={<MainProfilePage/>} index/>
            <Route element={<EditProfilePage/>} path={EDIT_PROFILE_PAGE}/>
            <Route element={<DecodeStatisticProfilePage/>} path={STATISTIC_PROFILE_PAGE}/>
            <Route element={<PhotoStatisticProfilePage/>} path={PHOTO_STATISTIC_PROFILE_PAGE}/>
          </Route>

        </Route>

        <Route element={<NotAccess/>} path={`*`}/>
      </Route>
    </Routes>
  )
}