import React from 'react';
import styles from '../headers.module.scss';
import { Stack } from '@mui/material';
import { ADMIN_USERS, PROFILE_PAGE, PROJECTS_PAGE } from '../../../router/routeConsts';
import logo from '../../../assets/cloudmaps.png';
import { useHeaders } from '../useHeaders';
import { NavLink, useLocation } from 'react-router-dom';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import { useRecoilValue } from 'recoil';
import { projectsSelector } from '../../../store/projectStore';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';
import * as JivoSite from 'react-jivosite'

const AuthenticatedHeader = () => {
  const location = useLocation()
  const { toHome, logout } = useHeaders()
  const projectInfo = ls_getProjectInfo()
  const { projects } = useRecoilValue(projectsSelector)

  return (
    <div className={styles.auth__wrapper}>
      <div className={styles.auth__logo__wrapper}>
        <img src={logo} alt="logo" onClick={toHome}/>
      </div>

      <Stack direction={'row'} spacing={1} className={styles.links}>
          <NavLink
            className={location.pathname.includes('overview') ? styles.active_link : styles.link}
            to={PROJECTS_PAGE}
          >
            КАРТАЗАХОРОНЕНИЙ.РФ
          </NavLink>

        <PermissionsGate scopes={[SCOPES.worker, SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser ]}>
          <NavLink
            className={location.pathname.includes('admin') ? styles.active_link : styles.link}
            to={ADMIN_USERS}
          >
            Админ панель
          </NavLink>
        </PermissionsGate>
      </Stack>

      <div className={styles.right_wrapper}>
        <div className={styles.auth__buttons}>
          <NavLink
            className={({ isActive }) => isActive ? styles.active_link : styles.link}
            to={PROFILE_PAGE}
          >
            Профиль
          </NavLink>

          <NavLink
            className={styles.link}
            to={''}
            onClick={logout}
          >
            Выйти
          </NavLink>
        </div>

        <div className={styles.project_name_wrapper}>
          Проект: {projects?.find((project) => project.schema === projectInfo?.schema)?.name || 'не загружен'}
        </div>

        <JivoSite.Widget  id={'1C0yig6gi5'}/>
      </div>
    </div>
  );
};

export default AuthenticatedHeader;