import React from 'react';
import cn from "classnames";
import styles from "./customButtons.module.scss";
import Icon from "../../UI/Icons/Icon";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../store/mapStore";

const SelectFinger = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)

  const onClick = () => {
    if (!mapState.selectFinger.isOn) {
      setMapState((prev) => ({...prev, selectFinger: {...mapState.selectFinger, isOn: true}}))
    } else {
      setMapState((prev) => ({
        ...prev,
        selectFinger: {isOn: false, placesArray: [], sectionsArray: []}
      }))
    }
  }

  return (
    <div onClick={onClick} className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)}>
      <Icon icon={"selectFinger"}/>
    </div>
  )
};

export default SelectFinger;