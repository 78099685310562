import React, { FC, useEffect } from 'react';
import useEditUserPermission from "../useEditUserPermission";
import styles from '../../../../users.module.scss';
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import SelectProject from "../../../../../../../components/SelectProject/SelectProject";

interface Props {
  params: any
  closeDialog: () => void
}

const EditProjectPermissions: FC<Props> = ({ params, closeDialog }) => {
  const {
    projectsData,
    currentProjects,
    userPermission,
    submitDelete,
    projects,
    setProjects,
    handleOpenAlert,
    handleCloseAlert,
    handleChange,
    handleSubmit,
    handleSubmitDelete,
    findProjectByPermissionId,
    getUserPermission,
    handleDeleteCurrentProjects
  } = useEditUserPermission(params)

  useEffect(() => {
    getUserPermission()
    if (projects.length === 0 && userPermission?.length === 0 && projectsData) {
      setProjects(projectsData?.data)
    }

    if (userPermission && projectsData) {
      const projectsSet = new Set(userPermission.map((perm: any) => (perm.project)))

      const newProjects = projectsData?.data?.filter((project: any) => {
        return !projectsSet.has(project.name)
      })

      setProjects(newProjects)
    }
  }, [userPermission, projectsData])

  return (
    <>
      <div className={styles.user_permission_content}>
        <p>Проекты назначенные пользователю:</p>

        <div className={styles.user_permissions_wrapper}>
          {(userPermission && userPermission.length !== 0) ?
            userPermission.map((perm: any, index: number) => (
              <Chip
                color="primary"
                size={'small'}
                key={index}
                label={perm.project}
                onDelete={() => handleOpenAlert(perm.id)}
              />
            ))
            :
            <div>У пользователя нет ограничений</div>
          }
        </div>

        <div className={styles.user_permissions_wrapper}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {currentProjects.map((value: any, index: number) => (
              <Chip key={index} label={value} onDelete={() => handleDeleteCurrentProjects(value)}/>
            ))}
          </Box>
        </div>

        <SelectProject onProjectClick={handleChange} needFirstSelect={false}/>

        <div>
          <Button onClick={closeDialog}>
            Отмена
          </Button>

          <Button onClick={handleSubmit}>
            Сохранить
          </Button>
        </div>
      </div>

      <Dialog open={!!submitDelete} onClose={handleCloseAlert}>
        <DialogTitle>
          Удалить у пользователя {params.row.email} права на проект {findProjectByPermissionId()}?
        </DialogTitle>

        <DialogContent>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Button onClick={handleSubmitDelete}>
              Да
            </Button>

            <Button onClick={handleCloseAlert}>
              Нет
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditProjectPermissions;