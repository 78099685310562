// packages
import dayjs, { Dayjs } from 'dayjs';
// types
import { IPhotoStatistic } from '../../../../../models/_admin/IPhotoStatistic';

interface ISyncStatistic {
  startDate: Dayjs,
  placesCount: number,
  gravesCount: number,
  endDate: Dayjs
}

export const syncStatistic = (statistic: IPhotoStatistic[]) => {
  if (!statistic.length) {
    return [] as ISyncStatistic[]
  }

  let syncStatisticData: ISyncStatistic[] = [{
    startDate: dayjs(statistic[0].dateCreated),
    placesCount: 0,
    gravesCount: 0,
    endDate: dayjs(statistic[0].dateCreated)
  }];

  let startDate = statistic[0].dateCreated;
  let i = 0;
  const stepType = 'place'

  statistic.forEach((step, index) => {
    if (dayjs(startDate).diff(step.dateCreated, 'minute') > 30) {
      startDate = step.dateCreated;
      i++;

      syncStatisticData[i] = {
        startDate: dayjs(startDate),
        placesCount: 0,
        gravesCount: 0,
        endDate: dayjs(startDate)
      }

      if (step.type === stepType) {
        syncStatisticData[i].gravesCount++;
      } else {
        syncStatisticData[i].placesCount++;
      }

    } else {
      syncStatisticData[i].endDate = dayjs(step.dateCreated);

      if (step.type === stepType) {
        syncStatisticData[i].gravesCount++;
      } else {
        syncStatisticData[i].placesCount++;
      }
    }
  })

  return syncStatisticData;
}