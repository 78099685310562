import React, { FC, useState } from 'react';
import styles from './mapLayoutModal.module.scss';
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import Icon from "../../../../components/UI/Icons/Icon";
import Loader from "../../../../components/UI/Loader/Loader";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { UPDATE_NOTE_OR_TASK_URL } from "../../../../api/urls";
import { toast } from "react-toastify";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import dayjs from "dayjs";

interface Props {
  onCloseTaskOrNote: () => void,
  onDeleteTaskOrNote: () => Promise<void>,
}

const TaskOrNoteModal: FC<Props> = ({ onCloseTaskOrNote, onDeleteTaskOrNote }) => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const [description, setDescription] = useState('')

  const scopes = mapState.selectedObject?.type === 'note' ? [SCOPES.worker, SCOPES.experiencedWorker] : [SCOPES.admin, SCOPES.superAdmin]

  const openCloseEditDescription = () => {
    setMapState((prev) => ({
      ...prev,
      selectedObject: { ...prev.selectedObject, editDescription: !prev.selectedObject.editDescription },
    }))
  }

  const isDoneTask = async () => {
    try {

      const dateIsDone = dayjs();

      await mutation.mutateAsync({
        method: 'patch',
        data: {
          noteType: mapState.selectedObject.type,
          isDone: mapState.selectedObject.isDone ? null : dateIsDone.toISOString()
        },
        url: UPDATE_NOTE_OR_TASK_URL + mapState.selectedObject.id,
      })

      toast.success(`${mapState.selectedObject.isDone ? 'Задача в работе' : 'Задача завершена'}`)

      setMapState((prev) => ({
        ...prev,
        selectedObject: { ...prev.selectedObject, isDone: mapState.selectedObject.isDone ? null : dateIsDone},
      }))

      if (!mapState.needReFetch) {
        setMapState((prev) => ({
          ...prev,
          needReFetch: true,
        }))
      }

    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data?.error}</p>
          <p>{e.response?.data?.message}</p>
        </div>
      )
    }
  }

  const onEditTaskOrNote = async () => {
    try {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            noteType: mapState.selectedObject.type,
            description: description
          },
          url: UPDATE_NOTE_OR_TASK_URL + mapState.selectedObject.id,
        });

        toast.success(`Описание ${mapState.selectedObject.type === 'task' ? 'задачи' : 'заметки'} успешно обновлено, перезагрузите страницу, чтобы увидеть изменения`);

        return
    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data?.error}</p>
          <p>{e.response?.data?.message}</p>
        </div>
      )
    }
  }

  if (!mapState.selectedObject || !(mapState.selectedObject?.type === 'note' || mapState.selectedObject?.type === 'task')) {
    return <></>
  }

  return (
    <>
      <div className={styles.modal_task_or_note}>
        <div className={styles.close} onClick={onCloseTaskOrNote}>
          <Icon icon={"close"}/>
        </div>

        <div className={styles.task_and_note_icon_wrapper}>
          <PermissionsGate scopes={scopes}>
            <div onClick={openCloseEditDescription} className={styles.edit}>
              <Icon icon={"edit"}/>
            </div>
          </PermissionsGate>

          <span>{mapState.selectedObject.type === 'task' ? 'Задача полевика' : 'Записка полевика'}</span>

          <PermissionsGate scopes={scopes}>
            <div className={styles.delete} onClick={onDeleteTaskOrNote}>
              <Icon icon={'delete'}/>
            </div>
          </PermissionsGate>
        </div>

        {
          mapState.selectedObject.type === 'task' &&
            <div className={styles.switch}>
                <div>
                    <Switch checked={!!mapState.selectedObject.isDone} onChange={isDoneTask}/>
                    Завершить задачу
                </div>

              {mapState.selectedObject.isDone &&
                  <span>
                  {`Дата завершения: ${dayjs(mapState.selectedObject.isDone).subtract(3, 'hours').format('DD.MM.YYYY HH:mm')}`}
                </span>
              }
            </div>
        }

        {
          mapState.selectedObject.editDescription
            ?
            <>
              <div onClick={onEditTaskOrNote} className={styles.save_description}>
                <Icon icon={"save"}/>
                <span>Сохранить изменения</span>
              </div>

              <TextField
                onChange={(e) => setDescription(e.target.value)}
                className={styles.input_change_description}
                label={'Описание'}
                defaultValue={mapState.selectedObject.description}
                rows={15}
                multiline
              />
            </>
            :
            mapState.selectedObject ?
              <>
                <p className={styles.task_and_note_description}>{mapState.selectedObject.description}</p>
              </>
              :
              <Loader/>
        }
      </div>
    </>
  );
};

export default TaskOrNoteModal;