import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import dayjs from "dayjs";
import UserTemplatesActions from "./UserTemplatesActions";

export const useUserTemplates = (refetch: () => void) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 250
    },
    {
      field: 'url',
      headerName: 'Предпросмотр',
      width: 200,
      renderCell: (params) => (
        <a href={params.formattedValue}>
          Посмотреть
        </a>
      )
    },
    {
      field: 'entity',
      headerName: 'Объект',
      width: 100,
      renderCell: (params) => params.formattedValue === 'grave' ? 'Могила' : 'Ограда',
    },
    {
      field: 'user',
      headerName: 'Создатель',
      width: 200
    },
    {
      field: 'municipality',
      headerName: 'Муниципалитет',
      width: 200
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 200,
      renderCell: params => dayjs(params.formattedValue).format('DD-MM-YYYY mm:ss')
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 100,
      renderCell: params => <UserTemplatesActions id={params.row.id} refetch={refetch}/>
    }
  ]

  return {
    columns
  }
}