import React, { useState } from 'react';
import { Card, CardActions, CardContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import styles from "../stubsStatistic/stubStatistic.module.scss";
import { useCustomMutation } from "../../api/useCustomMutation";
import { DELETE_PHOTOS_DUPLICATES_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const DeletePhotosDuplicates = () => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onSubmit = async () => {
    try {
      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'DeletePhotosDuplicates' }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: DELETE_PHOTOS_DUPLICATES_URL + `?schema=${project.schema}`
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Проходимся по всем могилам и оградам. Если находим в одном объекте ссылки на одно и тоже фото, то удаляем лишние
        ссылки.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.container}>
      <CustomCardHeader title={algorithmsTitles.DeletePhotosDuplicates} onInfoClick={onInfoClick}/>

      <CardContent sx={{ paddingBottom: 0, paddingTop: 2, marginBottom: 0 }}>
        {project &&
            <div>
                <h3>{project.name}</h3>
            </div>
        }
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать поиск
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default DeletePhotosDuplicates;