import { ls_getProjectInfo } from "../../helpers/localStorage";
import { GET_PLACE_SECTIONS, GET_PLACE_STATUSES, GET_PLACE_TYPES } from "../urls";
import { axiosInstance } from "../axios";

export const getPlaceStatuses = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_PLACE_STATUSES + `?schema=${schema}`)
}

export const getPlaceTypes = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_PLACE_TYPES + `?schema=${schema}`)
}

export const getPlaceSections = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_PLACE_SECTIONS + `?schema=${schema}`)
}