import React, { FC, useState } from 'react';
import { Card, CardContent, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";
import styles from "../CalculatePlacesTypes/calculatePlacesTypes.module.scss";
import { IProject } from "../../models/IProject";
import SelectProject from "../SelectProject/SelectProject";
import { useQuery } from "react-query";
import { getSections } from "../../api/section/sectionApi";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCustomMutation } from "../../api/useCustomMutation";
import { toast } from "react-toastify";
import { REPLACE_BORDER_BY_FILE_URL, REPLACE_SECTION_BY_FILE_URL } from "../../api/urls";
import { getBorders } from "../../api/border/borderApi";

interface Props {
  type: 'border' | 'section'
}

const ReplaceSectionOrBorder: FC<Props> = ({ type }) => {
  const [project, setProject] = useState<IProject>()
  const [selectedObject, setSelectedObject] = useState<any>()
  const { handleSubmit, register } = useForm()
  const mutation = useCustomMutation()
  const { data } = useQuery([`${project?.schema}-${type}`], () => {
    if (type === 'section') {
      return getSections(project?.schema)
    } else {
      return getBorders(project?.schema)
    }
  })

  const onProjectClick = (selectedProject: IProject) => {
    setProject(selectedProject)
  }

  const handleChange = (e: any) => {
    setSelectedObject(e.target.value)
  }

  const onSubmit = async ({ File }: any) => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      if (!selectedObject) {
        toast.error('Выберите объект')
        return
      }

      const formData = new FormData()
      formData.append('schema', project?.schema)
      formData.append('file', File[0])
      formData.append('id', selectedObject)

      await mutation.mutateAsync({
        method: 'post',
        url: type === 'section' ? REPLACE_SECTION_BY_FILE_URL : REPLACE_BORDER_BY_FILE_URL,
        data: formData
      })

      toast.success('Объект успешно заменён')
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Замена {type === 'section' ? 'сектора' : 'границы'}
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader
        title={type === 'border' ? algorithmsTitles.ReplaceBorder : algorithmsTitles.ReplaceSection}
        onInfoClick={onInfoClick}
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <SelectProject onProjectClick={onProjectClick}/>
            <Typography variant={'h5'}>{project?.name}</Typography>
          </Stack>

          <Select
            id="select-section-for-replace"
            value={selectedObject ?? ''}
            onChange={handleChange}
          >
            {data?.data.map((s: any) => (
              <MenuItem key={s.id} value={s.id}>{s.sectionNumber ?? s.id}</MenuItem>
            ))}
          </Select>

          <TextField
            {...register('File', { required: true })}
            size={'small'}
            type={'file'}
            label={'Замена'}
            InputLabelProps={{ shrink: true }}
          />

          <LoadingButton variant={'outlined'} onClick={handleSubmit(onSubmit)}>
            Заменить
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReplaceSectionOrBorder;