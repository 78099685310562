import React from 'react';
import { Grid } from '@mui/material';
import SyncSchema from '../../../../components/SyncSchema/SyncSchema';
import { GET_BOOK_PROGRESS_AND_RESULT_URL, MERGE_ASHES_URN_BOOK_URL, MERGE_BOOK_URL } from '../../../../api/urls';
import { syncSchemaBookState } from '../../../../store/syncSchemas';
import SyncProject from "./syncProject/SyncProject";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import SyncSchemaRgis from "../../../../components/SyncSchema/SyncSchemaRgis";
import { algorithmsTitles } from "../../../../utils/utils";

const CompareFeatures = () => {
  return (
    <Grid container spacing={2}>
      <PermissionsGate scopes={[SCOPES.admin]}>
        <Grid item>
          <SyncSchemaRgis loading={false}/>
        </Grid>

        <Grid item>
          <SyncSchema
            url={MERGE_BOOK_URL}
            title={algorithmsTitles.SyncSchema_book}
            label={'Книжные записи (excel)'}
            sseUrl={GET_BOOK_PROGRESS_AND_RESULT_URL}
            state={syncSchemaBookState}
            syncType={'book'}
          />
        </Grid>

        <Grid item>
          <SyncSchema
            url={MERGE_ASHES_URN_BOOK_URL}
            title={algorithmsTitles.SyncSchema_urnBook}
            label={'Книжные записи (excel)'}
            sseUrl={GET_BOOK_PROGRESS_AND_RESULT_URL}
            state={syncSchemaBookState}
            syncType={'urnBook'}
          />
        </Grid>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.worker]}>
        <Grid item>
          <SyncProject/>
        </Grid>
      </PermissionsGate>
    </Grid>
  );
};

export default CompareFeatures;